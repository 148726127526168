import React from 'react';
import styled from 'styled-components';

export default ({aspectRatio=1, children}) => (
  <Root aspectRatio={aspectRatio}>
    <Content>
      {children}
    </Content>
  </Root>
);

const Root = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ${({aspectRatio}) => `${aspectRatio*100}%`}
  }
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  > * {
    width: 100%;
    height: 100%;
  }
`;