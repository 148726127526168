import React from 'react'
/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import Container from '../ui/container'
import {TextInput, PasswordInput, TextArea} from '../ui/input'
import {FormField} from '../ui/form'
import Button, { OutlineButton } from '../ui/button'
import theme from '../ui/theme'
import Checkbox from '../ui/checkbox'
import {Link} from 'react-router-dom'
import SectionLabel from '../ui/section-label';
import api from '../api';
import App from '../app'

export default () => {
  const app = React.useContext(App);
  const [sending, setSending] = React.useState(false);

  const submit = body => {
    setSending(true);
    if ( !sending ) {
      api().post('/contact', body)
        .then(() => {
          app.flash('お問い合わせを送信しました');
          setSending(false);
        })
        .catch(() => {
          app.flash('お問い合わせの送信に失敗しました')
          setSending(false);
        });
    }
  };

  return <Contact user={app.user} onSubmit={submit} sending={sending}/>
}

export const Contact = ({user, onSubmit, sending}) => {
  const [name, setName] = React.useState(user ? user.name : '');
  const [email, setEmail] = React.useState(user ? user.email : '');
  const [content, setContent] = React.useState('');
  const [agree, setAgree] = React.useState(false);
  const valid = name && email && content && agree;
  console.log(sending);
  return (
    <Container css={{paddingTop: 50}}>
      <SectionLabel label="CONTACT"/>
      <form onSubmit={ev => { ev.preventDefault(); onSubmit({name, email, content}); }}>
        <OutlineButton to="/faq/" color={theme.colors.accent} fit>
          何かお困りですか？<br/>
          よくあるご質問はこちら
        </OutlineButton>
        <FormField label="お名前" required>
          <TextInput value={name} onChange={setName}/>
        </FormField>
        <FormField label="メールアドレス" required>
          <TextInput value={email} onChange={setEmail}/>
        </FormField>
        <FormField label="お問い合わせ内容" required>
          <TextArea rows="10" value={content} onChange={setContent}/>
        </FormField>
        <div css={{display: 'flex', alignItems: 'center'}}>
          <span css={{marginRight: 12}}>
            <Checkbox checked={agree} onChange={setAgree}/>
          </span>
          <Link to="/faq/" css={{color: theme.colors.accent, textDecoration: 'underline'}}>よくあるご質問</Link>を確認しました
        </div>
        <div css={{marginTop: 20}}>
          <Button disabled={!valid || sending} image={theme.colors.accentGradient} fit>
            {!sending ? "送信" : "...送信中"}
          </Button>
        </div>
      </form>
    </Container>
  )
}