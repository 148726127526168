import React from 'react';
import InfiniteScroll from './infinite-scroll';

const Paginator = ({fetch, children}) => {
  const [slice, setSlice] = React.useState(null)
  const [next, setNext] = React.useState('')

  React.useEffect(() => {
    fetch()
      .then(([slice, next]) => {
        setSlice(slice);
        setNext(next);
      })
  }, []);

  const forward = () => fetch(next)
    .then(([dSlice, dNext]) => {
      setSlice(slice.concat(dSlice))
      setNext(dNext)
    }); 

  return slice === null ? <React.Fragment/> : children(slice, next ? forward : null);
};

export const ScrollPaginator = ({fetch, children}) => 
  <Paginator fetch={fetch}>
    { (slice, next) =>
    <InfiniteScroll next={next}>
      {children(slice)}
    </InfiniteScroll>
    }
  </Paginator>

export default Paginator;