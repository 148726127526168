import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Button from '../ui/button';
import App from '../app';
import Container from '../ui/container';

export default ({media}) => {
  const app = React.useContext(App);

  return (
    <div onClick={() => app.pop()} css={{backgroundColor: 'black', width: '100%', height: '100%'}}>
      <Container css={{height: 65, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'white'}}>
        <span className="material-icons" css={{fontSize: 36, cursor: 'pointer'}}>close</span>
      </Container>
      {media.image && 
        <div css={{width: '100%', height: 'calc(100% - 65px)', backgroundImage: `url(${media.image})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}/>
      }
    </div>
  )
}