import React from 'react';
import styled from 'styled-components';

export const Input = ({type='text', value, onChange, placeholder, error=false}) => 
  <Input.Root type={type} value={value} onChange={ev => onChange(ev.target.value)} error={error} placeholder={placeholder}/>

Input.Root = styled.input`
  border: 1px solid #CCC;
  width: 100%;
  padding: 8px;
  font-size: 100%;

  ${({error}) => `
    ${error ? `
      border: 2px solid red;
    ` : ''}
  `}
`;

export const TextInput = Input;

export const PasswordInput = ({value, onChange, placeholder, error}) => <Input type="password" value={value} onChange={onChange} error={error} placeholder={placeholder}/>;

export const TextArea = ({value, rows, onChange, error=false}) =>
  <TextArea.Root value={value} rows={rows} error={error} onChange={ev => onChange(ev.target.value)}/>

TextArea.Root = styled.textarea`
  border: 1px solid #CCC;
  padding: 8px;
  font-size: 100%;
  width: 100%;
  
  ${({error}) => `
    ${error ? `
      border: 2px solid red;
    ` : ''}
  `}
`;