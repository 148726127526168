import moment, { now } from 'moment';
import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import ActivityCard from '../ui/activity-card';
import birthday_2022_prize_r_kakegawa_jpg from '../img/birthday-prize/2022/r-kakegawa.jpg';
import birthday_2023_prize_r_kakegawa_jpg from '../img/birthday-prize/2023/r-kakegawa.jpg';
import birthday_2024_prize_r_kakegawa_jpg from '../img/birthday-prize/2024/r-kakegawa.jpg';
import birthday_2022_prize_k_miura_jpg from '../img/birthday-prize/2022/k-miura.jpg';
import birthday_2021_prize_k_miura_jpg from '../img/birthday-prize/2021/k-miura.jpg';
import birthday_2020_prize_s_matsuda_jpg from '../img/birthday-prize/2020/s-matsuda.jpg';
import birthday_2022_prize_s_matsuda_jpg from '../img/birthday-prize/2022/s-matsuda.jpg';
import birthday_2023_prize_s_matsuda_jpg from '../img/birthday-prize/2023/s-matsuda.jpg';
import birthday_2024_prize_s_matsuda_jpg from '../img/birthday-prize/2024/s-matsuda.jpg';
import birthday_2021_prize_s_matsuda_jpg from '../img/birthday-prize/2021/s-matsuda.jpg';
import birthday_2020_prize_y_tamaki_jpg from '../img/birthday-prize/2020/y-tamaki.jpg';
import birthday_2022_prize_y_tamaki_jpg from '../img/birthday-prize/2022/y-tamaki.jpg';
import birthday_2023_prize_y_tamaki_jpg from '../img/birthday-prize/2023/y-tamaki.jpg';
import birthday_2024_prize_y_tamaki_jpg from '../img/birthday-prize/2024/y-tamaki.jpg';
import birthday_2021_prize_y_tamaki_jpg from '../img/birthday-prize/2021/y-tamaki.jpg';
import birthday_2020_prize_t_nagaishi_jpg from '../img/birthday-prize/2020/t-nagaishi.jpg';
import birthday_2021_prize_t_nagaishi_jpg from '../img/birthday-prize/2021/t-nagaishi.jpg';
import birthday_2022_prize_t_nagaishi_jpg from '../img/birthday-prize/2022/t-nagaishi.jpg';
import birthday_2020_prize_y_hirata_jpg from '../img/birthday-prize/2020/y-hirata.jpg';
import birthday_2021_prize_y_hirata_jpg from '../img/birthday-prize/2021/y-hirata.jpg';
import birthday_2022_prize_y_hirata_jpg from '../img/birthday-prize/2022/y-hirata.jpg';
import birthday_2023_prize_y_hirata_jpg from '../img/birthday-prize/2023/y-hirata.jpg';
import birthday_2024_prize_y_hirata_jpg from '../img/birthday-prize/2024/y-hirata.jpg';
import birthday_2023_prize_r_ishii_jpg from '../img/birthday-prize/2023/r-ishii.jpg';
import birthday_2024_prize_r_ishii_jpg from '../img/birthday-prize/2024/r-ishii.jpg';
import join_prize_2024_jpg from '../img/prize/2024/join-prize.png';
import join_prize_2023_jpg from '../img/prize/2023-2/join-prize.png';
import join_prize_2022_jpg from '../img/prize/2022/join-prize.jpg';
import join_prize_2021_jpg from '../img/prize/2021/join-prize.jpg';
import join_prize_2020_jpg from '../img/prize/2020/join-prize.jpg';
import continue_3m_prize_2024_jpg from '../img/prize/2024/continue-3m-prize.png';
import continue_6m_prize_2024_jpg from '../img/prize/2024/continue-6m-prize.png';
import continue_1y_prize_2024_jpg from '../img/prize/2024/continue-1y-prize.png';
import continue_3m_prize_2023_jpg from '../img/prize/2023-2/continue-3m-prize.png';
import continue_6m_prize_2023_jpg from '../img/prize/2023-2/continue-6m-prize.png';
import continue_1y_prize_2023_jpg from '../img/prize/2023-2/continue-1y-prize.png';
import continue_3m_prize_2022_jpg from '../img/prize/2022/continue-3m-prize.jpg';
import continue_6m_prize_2022_jpg from '../img/prize/2022/continue-6m-prize.jpg';
import continue_1y_prize_2022_jpg from '../img/prize/2022/continue-1y-prize.jpg';
import continue_3m_prize_2021_jpg from '../img/prize/2021/continue-3m-prize.jpg';
import continue_6m_prize_2021_jpg from '../img/prize/2021/continue-6m-prize.jpg';
import continue_1y_prize_2021_jpg from '../img/prize/2021/continue-1y-prize.jpg';
import continue_3m_prize_2020_jpg from '../img/prize/2020/continue-3m-prize.jpg';
import continue_6m_prize_2020_jpg from '../img/prize/2020/continue-6m-prize.jpg';
import continue_1y_prize_2020_jpg from '../img/prize/2020/continue-1y-prize.jpg';

const prizes = {
  th: {
    media: {
      video: 'https://d3ftz0x6dpr7j8.cloudfront.net/media/file/thanks.mp4',
      caption: `ISM FC会員の皆様、改めましてご入会有難うございます。所属アーティストより皆様へ感謝の気持ちを込めてFC開設記念動画をお届け致します！今後ともISM FCをよろしくお願い申し上げます。`
    }
  },
  j: {
    media: {
      name: '入会特典',
      image: {
        2024: join_prize_2024_jpg,
        2023: join_prize_2023_jpg,
        2022: join_prize_2022_jpg,
        2021: join_prize_2021_jpg,
        2020: join_prize_2020_jpg,
      },
      caption: `ISM FCのご入会ありがとうございます。
        楽しいコンテンツをお届けできるよう運営してまいりますので、
        今後ともISM FCをよろしくお願い申し上げます。`
    }
  },
  m3: {
    media: {
      name: '入会3ヶ月特典',
      image: {
        2024: continue_3m_prize_2024_jpg,
        2023: continue_3m_prize_2023_jpg,
        2022: continue_3m_prize_2022_jpg,
        2021: continue_3m_prize_2021_jpg,
        2020: continue_3m_prize_2020_jpg,
      },
      caption: `いつもISM FCのご利用ありがとうございます。
        入会3ヶ月特典をお届け致します。
        今後ともISM FCをよろしくお願い申し上げます。`
    }
  },
  m6: {
    media: {
      name: '入会6ヶ月特典',
      image: {
        2024: continue_6m_prize_2024_jpg,
        2023: continue_6m_prize_2023_jpg,
        2022: continue_6m_prize_2022_jpg,
        2021: continue_6m_prize_2021_jpg,
        2020: continue_6m_prize_2020_jpg,
      },
      caption: `いつもISM FCのご利用ありがとうございます。
        入会6ヶ月特典をお届け致します。
        今後ともISM FCをよろしくお願い申し上げます。`
    }
  },
  y1: {
    media: {
      name: '入会1年特典',
      image: {
        2024: continue_1y_prize_2024_jpg,
        2023: continue_1y_prize_2023_jpg,
        2022: continue_1y_prize_2022_jpg,
        2021: continue_1y_prize_2021_jpg,
        2020: continue_1y_prize_2020_jpg,
      },
      caption: `いつもISM FCのご利用ありがとうございます。
        入会1年特典をお届け致します。
        今後ともISM FCをよろしくお願い申し上げます。`
    }
  },
  'birthday.r-kakegawa': {
    talent: {
      id: 'r-kakegawa',
      name: '掛川僚太'
    },
    media: {
      image: {
        2024: birthday_2024_prize_r_kakegawa_jpg,
        2023: birthday_2023_prize_r_kakegawa_jpg,
        2022: birthday_2022_prize_r_kakegawa_jpg,
        2021: birthday_2022_prize_r_kakegawa_jpg,
      },
      caption: {
        2024: `　　＊お誕生日＊
        ＊おめでとう御座います！＊
        
        生まれてきてくれて
        本当にありがとう。
        
        貴方が、健康で、笑顔で幸せ溢れる一年になること願っています。
        
        今年も一緒にたくさんの
        思い出を作りましょうね♪        
        `,
        2023: `HAPPY BIRTHDAY‼️
        生まれて来てくれて本当にありがとう‼️
        これからの1年もあなたとたくさんの笑顔と幸せで溢れる日々を過ごせること願っていますっ。
        `,
        2022: `お誕生日おめでとうございます！
      大切な1日をお祝いできて嬉しいです。
      あなたにとって幸せが溢れる1年になりますように。`}
    }
  },
  'birthday.r-ishii': {
    talent: {
      id: 'r-ishii',
      name: '石井良太'
    },
    media: {
      image: {
        2024: birthday_2024_prize_r_ishii_jpg,
        2023: birthday_2023_prize_r_ishii_jpg
      },
      caption: {
        2024: `HAPPY BIRTHDAY!!!

        出会いに感謝！
        
        今日もあなたは輝いてます！
        
        最高の一年を更新していきましょー！！`,
        2023: `HAPPY BIRTHDAY!!!
        出会いに感謝！
        今年も良き出会いと良きエンターテイメントを通して素敵な一年になりますように！
        これからも楽しいこといっぱいやっていきましょうね！`}
    }
  },
    // 'birthday.k-miura': {
  //   talent: {
  //     id: 'k-miura',
  //     name: '三浦孝紀'
  //   },
  //   media: {
  //     image: {
  //       2022: birthday_2022_prize_k_miura_jpg,
  //       2021: birthday_2021_prize_k_miura_jpg,
  //     },
  //     caption: `お誕生日おめでとうございます！！！
  //     今年の一年があなたにとって
  //     煌びやかな年になることを
  //     心から祈ってます！
  //     笑顔のたえない年にしましょう！`
  //   }
  // },
  'birthday.y-tamaki': {
    talent: {
      id: 'y-tamaki',
      name: '玉城裕規'
    },
    media: {
      image: {
        2024: birthday_2024_prize_y_tamaki_jpg,
        2023: birthday_2023_prize_y_tamaki_jpg,
        2022: birthday_2022_prize_y_tamaki_jpg,
        2021: birthday_2021_prize_y_tamaki_jpg,
        2020: birthday_2020_prize_y_tamaki_jpg,
      },
      caption: {
        2024: `Happy birthday。。＊

        笑顔溢れる
        素敵な歳になりますよう
        心より祈ってます。。
        
        いつも
        ありがとうね。。＊
        
        日々Smile enjoy。。＊`,
        2023: `お誕生日おめでとうございます‼️＊‼️

        今年もこうしてお祝い出来る事を
        嬉しく思います。。＊
        
        例えば、、
        辛い事等があったとしても
        それは人生においては大事な事。
        それを経て強く素敵になれる……的な事を
        今年は言ったりしようと思ったけど、、、
        そんな事は結果どうでも良くて
        …やはり笑顔ですわ‼️＊‼️
        色んな言葉、事を
        笑顔にして共に
        enjoyしていきましょ。。＊
        
        そしたら素敵な日々が待ってるさぁね＊
        スマイルenjoy‼️＊‼️
        産まれて来てくれてありがとうね。。＊
        `,
        2022: `わっふぅ〜ぃ＊！＊
      お誕生日おめでとうございます♪♪
      笑顔溢れる素敵な歳になりますように。。＊
      
      僕も良いモノをお届け出来る様、精進致します。。！
      
      お身体ご自愛の程、、
      日々スマイルenjoyです。。＊
      ＊＊愛＊＊`}
    }
  },
  'birthday.s-matsuda': {
    talent: {
      id: 's-matsuda',
      name: '松田昇大'
    },
    media: {
      image: {
        2024: birthday_2024_prize_s_matsuda_jpg,
        2023: birthday_2023_prize_s_matsuda_jpg,
        2022: birthday_2022_prize_s_matsuda_jpg,
        2021: birthday_2021_prize_s_matsuda_jpg,
        2020: birthday_2020_prize_s_matsuda_jpg,
      },
      caption: {
        2024: `誕生日おめでとう！！！
        去年よりも更に良い年にしよう！！
        そして笑顔溢れる一年にしようね！`,
        2023: `お誕生日おめでとうございます！
        どんな一年でしたか？？
        誕生日を迎え、素敵な実りのある一年になりますように！！
        そして共に素敵な日々になりますように！！
        
        Happy Birthday!!
        Luv Matey‼️
        `,
        2022: `お誕生日おめでとう！！
      年に一度しかないこの日をお祝いできて僕も嬉しいです！！
      素敵な一年になりますよう祈ってます！
      いや、共にしていきましょう！！
      
      Happy Birthday`}
    }
  },
  'birthday.y-hirata': {
    talent: {
      id: 'y-hirata',
      name: '平田裕一郎'
    },
    media: {
      image: {
        2024: birthday_2024_prize_y_hirata_jpg,
        2023: birthday_2023_prize_y_hirata_jpg,
        2022: birthday_2022_prize_y_hirata_jpg,
        2021: birthday_2021_prize_y_hirata_jpg,
        2020: birthday_2020_prize_y_hirata_jpg,
      },
      caption: {
        2024: `ハッピーバースデー！
        お誕生日おめでとう！
        産まれてきてくれてありがとう！
        出逢ってくれてありがとう！
        感謝感謝のおめでたい日！
        素敵な一年になりますように！
        深まる日々に微笑みを。
        裕一郎より。`,
        2023: `お誕生日おめでとう。
        あなたが産まれた日。
        幸せな日。
        happy birthday！！
        幸せな誕生日！！
        日常にある幸せを…
        今年もより笑顔で過ごせますように。
        僕が少しでも笑顔をプレゼント出来るように頑張るね！
        笑顔で居てね！
        `,
        2022: `happy birthday！！
          産まれてきてくれてありがとう！
          出逢ってくれてありがとう！！
          そして、素敵な一年になりますように！！
          笑顔が増える一年になりますように！！
          共に笑おうね！`
      }
    }
  },
  // 'birthday.t-nagaishi': {
  //   talent: {
  //     id: 't-nagaishi',
  //     name: '永石匠'
  //   },
  //   media: {
  //     image: {
  //       2022: birthday_2022_prize_t_nagaishi_jpg,
  //       2021: birthday_2021_prize_t_nagaishi_jpg,
  //       2020: birthday_2020_prize_t_nagaishi_jpg,
  //     },
  //     caption: `お誕生日おめでとうございます！
  //     お祝いできるのが幸せです。
  //     もっともっと楽しい１年になるように頑張るので、ずっとずっと一緒にいてください！`
  //   }
  // }
};

export default ({user}) => {
  const n = moment();
  const r = moment(moment(user.registered_at).format('YYYY-MM-DD 00:00:00'));
  const b = moment(user.birthday).year(n.year());

  const pb = moment(user.birthday).year(n.year() - 1);

  const past3m = moment(r).add(3, 'M');
  const past6m = moment(r).add(6, 'M');
  const past1y = moment(r).add(1, 'y');

  const now_year = moment().year();
  const prev_year = moment().year() - 1;

  let acquires = [];
  let pasted = [];

  const pasts = {
    j: r,
    m3: past3m,
    m6: past6m,
    y1: past1y,
  };

  for (const e in pasts) {
    const p = pasts[e];
    if (n.isBetween(p, moment(p).add(1, 'w'))) {
      acquires.push({
        ...prizes[e],
        published_at: p,
        year: now_year,
      });
    } else {
      if (n.isAfter(p)) {
        pasted.push({
          ...prizes[e],
          published_at: p
        })
      }
    }
  }

  if (n.isBetween(b, moment(b).add(1, 'w'))) {
    acquires = acquires.concat(user.follows.map(f => f.id).map(slug => {
      if ( prizes[`birthday.${slug}`] ) {
        return {
          ...prizes[`birthday.${slug}`],
          media: {
            ...prizes[`birthday.${slug}`].media,
            caption: prizes[`birthday.${slug}`].media.caption[now_year],
            image: prizes[`birthday.${slug}`].media.image,
          },
          year: now_year,
          published_at: b
        }
      }
    }))
  }
  if (n.isBetween(pb, moment(pb).add(1, 'w'))) {
    acquires = acquires.concat(user.follows.map(f => f.id).map(slug => {
      if ( prizes[`birthday.${slug}`] ) {
        return {
          ...prizes[`birthday.${slug}`],
          media: {
            ...prizes[`birthday.${slug}`].media,
            caption: prizes[`birthday.${slug}`].media.caption[prev_year],
          },
          year: prev_year,
          published_at: pb
        }
      }
    }))
  }


  const past_prizes = [
    prizes.j,
    prizes.m3,
    prizes.m6,
    prizes.y1
  ]

  return acquires.map((pz, i) =>
    <ActivityCard key={i} activity={pz} prize now_year={pz ? pz.year : now_year} past_prizes={past_prizes}/>
  )
}