import kakegawa_jpg from '../img/talent_kakegawa.jpg'
import miura_jpg from '../img/talent_miura.jpg'
import matsuda_jpg from '../img/talent_matsuda.jpg'
import tamaki_jpg from '../img/talent_tamaki.jpg'
import hirata_jpg from '../img/talent_hirata.jpg'
import nagaishi_jpg from '../img/talent_nagaishi.jpg'
import ishii_jpg from '../img/talent_ishii.jpg'

export default slug => {
  switch(slug) {
    case 'r-kakegawa': return kakegawa_jpg;
    case 'k-miura': return miura_jpg;
    case 's-matsuda': return matsuda_jpg;
    case 'y-tamaki': return tamaki_jpg;
    case 'y-hirata': return hirata_jpg;
    case 't-nagaishi': return nagaishi_jpg;
    case 'r-ishii': return ishii_jpg;
    default: return 'https://placehold.it/400x400.jpg';
  }
};