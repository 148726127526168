import React from 'react';

export default ({radius, color, value}) => {
  const $ = React.createRef();
  
  React.useEffect(() => {
    const ctx = $.current.getContext('2d');
    const a = Math.PI * 2 * value / 100;

    $.current.width = $.current.width;

    ctx.save();
    ctx.moveTo(radius, radius);
    ctx.arc(radius, radius, radius, a, 0, false);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.restore();
  });

  return <canvas ref={$} width={radius*2} height={radius*2}/>
}