import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import RichText from '../ui/richtext';
import Container from '../ui/container';

const txt = `
個人情報保護方針

１　個人情報保護方針の策定及び継続的改善
株式会社ISM ENTERTAINMENTおよび弊社オフィシャルファンクラブISM FC（以下、これらを併せて「ISM」といいます）は、役員および従業員に個人情報保護の重要性を認識させ、個人情報を適切に保護するための個人情報保護方針その他の規程を策定、実施、維持し、継続的に改善します。
２　個人情報保護方針の遵守
ISMは、個人情報保護方針に従い、各部門の業務内容および規模を考慮した適切な個人情報保護のための管理体制を確立し、個人情報を適切に収集、利用、提供するとともに、ご本人への個人情報の開示や訂正等、情報主体への適切な対応を行います。
３　個人情報の正確性・安全性の確保
ISMは、個人情報の正確性および安全性を確保するため、個人情報を正確かつ最新の内容に保つよう努めるとともに、情報通信技術および管理組織体制の両面から合理的な安全対策を講じて、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏洩等の防止に努めます。
４　個人情報保護に関する法令およびその他の規範遵守
ISMは、個人情報の保護に関する法令の規定および行政機関その他が特に定めた規範、ガイドライン等を遵守します。

 
プライバシーポリシー

株式会社ISM ENTERTAINMENTおよびそのオフィシャルファンクラブISM FC（以下「ISM」といいます）では、お客様の個人情報に関して、「個人情報の保護に関する法律」（平成15年法律第57号。その後の改正も含みます。以下同じです。）その他の法令を遵守し、以下に定める内容に従い、適正に取り扱うことをお約束します。本プライバシーポリシーの全文をお読みいただいて、本プライバシーポリシーにご同意いただいたうえで、ISMのサービスをご利用いただくようお願いいたします。

１　個人情報及びその管理について
⑴　個人情報とは、個人に関する情報であり、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）を指します。
⑵　ISMは、個人情報の漏洩、滅失、き損等の防止のために、法令、ガイドラインに従い、適切な安全管理策を施し、保有する個人情報の保護に努めます。また、従業員に対しても個人情報の適切な取扱い等についての教育を行い、その保護に万全を期するよう努めます。

２　個人情報の取得と利用目的について
⑴　ISMは、お客様の個人情報を取得する場合は、適正な手段で行います。なお、ISMが個人情報の取得を意図し、お客様から書面やWebを通じて直接提供いただく場合、個人情報の利用目的を明示いたします。但し、提供時の状況から利用目的が明らかな場合は、利用目的の明示を省略させていただくことがあります。
⑵　ISMは、以下に掲げる利用目的の達成に必要な範囲内において個人情報を利用いたします。
　ISMの商品・サービスをご利用頂いたお客様またはISMの商品・サービスにご関心をお持ちのお客様の個人情報の利用目的
	①　商品のご購入の確認やお届けをするため
	②　イベントへのお申し込みの確認や、入場券などのお届けをするため
	③　電子メール配信サービスのお申し込みの確認やメールの配信をするため
	④　各種会員制サービスへの登録の確認やサービスの提供をするため
	⑤　ご協力いただいたアンケートに対して謝礼等をお送りするため
	⑥　ご応募いただいた懸賞などに対する景品等をお送りするため
⑦　ご利用いただいている商品・サービスの提供・改良や、新たなサービスを開発するため
⑧　商品・サービス、Webサイトなどの内容を皆様がよりご満足いただけるよう改善するため
⑨　商品・サービス、Webサイトなどの内容を個々のお客様に合わせてカスタマイズするため
⑩　お客様が注文された情報や商品などに関する満足度を調査するため
⑪　お客様の利用状況を把握し、サービスの改善や新サービスの開発に役立てるため
⑫　提供している商品及びサービス並びにアーティストに関連した情報などをお届けするため
⑬　ISM所属のアーティストや、各種イベント、各種会員制サービス及びその他各種サー　　　ビスに関するご案内をお届けするため
	⑭　アンケートへのご協力依頼や、その結果などをご報告するため　　
⑮　会員登録の確認並びに各ファンクラブの入会、継続及び退会等の手続に関する連絡の　　ため
	⑯　ファンクラブのサービス提供及びお問い合わせ等のサポート業務のため
⑰　お客様にアーティストに関するアンケートを実施し、またはその謝礼をお届けするため
⑱　お客様の属性の集計・分析を行い、新規サービスの検討や開発等を行ったり、お客様　の趣向に沿った商品、サービスおよびアーティストに関する案内を行ったりするため
⑲　お客様のファンクラブへの入会、更新、退会等の手続に関する連絡のため
⑳　前各号に掲げるほか、前各号に密接に関連する利用目的のため
⑶　ISMは、合併、分社化、営業譲渡等で事業を承継し個人情報を取得した場合は、承継前にご本人に同意を得ている範囲内または通知もしくは公表した利用目的の達成に必要な範囲内で利用いたします。
⑷　ISMは、以下の各号に定める場合を含む法令で認められる場合には、上記利用目的の範囲外であってもあらかじめお客様の同意を得ないで、個人情報を取得または利用させていただ　く場合があります。
ア　法令に基づく場合
イ　人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき。
ウ　公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき。
エ　国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。

３　個人データの第三者への提供について
⑴　ISMは、以下の各号に定める場合を含む法令で認められる場合を除き、お客様の個人データを第三者に提供することはいたしません。
ア　あらかじめお客様の同意をいただいている場合
イ　人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意をいただくことが困難であるとき
ウ　公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意をいただくことが困難であるとき
エ　国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意をいただくことにより当該事務の遂行に支障を及ぼすおそれがあるとき
オ　第三者に提供される個人データ（要配慮個人情報を除きます。以下この号において同じです。）について、お客様の求めに応じて当該お客様が識別される個人データの第三者への提供を停止することとしている場合であって、法令で定める事項について、あらかじめ、お客様に通知し、又はお客様が容易に知り得る状態に置くとともに、個人情報保護委員会に届け出たとき
カ　サービス利用に伴うクレジットカード等の決済手続きに際してユーザーの銀行口座やクレジットカード等の正当性を金融機関またはカード会社等に確認する場合　
⑵　ISMは、利用目的の達成に必要な範囲内においてお客様の個人データの取扱いの全部又は一部を委託することに伴って当該個人データを委託先に提供することがあります。この場合、その取扱いを委託した個人データの安全管理が図られるよう、委託先に対して必要かつ適切な監督を行います。
⑶	ISMは、合併その他の事由による事業の承継に伴ってお客様の個人データを提供することがあります。
	
４　個人データの共同利用について
⑴　ISMは、ISM FCで総合的なサービスを提供するために、利用目的の達成に必要な範囲内で、ISMが取得する個人データを、ISM内で共同利用する場合があります。
⑵　ISMが共同利用する個人データの項目は、次の通りです。 氏名・性別・年齢・生年月日・職業・住所・電話番号・メールアドレス・商品またはサービスのご購入履歴・アンケートや懸賞へのご回答内容
⑶　ISMは、次に掲げる利用目的の達成に必要な範囲において個人情報を共同利用することがあります。
ア　ご利用いただいている商品・サービスの提供・改良や、新たなサービスを開発するため
イ　商品・サービス、Webサイトなどの内容を皆様がよりご満足いただけるよう改善するため
ウ　商品・サービス、Webサイトなどの内容を個々のお客様に合わせてカスタマイズするため
エ　お客様が注文された情報や商品などに関する満足度を調査するため
オ　お客様の利用状況を把握し、サービスの改善や新サービスの開発に役立てるため
カ　お客様の属性の集計・分析を行い、新規サービスの検討や開発等を行ったり、お客様の趣向に沿った商品、サービスおよびアーティストに関する案内を行ったりするため
キ　提供している商品・サービスに関連した情報などをお届けするため
ク　ISMのアーティストや、各種イベントに関するご案内をお届けするため
ケ　各種会員制サービス、その他各種サービスのご案内をお届けするため
コ　アンケートへのご協力をお願いや、その結果などをご報告するため
⑷　共同利用の管理責任者は株式会社ISM ENTERTAINMENTとする。

５　掲示板等への個人情報の書き込みについて
ISMでは、各アーティストのホームページ等にお客様が書き込むことができる掲示板等のサービスを提供した場合、掲示板等への書き込みの中でお客様が自ら開示した個人情報は、他の利用者によっても閲覧可能となりますので、十分にご注意ください。また、ISMのサイトは、ISM以外のサイトへリンクを行っている場合があります。ISMは、リンク先のサイトでの個人情報の取扱いに関しましては責任を負いかねますので、ご了承ください。

６　個人情報の開示等のお問い合わせと手続について
⑴　ISMでは、お客様ご本人または代理人の方が、お客様の個人情報に関して訂正、追加、削除（退会）等を希望される場合は、以下⑵の窓口までご連絡いただきますよう、お願い申し上げます。
なお、ISM内で共同利用される個人情報の管理責任者は、株式会社ISM ENTERTAINMENTであり、以下の窓口をISMの統一窓口とさせていただいております。
お客様ご自身がWeb上から登録された会員情報等につきましては、お客様ご自身で、お客様の個人情報を訂正、追加、削除（退会）することができます。
⑵　個人情報については、郵送またはWeb上にて下記宛にお問い合わせください。
ア　郵送の場合　
　　106-0032 東京都港区六本木3-9-11
株式会社ISM ENTERTAINMENT　個人情報保護管理責任者宛
　　イ　WEBの場合
	　問い合わせフォームよりご連絡ください。
⑶　お問い合わせの際に、お客様ご本人またはお客様の正当な代理人であるかについて、確認を求める場合がございますので、あらかじめご了承ください。開示、訂正等をご希望の場合は、諸手続きについて、窓口にお問い合わせ下さい。
⑷　以下の事項に該当する場合、お問い合わせまたはご要望に応じられない場合がありますので、あらかじめご了承下さい。
ア　ご本人または正当な代理人との確認ができない場合
イ　ISMの保有個人データに該当しない個人情報に関して開示、訂正、追加、削除、利用停止等のご請求をされた場合
ウ　ISMが保有個人データを開示することによって、ご本人または第三者の生命・身体・財産その他の権利利益を害するおそれがある場合
エ　ISMが保有個人データを開示することによって、ISMの業務の適正な実施に著しい支障を及ぼすおそれがある場合
オ　ISMが保有個人データを開示することによって、他の法令に違反することとなる場合
カ　ISMの保有個人データに関して訂正、追加等のご請求をされた場合に、利用目的からして訂正等が必要ない場合やご要求が事実とは相違する場合
キ　ISMの保有個人データに関して削除、利用停止等（以下「利用停止等」といいます。）のご請求をされた場合に、ISMの手続上の違反が認められない場合
ク　ISMの保有個人データに関して利用停止等のご請求をされた場合に、利用停止等を行うことが困難な場合であって、かつ代替手段によりお客様の権利利益を保護しうる場合
⑸　ISMでは、13歳未満のお客様の個人情報の取扱いについては、特別な配慮を行います。13歳未満のお客様につきましては、必ず保護者の方の承認を得たうえで、個人情報を提供していただきますようお願いいたします。

７　Webサイトにおける個人情報の取扱いについて
⑴　クッキーについて
クッキーは、お客様がWebサイトを快適にご利用できるように、Webサーバがお客様のブラウザに送信する情報です。ISMでは、Webサイトをご利用になるお客様に対し、クッキーを使用することがあります。なお、お客様は、クッキーの受け取りを拒否するように設定することも出来ますが、その場合、当Webサイトが提供するサービスの全部または一部をご利用できなくなることがありますのでご了承ください。
⑵　リンク先における個人情報の保護
WebサイトからリンクされているISM以外が運営するWebサイトにおける個人情報の取扱いについては、ISMは責任を負うことはできません。
	
８　改定について
ISMは、法令の変更等に伴い、本プライバシーポリシーを変更することがあります。
`;

export default () => <Container css={{paddingTop: 50, fontSize: 11}}><RichText text={txt}/></Container>;