import React from 'react'
/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import Container from '../ui/container'
import faq from '../faq';
import theme from '../ui/theme';
import { Link } from 'react-router-dom';
import { OutlineButton, OutlinePillNavButton } from '../ui/button';
import RichText from '../ui/richtext';
import SectionLabel from '../ui/section-label';

export default ({match}) => <Question question={faq.get(match.params.question)}/>

export const Question = ({question}) => (
  <Container css={{paddingTop: 50}}>
    <SectionLabel label="よくあるご質問"/>
    <div css={css`
      background-color: #E8ECEF;
      padding: 10px;
      border-radius: 5px;
    `}>
      <header css={css`
        padding: 10px 0;
        border-bottom: 1px solid #CCC;
      `}>
        <h1 css={css`
          font-size: 18px;
        `}>{question.question}</h1>
      </header>
      <div css={css`
        padding: 10px 0;
      `}>
        {question.answer}
      </div>
    </div>
    <div css={{marginTop: 40}}>
      <OutlineButton to="/contact" fit color={theme.colors.primary}>
        <div css={{display: 'flex', alignItems: 'center'}}>
          <div css={{flex: 'auto'}}>
            この回答で解決しない場合<br/>
            お問合せはこちら
          </div>
          <span className="material-icons" css={{color: theme.colors.primary}}>keyboard_arrow_right</span>
        </div>
      </OutlineButton>
    </div>
    <div css={{marginTop: 40}}>
      <OutlinePillNavButton to="/faq/" color={theme.colors.accent} caret="left">よくあるご質問一覧</OutlinePillNavButton>
    </div>
  </Container>
)