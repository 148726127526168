import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

export default ({title}) => (
  <Root>{title}</Root>
);

const Root = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;