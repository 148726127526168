import moment from 'moment';
import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import SettingHeader from '../ui/setting-header';
import Button from '../ui/button';
import {Center} from '../ui/flex';
import {Padding} from '../ui/spacing';
import theme from '../ui/theme';
import App from '../app';
import api from '../api';
import Container from '../ui/container';
import { OutlinePillNavButton } from '../ui/button';

export default () => {
  const app = React.useContext(App);
  const [deny, setDeny] = React.useState(app.user.deny_magazine);

  const submit = () => {
    if ( !deny ) {
      api().patch('/user/deny_magazine')
        .then(rs => rs.data.user)
        .then(app.resetUser)
        .then(() => {
          app.flash('メールマガジンの配信を解除しました');
          setDeny(true);
        });
    } else {
      api().patch('/user/allow_magazine')
        .then(rs => rs.data.user)
        .then(app.resetUser)
        .then(() => {
          app.flash('メールマガジンの配信を登録しました');
          setDeny(false);
        });
    }
  };

  return <Magazine user={app.user} onSubmit={submit} deny={deny}/>
};

const Magazine = ({user, onSubmit, deny}) => {

  const submit = ev => {
    ev.preventDefault();
    onSubmit();
  };

  return (
    <Container css={{paddingTop: 50}}>
      <form onSubmit={submit}>
        <p css={{textAlign: 'center'}}>メールマガジンの受信: {deny ? 'OFF' : 'ON'}</p>
        <Padding top="20px">
          <Center>
            <Button>{deny ? 'ONにする' : 'OFFにする'}</Button>
          </Center>
        </Padding>
      </form>
      <div css={{marginTop: 40}}>
        <OutlinePillNavButton color={theme.colors.accent} to="/settings" children="設定へ戻る" caret="left"/>
      </div>
    </Container>
  );
}
