import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';
import Button from '../ui/button';
import {FormField} from '../ui/form';
import theme from '../ui/theme';

const stripe = loadStripe(process.env.STRIPE);

export default ({submitLabel, onResult}) => (
  <Elements stripe={stripe}>
    <Fragment submitLabel={submitLabel} onResult={onResult}/>
  </Elements>
);

const Fragment = ({submitLabel, onResult}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);

  const createPaymentMethod = async () => {
    const rs = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)
    });

    if (rs.error) throw rs.error;

    return rs.paymentMethod.id;
  };

  const submit = async () => {
    setDisabled(true);

    try {
      const card = await createPaymentMethod();
      setErrors({})
      await onResult({payload: card});
    } catch(e) {
      switch(e.code) {
        case "incomplete_number":
          setErrors({card_number: true});
          break
        case "incomplete_expiry":
          setErrors({card_expiry: true});
          break
        case "incomplete_cvc":
          setErrors({card_cvc: true});
          break
        default:
          setErrors({})
          await onResult({error: e});
      }
    }

    setDisabled(false);
  };

  return <View submitLabel={submitLabel} onSubmit={submit} errors={errors} disabled={disabled}/>;
};

export const View = ({submitLabel='送信', disabled, onSubmit, errors}) => (
  <div>
    <Card>
      <FormField label="カード番号">
        <Card.Input error={errors.card_number}>
          <CardNumberElement/>
        </Card.Input>
      </FormField>
      <FormField label="有効期限">
        <Card.Input error={errors.card_expiry}>
          <CardExpiryElement/>
        </Card.Input>
      </FormField>
      <FormField label="セキュリティコード">
        <Card.Input error={errors.card_cvc}>
          <CardCvcElement/>
        </Card.Input>
      </FormField>
    </Card>
    <Button onClick={onSubmit} image={theme.colors.accentGradient} fit disabled={disabled}>{submitLabel}</Button>
  </div>
);

const Card = ({children}) => children;

Card.Input = ({children, error}) => <div css={css`
  padding: 8px;
  margin-top: 10px;
  ${error ? 'border: 2px solid red;' : 'border: 1px solid #CCC;'}
`}>{children}</div>;