import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    word-break: break-all;
  }

  h1,
  h2,
  h3,
  h4
  h5,
  h6 {
    margin: 0;
    font-size: inherit;
  }

  p {
    margin: 0;
  }

  hr {
    margin: 0;
    border-top: 1px solid black;
    border-bottom: none;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  figure {
    margin: 0;
  }

  body {
    margin: 0;
    font-family: YuGothic, “Yu Gothic”, sans-serif;
    font-size: 16px;
    color: #222;
    text-size-adjust: none;
  }
`;