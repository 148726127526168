import React from 'react';
import { Helmet } from 'react-helmet';

/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from 'styled-components';
import SectionLabel from '../ui/section-label';
import RichText from '../ui/richtext';
import Container from '../ui/container';
import TalentAvatar from '../ui/talent-avatar';
import api from '../api';
import Button from '../ui/button';
import theme from '../ui/theme';
import BlogBanner from '../ui/blog-banner';
import { Link } from 'react-router-dom';
import {time} from '../fmt';
import LimitedCard from '../ui/limited-card';
import App from '../app';
import FavIcon from '../img/fav.svg';
import FavIconRed from '../img/fav_red.svg';
import CommentIcon from '../img/comment.svg';
import ArrowIcon from '../img/arrow.svg';

export default ({match}) => {
  const app = React.useContext(App);

  return <Screen key={match.params.post} match={match} user={app.user}/>
}

class Screen extends React.Component {
  constructor() {
    super();
    this.state = {
      faved: false,
      fav_count: 0,
      comments: [],
      open_comment_input: false,
      open_comments: false,
      nickname: "",
      text: "",
    };
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    
    api()(`/talents/${params.artist}/blog/${params.post}`)
      .then(rs => {
        this.setState({
          post: rs.data.post,
          fav_count: rs.data.post.fav_count,
          faved: rs.data.post.favorited,
          comments: rs.data.post.comments,
        });
        if ( rs.data.meta.links.prev ) {
          api()(rs.data.meta.links.prev)
            .then(rs => rs.data.post)
            .then(post => this.setState({prev: post}))
        }
        if ( rs.data.meta.links.next ) {
          api()(rs.data.meta.links.next)
            .then(rs => rs.data.post)
            .then(post => this.setState({next: post}))
        }

      });
  }
  
  render() {
    const {match: {params}, user} = this.props;
    const {
      post,
      prev,
      next,
      faved,
      fav_count,
      comments,
      nickname,
      text,
      open_comment_input,
      open_comments,
    } = this.state;
    const doFav = () => {
      if ( faved ) {
        api().delete(`/talents/${params.artist}/blog/${params.post}/favorite`, {})
          .then((res) => {
            this.setState({faved: false, fav_count: fav_count - 1})
          })
      } else {
        api().post(`/talents/${params.artist}/blog/${params.post}/favorite`, {})
          .then((res) => {
            this.setState({faved: true, fav_count: fav_count + 1})
          })
      }
    }
    const addComment = (nickname, text) => {
      if ( text ) {
        if ( text.length > 300 ) {
          alert('コメントは300文字以内で入力してください');
          return false;
        }
        api().post(`/talents/${params.artist}/blog/${params.post}/comments`, {nickname: nickname || '匿名', text})
        .then((res) => {
          this.setState({
            open_comment_input: false,
            open_comments: true,
            nickname: "",
            text: "",
            comments: [{
              id: res.data.id,
              icon: user.avatar,
              nickname: nickname || '匿名',
              text,
              mine: true,
            }, ...comments]
          })
        })
      }
    }
    const doDeleteComment = (id) => {
      if ( window.confirm('コメントを削除してよろしいですか？') ) {
        api().delete(`/comments/${id}`)
        .then((res) => {
          this.setState({
            comments: [...comments.filter(c => c.id !== id)]
          })
        })
      }
    }
    const setValue = (key, e) => {
      console.log(key, e.target.value)
      this.setState({
        [key]: e.target.value
      })
    }
    const toggleCommentInput = () => {
      this.setState({
        open_comment_input: !open_comment_input
      })
    }
    const toggleComments = () => {
      this.setState({
        open_comments: !open_comments,
      })
    }

    if ( post ) {
      document.title = post.title + ' | ISM FC';
      const headData = document.head.children;  
      for (let i = 0; i < headData.length; i++) {
        const nameVal = headData[i].getAttribute('property');
        if (nameVal !== null) {
          if (nameVal.indexOf('og:image') != -1 && post.og_image) {
              headData[i].setAttribute('content', post.og_image);
          }
          if (nameVal.indexOf('og:url') != -1) {
              headData[i].setAttribute('content', 'https://ism-fc.com/news/' + post.id);
          }
        }
      }
    }

    return post ? <Post
      setValue={setValue}
      user={user}
      nickname={nickname}
      text={text}
      post={post}
      prev={prev}
      next={next}
      comments={comments}
      faved={faved}
      fav_count={fav_count}
      doFav={doFav}
      addComment={addComment}
      toggleCommentInput={toggleCommentInput}
      toggleComments={toggleComments}
      open_comment_input={open_comment_input}
      open_comments={open_comments}
      doDeleteComment={doDeleteComment}
    /> : <React.Fragment/>;
  }
};

export const Post = ({
  user,
  nickname,
  text,
  post,
  prev,
  next,
  comments,
  faved,
  fav_count,
  doFav,
  addComment,
  setValue,
  toggleCommentInput,
  toggleComments,
  open_comment_input,
  open_comments,
  doDeleteComment
}) => {
  const app = React.useContext(App)

  return <>
  <BlogBanner talent={post.talent}/>
  <Container>
    <div css={{marginBottom: 15}}>
      <Artist artist={post.talent}/>
    </div>
    <div css={css`
      background-color: #E8ECEF;
      padding: 10px;
      marginTop: 10px;
      border-radius: 5px;
    `}>
      <header css={css`
        padding: 10px 0;
        border-bottom: 1px solid #CCC;
      `}>
        <h1 css={css`
          font-size: 18px;
        `}>{post.title}</h1>
        <div css={css`
          color: #999;
          margin-top: 20px;
        `}>{time(post.published_at)}</div>
      </header>
      <div css={css`
        padding: 10px 0;
      `}>
        <RichText text={post.text}/>
        {!app.signed && <LimitedCard grad={post.text.length > 0} />}
        {app.signed && post.image && <img src={post.image} css={{
          width: '100%',
          height: 'auto',
          marginTop: 20
        }}/>}
      </div>

      <div>
        <div>
          {post.talent.name}
        </div>
        <div css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 7.7px;
        `}>
          <div css={css`
            display: flex;
            align-items: center;
            gap: 3px;
            font-size: 12px;
            cursor: pointer;
            img {
              width: 18.52px;
              height: 15px;
            }
          `} onClick={doFav}><span>{fav_count}</span><img src={faved ? FavIconRed : FavIcon} /></div>
          <div css={css`
            cursor: pointer;
            height: 16.51px;
            img {
              width: 15.76px;
              height: 100%;
            }
          `} onClick={toggleCommentInput}><img src={CommentIcon} /></div>
        </div>
        <div css={css`
          display: ${open_comment_input ? `block` : `none`};
          ${open_comment_input && `animation: fade-in .5s;`};
          @keyframes fade-in {
            0% {opacity: 0;}
            100% {opacity: 1;}
          }
        `}>
          <div>{comments.length}件のコメント</div>
          <div css={css`
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            gap: 10px;
            input, textarea {
              background-color: transparent;
              border: 1px solid #9A9A9A;
              padding: 10px;
            }
          `}>
            <input type="text" value={nickname} placeholder="ニックネームを入力" onChange={(e) => setValue('nickname', e)}/>
            <textarea placeholder="コメントする" onChange={(e) => setValue('text', e)} value={text} />
            <div css={css`width: 200px; display: flex; justify-content: center; margin: auto`}><Button image={theme.colors.accentGradient} fit onClick={(e) => addComment(nickname, text)}>送信する</Button></div>
          </div>
        </div>

        <div css={css`
          display: ${open_comments ? `block` : `none`};
          ${open_comments && `animation: fade-in .5s;`};
          @keyframes fade-in {
            0% {opacity: 0;}
            100% {opacity: 1;}
          }
        `}>
          <div css={css`
            display: flex;
            flex-direction: column;
            gap: 10px;
          `}>
            {comments.map((c,i) => <div key={i}>
              <div css={css`
                display: flex;
                gap: 8px;
              `}>
                <div css={css`
                  width: 20px;
                  img {
                    width: 100%;
                  }
                `}>
                  <span css={css`
                    overflow: hidden;
                    border-radius: 50%;
                  `
                }><img src={c.icon} /></span>
                </div>
                <div css={css`flex: 1; width: 100%;`}>
                  <div css={css`font-weight: bold; font-size: 9px; margin-bottom: 5px;`}>{c.nickname}</div>
                  <div css={css`font-size: 9px; white-space: pre-line;`}>{c.text}</div>
                  {c.mine && <div css={css`font-size: 9px; margin-top: 5px; text-decoration: underline;`} onClick={() => doDeleteComment(c.id)}>削除</div>}
                </div>
              </div>
            </div>)}
          </div>
        </div>

        <div css={css`
          margin: 20px 0 0;
          padding-bottom: 10px;
          font-size: 9px;
          display: ${comments.length > 0 ? `flex` : `none`};
          width: 100%;
          gap: 8px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            ${open_comments && `transform: rotate(180deg);`}
          }
        `} onClick={toggleComments}>
          <img src={ArrowIcon} />
          <span>コメントを{open_comments ? `非表示` : `表示`}</span>
        </div>
      </div>
    </div>
    <div css={css`
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 20px 0 10px;
    `}>
      <Navigation post={next} flg="next"/>
      <Navigation post={prev} flg="prev"/>
    </div>
  </Container>
</>
}

const Navigation = ({post, flg}) => (
  <div css={{
    width: "50%",
    padding: flg == "prev" ? "0 0 0 10px" : "0 10px 0 0",
    textAlign: flg == "prev" ? "right" : "left",
    borderRight: flg == "prev" ? 0 : "1px solid #000"
  }}>
    {
      post ?
        (
          <Link to={`/artists/${post.talent.id}/blog/${post.id}`}>
            <p>{flg == 'prev' ? "前の投稿 >" : "< 次の投稿"}</p>
            <p css={css`padding: 5px 0;`}>{post.title}</p>
          </Link>
        )
        :
        (
          <span>
            <p>{flg == 'prev' ? "前の投稿 >" : "< 次の投稿"}</p>
            <p css={css`padding: 5px 0;`}>投稿はありません</p>
          </span>
        )
    }
  </div>
);

const Artist = ({artist}) => (
  <div css={{display: 'flex', alignItems: 'center'}}>
    <span css={{marginRight: 10, marginTop: -25}}>
      <TalentAvatar radius={50} talent={artist}/>
    </span>
    <span css={{fontSize: 18, fontWeight: 'bold', flex: 'auto'}}>
      {artist.name}
    </span>
    <span css={{flex: 'none'}}>
      <Button padding={{x: 5, y: 0}} borderRadius={12.5} color={theme.colors.accent}>
        <div css={{display: 'flex', alignItems: 'center', height: 25}}>
          <Link to={`/artists/${artist.id}`} css={{fontSize: 12}}>ARTISTトップ</Link>
          <span className="material-icons">keyboard_arrow_right</span>
        </div>
      </Button>
    </span>
  </div>
);