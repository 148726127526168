import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import Container from '../ui/container';
import StaffArticleTile from '../ui/staff-article-tile';
import {Padding} from '../ui/spacing';
import NewsTabBar from '../ui/news-tab-bar';
import api from '../api';
import InfiniteScroll from '../ui/infinite-scroll';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    api()('/staffs/news')
      .then(rs => rs.data)
      .then(({news, meta: {links: {next}}}) => this.setState({
        snapshot: {news, next}
      }));
  }
  
  render() {
    const {history} = this.props;
    const {snapshot} = this.state;

    return snapshot ? <Paginator initialSlice={snapshot.news} initialNext={snapshot.next}>
      { (news, next) => <StaffsNews news={news} next={next} history={history}/>}
    </Paginator> : <React.Fragment/>;
  }
};

const Paginator = ({initialSlice, initialNext, children}) => {
  const [slice, setSlice] = React.useState(initialSlice)
  const [next, setNext] = React.useState(initialNext)

  const fetch = () => api()(next)
    .then(rs => rs.data)
    .then(({news, meta: {links: {next}}}) => {
      setSlice(slice.concat(news))
      setNext(next)
    }); 

  return children(slice, next ? fetch : null);
};

export const StaffsNews = ({news, history, next}) => (
  <InfiniteScroll next={next}>
    <Container css={{paddingTop: 50}}>
      <Padding bottom="10px">
        <NewsTabBar history={history}/>
      </Padding>
      <ul>{news.map(a => <StaffArticleTile key={a.id} article={a}/>)}</ul>
    </Container>
  </InfiniteScroll>
);

