import moment from 'moment';
import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import SettingHeader from '../ui/setting-header';
import {FormField} from '../ui/form';
import {TextInput} from '../ui/input';
import Button from '../ui/button';
import {Center} from '../ui/flex';
import theme from '../ui/theme';
import App from '../app';
import api from '../api';
import Container from '../ui/container';
import {BirthdayInput} from '../ui/birthday';
import { OutlinePillNavButton } from '../ui/button';

export default () => {
  const app = React.useContext(App);

  const submit = form => {
    api().put('/user', form)
      .then(rs => rs.data.user)
      .then(app.resetUser)
      .then(() => app.flash('ユーザー情報を保存しました'));
  };


  return <Profile user={app.user} onSubmit={submit}/>
};

const Profile = ({user, onSubmit}) => {
  const [name, setName] = React.useState(user.name);
  const [birthday, setBirthday] = React.useState(moment(user.birthday).format('YYYY/M/D') || '');

  const submit = ev => {
    ev.preventDefault();
    onSubmit({
      name,
      birthday: moment(birthday, 'YYYY/M/D').format('YYYY-MM-DD')
    });
  };

  return (
    <Container css={{paddingTop: 50}}>
      <form onSubmit={submit}>
        <SettingHeader title="ユーザー情報変更"/>
        <FormField label="名前">
          <TextInput value={name} onChange={setName}/>
        </FormField>
        <FormField label="生年月日">
          {birthday}
        </FormField>
        <Center>
          <Button fit image={theme.colors.accentGradient}>変更する</Button>
        </Center>
      </form>
      <div css={{marginTop: 40}}>
        <OutlinePillNavButton color={theme.colors.accent} to="/settings" children="設定へ戻る" caret="left"/>
      </div>
    </Container>
  );
}
