import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Container from '../ui/container';
import SectionLabel from '../ui/section-label';
import theme from '../ui/theme';
import Button from '../ui/button';

export default () => (
  <Container css={{paddingTop: 45}}>
    <SectionLabel label="新規会員登録"/>
    <p css={{textAlign: 'center'}}>
      会員登録確認のメールを送信いたしました。
    </p>
  </Container>
);