import kakegawa_png from '../img/talent_avatar_kakegawa.png'
import miura_png from '../img/talent_avatar_miura.png'
import tamaki_png from '../img/talent_avatar_tamaki.png'
import matsuda_png from '../img/talent_avatar_matsuda.png'
import hirata_png from '../img/talent_avatar_hirata.png'
import nagaishi_png from '../img/talent_avatar_nagaishi.png'
import ishii_png from '../img/talent_avatar_ishii.png'

export default slug => {
  switch(slug) {
    case 'r-kakegawa': return kakegawa_png;
    case 'k-miura': return miura_png;
    case 'y-tamaki': return tamaki_png;
    case 's-matsuda': return matsuda_png;
    case 'y-hirata': return hirata_png;
    case 't-nagaishi': return nagaishi_png;
    case 'r-ishii': return ishii_png;
    default: return 'https://placehold.it/400x400.jpg';
  }
};