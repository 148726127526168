import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import api from '../api';
import theme from '../ui/theme';
import {Link} from 'react-router-dom';
import Container from '../ui/container';
import TalentAvatar from '../ui/talent-avatar';
import {FollowButton} from '../ui/button';
import App from '../app';
import SectionLabel from '../ui/section-label';

export default class FollowsContainer extends React.Component {
  constructor() {
    super()
    this.state = {snapshot: null};
  }

  componentDidMount() {
    Promise.all([
      api()('/talents')
        .then(res => res.data.talents),
    ])
      .then(([talents]) => this.setState({
        snapshot: {talents}
      }))
  }

  render() {
    return <App.Consumer>{ app => {
      const {snapshot} = this.state;

      const toggleFollow = artist => {
        if (app.user.follows.filter(f => f.id == artist.id).length != 0) app.unfollow(artist);
        else app.follow(artist);
      };

      console.log('follows', app.user.follows);

      return snapshot ? <Follows artists={snapshot.talents} follows={app.user.follows || []} onToggleFollow={toggleFollow}/> : <React.Fragment/>;
    }}</App.Consumer>;
  }
};

const Follows = ({artists, follows, onToggleFollow}) => (
  <Container css={{paddingTop: 50}}>
    <SectionLabel label="アーティスト一覧"/>
    <ul>
      {artists.map(a => <ArtistTile key={a.id} artist={a} followed={follows.filter(f => f.id == a.id).length != 0} onToggleFollow={() => onToggleFollow(a)}/>)}
    </ul>
  </Container>
);

const ArtistTile = ({artist, followed, onToggleFollow}) => (
  <li css={css`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 20px;
    margin-top: 5px;
    border-radius: 5px;
    &:nth-of-type(odd) {
      background-color: #E8ECEF;
    }

    &:nth-of-type(even) {
      background-color: #F3F5F7;
    }
  `}>
    <div css={{marginRight: 10}}>
      <Link to={`/artists/${artist.id}`}><TalentAvatar talent={artist}/></Link>
    </div>
    <div css={{display: 'flex', alignItems: 'center', flex: 'auto'}}>
      <Link to={`/artists/${artist.id}`} css={{fontWeight: 'bold'}}>{artist.name}</Link>
    </div>
    <div css={{display: 'flex', alignItems: 'center', flex: 'auto', justifyContent: 'flex-end'}}>
      <FollowButton onClick={onToggleFollow} followed={followed} followCount={artist.followed}></FollowButton>
    </div>
  </li>
);