import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Container from '../ui/container';
import Button, { OutlinePillNavButton } from '../ui/button';
import {TextInput, PasswordInput} from '../ui/input';
import {FormField} from '../ui/form';
import theme from '../ui/theme';
import SectionLabel from '../ui/section-label';
import App from '../app';
import api from '../api';
import { Link } from 'react-router-dom';

export default ({history}) => {
  const app = React.useContext(App);
  const [forbidden, setForbidden] = React.useState(false);

  const submit = form =>
    api().post('/signin', form)
      .then(rs => rs.data.token)
      .then(token => app.signin(token))
      .then(() => history.push('/'))
      .then(() => app.flash('ログインしました'))
      .catch(() => app.flash('Eメール、またはパスワードが違います'));

  return <Login forbidden={forbidden} onSubmit={submit}/>;
};

const Login = ({forbidden, onSubmit}) => {
  const submit = ev => {
    ev.preventDefault();
    onSubmit({email, password});
  };

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const valid = email && password;

  return (
    <Container css={{paddingTop: 45}}>
      <SectionLabel label="LOGIN"/>
      <form onSubmit={submit}>
        <FormField label="Eメール">
          <TextInput value={email} onChange={setEmail}/>
        </FormField>
        <FormField label="パスワード">
          <PasswordInput value={password} onChange={setPassword}/>
          <div css={{marginTop: 20}}>
            <Link to="/rescue" css={{color: theme.colors.accent, textDecoration: 'underline'}}>パスワードをお忘れの場合</Link>
          </div>
        </FormField>
        <div css={{marginTop: 40}}>
          <Button disabled={!valid} image={theme.colors.accentGradient} fit>ログイン</Button>
        </div>
        {forbidden && <p style={{color: 'red'}}>Eメールか、パスワードに誤りがあります</p>}
      </form>
      <div css={css`display: flex; justify-content: center; margin-top: 40px`}>
        <OutlinePillNavButton to="/signup" color={theme.colors.accent} children="初めての方は新規会員登録"/>
      </div>
    </Container>
  )
};
