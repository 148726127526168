import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Button from '../ui/button';
import Container from '../ui/container';

export default ({title, message}) => {
  return <Container>
    <div css={{textAlign: 'center', paddingTop: 50}}>
      <p css={{fontSize: 24, fontWeight: 'bold', marginBottom: 20}}>{title}</p>
      <p>{message}</p>
      <div css={{marginTop: 20}}>
        <Button children="トップへ戻る" to="/"/>
      </div>
    </div>
  </Container>;
};