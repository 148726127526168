import React from 'react';
import { Helmet } from 'react-helmet';

/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from 'styled-components';
import SectionLabel from '../ui/section-label';
import RichText from '../ui/richtext';
import Container from '../ui/container';
import TalentAvatar from '../ui/talent-avatar';
import api from '../api';
import Button from '../ui/button';
import theme from '../ui/theme';
import BlogBanner from '../ui/blog-banner';
import { Link } from 'react-router-dom';
import {time} from '../fmt';
import LimitedCard from '../ui/limited-card';
import App from '../app';
import FavIcon from '../img/fav.svg';
import FavIconRed from '../img/fav_red.svg';
import CommentIcon from '../img/comment.svg';
import ArrowIcon from '../img/arrow.svg';
import MediaPopup from '../screens/media-popup';


export default ({match}) => {
  const app = React.useContext(App);

  return <Screen key={match.params.media} match={match} user={app.user}/>
}

class Screen extends React.Component {
  constructor() {
    super();
    this.state = {
      faved: false,
      fav_count: 0,
      comments: [],
      open_comment_input: false,
      open_comments: false,
      nickname: "",
      text: "",
    };
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    
    api()(`/talents/${params.artist}/media/${params.media}`)
      .then(rs => {
        this.setState({
          media: rs.data.media,
          fav_count: rs.data.media.fav_count,
          faved: rs.data.media.favorited,
          comments: rs.data.media.comments,
        });
        if ( rs.data.meta.links.prev ) {
          api()(rs.data.meta.links.prev)
            .then(rs => rs.data.media)
            .then(media => this.setState({prev: media}))
        }
        if ( rs.data.meta.links.next ) {
          api()(rs.data.meta.links.next)
            .then(rs => rs.data.media)
            .then(media => this.setState({next: media}))
        }

      });
  }
  
  render() {
    const {match: {params}, user} = this.props;
    const {
      media,
      prev,
      next,
      faved,
      fav_count,
      comments,
      nickname,
      text,
      open_comment_input,
      open_comments,
    } = this.state;

    const doFav = () => {
      if ( faved ) {
        api().delete(`/talents/${params.artist}/media/${params.media}/favorite`, {})
          .then((res) => {
            this.setState({faved: false, fav_count: fav_count - 1})
          })
      } else {
        api().post(`/talents/${params.artist}/media/${params.media}/favorite`, {})
          .then((res) => {
            this.setState({faved: true, fav_count: fav_count + 1})
          })
      }
    }
    const addComment = (nickname, text) => {
      if ( text ) {
        if ( text.length > 300 ) {
          alert('コメントは300文字以内で入力してください');
          return false;
        }
        api().post(`/talents/${params.artist}/media/${params.media}/comments`, {nickname: nickname || '匿名', text})
        .then((res) => {
          this.setState({
            open_comment_input: false,
            open_comments: true,
            nickname: "",
            text: "",
            comments: [{
              id: res.data.id,
              icon: user.avatar,
              nickname: nickname || '匿名',
              text,
              mine: true,
            }, ...comments]
          })
        })
      }
    }
    const doDeleteComment = (id) => {
      if ( window.confirm('コメントを削除してよろしいですか？') ) {
        api().delete(`/comments/${id}`)
        .then((res) => {
          this.setState({
            comments: [...comments.filter(c => c.id !== id)]
          })
        })
      }
    }
    const setValue = (key, e) => {
      this.setState({
        [key]: e.target.value
      })
    }
    const toggleCommentInput = () => {
      this.setState({
        open_comment_input: !open_comment_input
      })
    }
    const toggleComments = () => {
      this.setState({
        open_comments: !open_comments,
      })
    }

    if ( media ) {
      document.title = media.talent.name + 'のメディア | ISM FC';
    }

    return media ? <Media
      setValue={setValue}
      user={user}
      nickname={nickname}
      text={text}
      media={media}
      prev={prev}
      next={next}
      comments={comments}
      faved={faved}
      fav_count={fav_count}
      doFav={doFav}
      addComment={addComment}
      toggleCommentInput={toggleCommentInput}
      toggleComments={toggleComments}
      open_comment_input={open_comment_input}
      open_comments={open_comments}
      doDeleteComment={doDeleteComment}
    /> : <React.Fragment/>;
  }
};

export const Media = ({
  user,
  nickname,
  text,
  media,
  prev,
  next,
  comments,
  faved,
  fav_count,
  doFav,
  addComment,
  setValue,
  toggleCommentInput,
  toggleComments,
  open_comment_input,
  open_comments,
  doDeleteComment
}) => {
  const app = React.useContext(App)

  return <>
  <BlogBanner talent={media.talent}/>
  <Container>
    <div css={{marginBottom: 15}}>
      <Artist artist={media.talent}/>
    </div>
    <div css={css`
      background-color: #E8ECEF;
      padding: 0 0 10px;
      marginTop: 10px;
      border-radius: 5px;
    `}>
      <header css={css`
        padding: 10px;
      `}>
        <div css={css`
          color: #999;
        `}>{time(media.published_at)}</div>
      </header>
      <div>
        <div css={css`
          margin-bottom: 20px;
        `}>
          {media.image ?
          <img css={{display: 'block', width: '100%', height: 'auto', cursor: 'pointer'}} src={media.image} onClick={() => { app.push(<MediaPopup media={{...media, image: media.image}}/>)}} />          
          : <video poster={media.poster} src={media.video} controls playsInline css={{display: 'block', width: '100%', height: 'auto'}} onPlay={() => {GA.push("/media/"+media.id, "play")}}/>

          }
        </div>
        <div css={css`padding: 0 10px`}>
          <div css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 7.7px;
          `}>
            <div css={css`
              display: flex;
              align-items: center;
              gap: 3px;
              font-size: 12px;
              cursor: pointer;
              img {
                width: 18.52px;
                height: 15px;
              }
            `} onClick={doFav}><span>{fav_count}</span><img src={faved ? FavIconRed : FavIcon} /></div>
            <div css={css`
              cursor: pointer;
              height: 16.51px;
              img {
                width: 15.76px;
                height: 100%;
              }
            `} onClick={toggleCommentInput}><img src={CommentIcon} /></div>
          </div>
          <div css={css`
            display: ${open_comment_input ? `block` : `none`};
            ${open_comment_input && `animation: fade-in .5s;`};
            @keyframes fade-in {
              0% {opacity: 0;}
              100% {opacity: 1;}
            }
          `}>
            <div>{comments.length}件のコメント</div>
            <div css={css`
              display: flex;
              flex-direction: column;
              margin-top: 5px;
              gap: 10px;
              input, textarea {
                background-color: transparent;
                border: 1px solid #9A9A9A;
                padding: 10px;
              }
            `}>
              <input type="text" value={nickname} placeholder="ニックネームを入力" onChange={(e) => setValue('nickname', e)}/>
              <textarea placeholder="コメントする" onChange={(e) => setValue('text', e)} value={text} />
              <div css={css`width: 200px; display: flex; justify-content: center; margin: auto`}><Button image={theme.colors.accentGradient} fit onClick={(e) => addComment(nickname, text)}>送信する</Button></div>
            </div>
          </div>


          <div css={css`
          display: ${open_comments ? `block` : `none`};
          ${open_comments && `animation: fade-in .5s;`};
          @keyframes fade-in {
            0% {opacity: 0;}
            100% {opacity: 1;}
          }
        `}>
          <div css={css`
            display: flex;
            flex-direction: column;
            gap: 10px;
          `}>
            {comments.map((c,i) => <div key={i}>
              <div css={css`
                display: flex;
                gap: 8px;
              `}>
                <div css={css`
                  width: 20px;
                  img {
                    width: 100%;
                  }
                `}>
                  <span css={css`
                    overflow: hidden;
                    border-radius: 50%;
                  `
                }><img src={c.icon} /></span>
                </div>
                <div css={css`flex: 1; width: 100%;`}>
                  <div css={css`font-weight: bold; font-size: 9px; margin-bottom: 5px;`}>{c.nickname}</div>
                  <div css={css`font-size: 9px; white-space: pre-line;`}>{c.text}</div>
                  {c.mine && <div css={css`font-size: 9px; margin-top: 5px; text-decoration: underline;`} onClick={() => doDeleteComment(c.id)}>削除</div>}
                </div>
              </div>
            </div>)}
          </div>
        </div>

        </div>

        <div css={css`
          margin: 20px 0 0;
          padding-bottom: 10px;
          font-size: 9px;
          display: ${comments.length > 0 ? `flex` : `none`};
          width: 100%;
          gap: 8px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            ${open_comments && `transform: rotate(180deg);`}
          }
        `} onClick={toggleComments}>
          <img src={ArrowIcon} />
          <span>コメントを{open_comments ? `非表示` : `表示`}</span>
        </div>
      </div>
    </div>
    <div css={css`
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 20px 0 10px;
    `}>
      <Navigation media={next} flg="next"/>
      <Navigation media={prev} flg="prev"/>
    </div>
  </Container>
</>
}

const Navigation = ({media, flg}) => (
  <div css={{
    width: "50%",
    padding: flg == "prev" ? "0 0 0 10px" : "0 10px 0 0",
    textAlign: flg == "prev" ? "right" : "left",
    borderRight: flg == "prev" ? 0 : "1px solid #000"
  }}>
    {
      media ?
        (
          <Link to={`/artists/${media.talent.id}/media/${media.id}`}>
            <p>{flg == 'prev' ? "前のメディア >" : "< 次のメディア"}</p>
          </Link>
        )
        :
        (
          <span>
            <p>{flg == 'prev' ? "前のメディア >" : "< 次のメディア"}</p>
          </span>
        )
    }
  </div>
);

const Artist = ({artist}) => (
  <div css={{display: 'flex', alignItems: 'center'}}>
    <span css={{marginRight: 10, marginTop: -25}}>
      <TalentAvatar radius={50} talent={artist}/>
    </span>
    <span css={{fontSize: 18, fontWeight: 'bold', flex: 'auto'}}>
      {artist.name}
    </span>
    <span css={{flex: 'none'}}>
      <Button padding={{x: 5, y: 0}} borderRadius={12.5} color={theme.colors.accent}>
        <div css={{display: 'flex', alignItems: 'center', height: 25}}>
          <Link to={`/artists/${artist.id}`} css={{fontSize: 12}}>ARTISTトップ</Link>
          <span className="material-icons">keyboard_arrow_right</span>
        </div>
      </Button>
    </span>
  </div>
);