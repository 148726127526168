import moment from 'moment';

export const birthday = d => moment(d).format('YYYY/MM/DD');
export const date = (d, sep='.') => moment(d).format(`YYYY${sep}MM${sep}DD`);
export const time = t => moment(t).format(`YYYY.MM.DD HH:mm`);
export const still = t => moment(t).add(0, 0, -1).format(`YYYY.MM.DD HH:mm`);
export const mdhm = t => moment(t).format(`MM月DD日 HH:mm`);

const _units = ['k', 'm', 'g', 't'];

export const bigInt = n => {
  let shift = 0;
  let r = n;

  for (; Math.floor(n / 1000) > 0; r = n % 1000, n = Math.floor(n / 1000), shift++) continue;

  if (shift == 0) {
    return r.toString();
  } else {
    return `${n}.${Math.floor(r / 100)}${_units[shift - 1]}`;
  }
}