import React from 'react';
import styled, {css} from 'styled-components';

const flexMixin = css`
  ${props => `
    display: flex;
    ${props.onClick ? 'cursor: pointer;' : ''}
    flex-direction: ${props.direction || 'row'};
    justify-content: ${props.justifyContent || 'center'};
    align-items: ${props.alignItems || 'center'};
  `}
`;

const Flex = styled.div`${flexMixin}`;

export const Row = Flex;

export const Column = styled.div.attrs(_ => ({
  direction: 'column'
}))`
  ${flexMixin}
`;

export const Flexible = styled.div.attrs(props => ({
  flex: props.flex || '1'
}))`
  ${props => `
    flex: ${props.flex};
  `}
`;

export const Expanded = Flexible;

const justifyContent = a => {
  switch(a) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return a;
  }
}

const alignItems = a => {
  return 'center';
}

export const Align = styled.div.attrs(props => ({
  justifyContent: justifyContent(props.alignment),
  alignItems: alignItems(props.alignment)
}))`
  ${flexMixin};
`;

export const Center = Align;

export default Flex;