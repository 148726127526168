import ReactGA from 'react-ga';
ReactGA.initialize('UA-178750415-1');

const ga = {
  push(category, action) {
    console.log(category + " " + action + " " + 'push!');
    ReactGA.event({category, action});
  }
};

export default ga;