import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import api from '../api';
import { Switch, Redirect, Route } from 'react-router-dom';
import TalentMedia from './talent-media';
import TalentNews from './talent-news';
import TalentBlog from './talent-blog';
import TabBar from '../ui/tab-bar';
import talentPosterImage from '../ui/talent-poster-image';
import Container from '../ui/container';
import ttl_news_png from '../img/ttl_news.png';
import ttl_news_wh_png from '../img/ttl_news_wh.png';
import ttl_blog_png from '../img/ttl_blog.png';
import ttl_blog_wh_png from '../img/ttl_blog_wh.png';
import ttl_media_png from '../img/ttl_media.png';
import ttl_media_wh_png from '../img/ttl_media_wh.png';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {snapshot: null};
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    api()(`/talents/${params.talent}`)
      .then(rs => rs.data.talent)
      .then(talent => this.setState({snapshot: talent}));
  }

  render() {
    const {history} = this.props;
    const {snapshot} = this.state;
    return snapshot ? <Talent talent={snapshot} history={history}>
      <Switch>
        <Redirect exact path="/artists/:talent/" to="/artists/:talent/news"/>
        <Route exact path="/artists/:talent/media" component={TalentMedia}/>
        <Route exact path="/artists/:talent/news" component={TalentNews}/>
        <Route exact path="/artists/:talent/blog" component={TalentBlog}/>
      </Switch>
    </Talent> : <React.Fragment/>;
  }
};

export const Talent = ({talent, history, children}) => {
  const tabBar = (tab) => (
    <TabBar>
      <TabBar.Tab selected={tab == 'news'} image={ttl_news_wh_png} selectedImage={ttl_news_png} onClick={() => history.push(`/artists/${talent.id}/news`)}/>
      <TabBar.Tab selected={tab == 'blog'} image={ttl_blog_wh_png} selectedImage={ttl_blog_png} onClick={() => history.push(`/artists/${talent.id}/blog`)}/>
      <TabBar.Tab selected={tab == 'media'} image={ttl_media_wh_png} selectedImage={ttl_media_png} onClick={() => history.push(`/artists/${talent.id}/media`)}/>
    </TabBar>
  );

  return (
    <>
      <Container>
        <figure css={css`
          background-image: url(${talentPosterImage(talent.id)});
          background-size: 100% 100%;
          padding-top: 100%;
        `}/>
        <div css={{
          margin: '10px 0 5px'
        }}>
          <Switch>
            <Route exact path="/artists/:talent/blog" render={() => tabBar('blog')}/>
            <Route exact path="/artists/:talent/media" render={() => tabBar('media')}/>
            {tabBar('news')}
          </Switch>
        </div>
      </Container>
      {children}
    </>
  );
}