const pick = async () => {
  return new Promise((resolve, reject) => {
    const $ = document.createElement('input');
    $.type = 'file';
    const css = {visibility: 'hidden', position: 'absolute', left: 0, top: 0, width: 0, height: 0};

    for (const k in css) {
      $.style[k] = css[k];
    }

    $.addEventListener('change', ev => {
      if (ev.target.files.length > 0) {
        const file = ev.target.files[0];
        resolve(file);
      } else {
        resolve(null);
      }      
      document.body.removeChild($);
    });
    document.body.appendChild($);
    $.click();
  });
};

pick.url = async () => {
  const file = await pick();
  if (file == null) return null;

  return new Promise(resolve => {
    const rd = new FileReader();
    rd.addEventListener('load', () => {
      resolve(rd.result);
    });
    rd.readAsDataURL(file);
  });
};

export default pick;