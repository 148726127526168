import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import api from '../api';
import ArticleTile from '../ui/article-tile';
import setting_icon_svg from '../img/setting_icon.svg';

import {ScrollPaginator} from '../ui/paginator';

export default ({match}) => {
  const fetch = next => api()(next || `/user/news`)
    .then(({data: {news, meta: {links: {next}}}}) => [news, next]);

  return <ScrollPaginator fetch={fetch}>
    {news => <MyNews news={news}/>}      
  </ScrollPaginator>;
}

export const MyNews = ({news}) => {
  return (
    <ul>
      {
        news.length > 0 ? news.map(article => <ArticleTile key={article.id} article={article}/>)
        : (
          <p>
            早速お気に入りのアーティストをフォローしよう！<br/>
            マイページ内 <img css={{verticalAlign: '-4px'}} src={setting_icon_svg} width="20"/>＞フォロー変更から設定できます。
          </p>
        )
    }
    </ul>
  );
}