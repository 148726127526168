import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Button from './button';
import theme from '../ui/theme';

const LimitedCard = ({grad}) => <div css={css`
  width: 100%;
  position: relative;

  ${grad && 
    `
    padding-top: 90px;
    margin-top: -60px;
  
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 90px;
      background: linear-gradient(rgba(232, 236, 239, 0), rgba(232, 236, 239, 1) 70%);
  
      position: absolute;
      top: 0;
    }`
  }
`}>
  <div css={css`
    border: 3px solid #aaa;
  `}>
    <div css={css`
      font-size: 16px; font-weight: bold; background: #ccc; padding: 20px;
    `}>この記事は有料記事です</div>
    <div css={css`background: white; padding: 20px;`}>
      <div css={css`margin-bottom: 20px;
      font-size: 14px;`}>
        有料会員登録をすると、<br/>全ての記事・ブログが読み放題！
      </div>
      <ul css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;

        li {
          cursor: pointer;
        }
      `}>
      <li css={css``}>
        <form action="/signup"><Button image={theme.colors.accentGradient} fit>
          会員登録する
        </Button></form>
      </li>
      <li>
      <form action="/login"><Button image={theme.colors.accentGradient} fit>
        ログイン
      </Button></form>
      </li>
        
      </ul>

    </div>
  </div>
</div>;

export default LimitedCard;