import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import { TextArea } from './input';
import { Margin } from './spacing';
import theme from './theme';

const Rich = styled.div`
  strong {
    color: red;
  }

  a {
    color: ${theme.colors.accent};
    text-decoration: underline;
  }
`;

export const RichEdit = ({value, onChange, placeholder, rows}) => {
  const [tab, setTab] = React.useState('edit');

  return (
    <>
      <Margin bottom="5px">
        <div css={{display: 'flex', justifyContent: 'flex-end'}}>
          {tab == 'edit' ? <span onClick={() => setTab('preview')}>preview</span> : <span onClick={() => setTab('edit')}>edit</span>}
        </div>
      </Margin>
      {tab == 'edit' && (
        <>
          <TextArea rows={rows} value={value} placeholder={placeholder} onChange={onChange}/>
          <Deco>
            <Rich>
              <Deco.Item><b>*強調*</b></Deco.Item>
              <Deco.Item><strong>**赤字強調**</strong></Deco.Item>
            </Rich>
          </Deco>
        </>
      )}
      {tab == 'preview' && (
        <Preview>
          <RichText>{value}</RichText>
        </Preview>
      )}
    </>
  );
}

const Preview = styled.div`
  background-color: white;
  height: 200px;
  padding: 12px;
`;

const Deco = styled.div`
  text-align: right;
  font-size: 14px;
`;

Deco.Item = styled.span`
  margin-left: 0.5em;
`;

export const RichText = ({children}) => {
  return <Rich>{nodify(tokenize(children))}</Rich>;
};

const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890/-_?%&=@:~.#!+";

const tokenize = text => {
  const spans = [];
  let cs = '';
  for (let i = 0; i < text.length;) {
    const c = text[i];
    switch(c) {
      case '&':
        if (text.slice(i, i + 8) == '&lowast;') {
          i += 8;
          cs += '*';
        } else {
          cs += '&';
          i++;
        }
        break;
      case '*':
        if (i+1 >= text.length) {
          spans.push({type: 'text', text: cs+'*'});
          i++;
          cs='';
          break;
        }

        const d = text[i+1];

        if (d == '*') {
          if (i+2 >= text.length) {
            spans.push({type: 'text', text: cs+'**'});
            i += 2;
            cs = '';
            break;
          }

          let b = i+2, e = text.length, j = b;
          
          for (; j < text.length; j++) {
            if (text[j] == '*') {
              if (j+1 >= text.length) {
                e = j+1;
                j++;
                break;
              }

              if (text[j+1] == '*') {
                e = j;
                j += 2;
                break;
              }
            }
          }

          spans.push({type: 'text', text: cs});
          spans.push({type: 'strong', text: text.slice(b, e)});
          i = j;
          cs='';
          break;
        }

        let b = i+1, e = text.length, j = b;
        for (; j < text.length; j++) {
          if (text[j] == '*') {
            e = j;
            j++;
            break;
          }
        }

        spans.push({type: 'text', text: cs});
        spans.push({type: 'bold', text: text.slice(b, e)});
        i = j;
        cs='';
        break;
      case 'h':
        if (i+3 >= text.length) {
          cs += text.slice(i);
          i = text.length;
          break;
        }

        if (text.slice(i, i+4) == 'http') {
          let j = i+4;
          for (; j < text.length; j++) {
            if (chars.indexOf(text[j]) == -1) break;
          }
          spans.push({type: 'text', text: cs});
          spans.push({type: 'link', text: text.slice(i, j)});
          i = j;
          cs = '';
          break;
        }

        cs += c;
        i++;
        break;
      default:
        cs += c;
        i++;
    }
  }

  if (cs.length != 0) {
    spans.push({type: 'text', text: cs});
  }
  return spans;
}

const nl2br = t => {
  const lns = t.split('\n');
  const nodes = [];
  for (var i = 0, k=1; i < lns.length; i++, k+=2) {
    nodes.push(<React.Fragment key={k}>{lns[i]}</React.Fragment>);
    nodes.push(<br key={k+1}/>);
  }
  nodes.pop();
  return nodes;
}

const nodify = spans => {
  const nodes = [];

  for (let i = 0; i < spans.length; i++) {
    const span = spans[i];

    switch(span.type) {
      case 'text':
        nodes.push(
          <React.Fragment key={i}>
            {nl2br(span.text)}
          </React.Fragment>
        );
        break;
      case 'bold':
          nodes.push(
            <b key={i}>
              {nl2br(span.text)}
            </b>
          );
          break;
      case 'strong':
        nodes.push(
          <strong key={i}>
            {nl2br(span.text)}
          </strong>
        );
        break;
      case 'link':
        nodes.push(
          <a key={i} href={span.text} target="_blank">{span.text}</a>
        );
    }
  }

  return nodes;
};

export default Rich;