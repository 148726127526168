import moment from 'moment';
import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import api from '../api';
import ActivityCard from '../ui/activity-card';
import {ScrollPaginator} from '../ui/paginator';
import App from '../app';
import setting_icon_svg from '../img/setting_icon.svg';
import Prizes from '../ui/prizes';
import SpecialMovies from '../ui/special_movies';

export default ({match}) => {
  const app = React.useContext(App);

  const fetch = next => api()(next || `/timeline`)
    .then(({data: {activities, meta: {links: {next}}}}) => [activities, next]);

  return (
    <ScrollPaginator fetch={fetch}>
      {timeline => <MyTimeline user={app.user} timeline={timeline}/>}
    </ScrollPaginator>
  )
}

export const MyTimeline = ({user, timeline}) => {
  return (
    <>
      <Prizes user={user}/>
      <SpecialMovies/>
      {
        timeline.length > 0 ? timeline.map(activity => activity.talent && <ActivityCard key={activity.id} activity={activity}/>)
        : (
          <p css={{marginTop: 40}}>
            早速お気に入りのアーティストをフォローしよう！<br/>
            フォローしたアーティストの情報がマイページに表示されます。<br/>
            マイページ内 <img css={{verticalAlign: '-4px'}} src={setting_icon_svg} width="20"/>＞フォロー変更からいつでも設定、変更できます。
          </p>
        )
      }
    </>
  );
}
