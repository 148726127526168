import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from 'styled-components';
import SectionLabel from '../ui/section-label';
import {RichText} from '../ui/rich';
import Container from '../ui/container';
import TalentAvatar from '../ui/talent-avatar';
import api from '../api';
import APIError from './api-error';
import theme from '../ui/theme';
import {time} from '../fmt';
import action_gobottom_png from '../img/action_gobottom.png';
import LimitedCard from '../ui/limited-card';
import App from '../app';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      article: null
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    api()(`/news/${params.article}`)
      .then(rs => rs.data.article)
      .then(article => this.setState({article}))
      .catch(error => this.setState({error}));
  }
  scrollBottom() {
    this.formRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }
  
  render() {
    const {article, error} = this.state;
    // if ( article ) {
    //   document.title = article.title + ' | ISM FC';
    //   const headData = document.head.children;  
    //   for (let i = 0; i < headData.length; i++) {
    //     const nameVal = headData[i].getAttribute('property');
    //     if (nameVal !== null) {
    //       if (nameVal.indexOf('og:image') != -1 && article.og_image) {
    //           headData[i].setAttribute('content', article.og_image);
    //       }
    //       if (nameVal.indexOf('og:url') != -1) {
    //           headData[i].setAttribute('content', 'https://ism-fc.com/news/' + article.id);
    //       }
    //     }
    //   }
    // }

    return article ? <Article article={article} scrollBottom={() => this.scrollBottom()} formRef={this.formRef}/> :
      error ? <APIError error={error}/> : <React.Fragment/>;
  }
};

export const Article = ({article, scrollBottom, formRef}) => {
  const app = React.useContext(App)

  return <Container css={{paddingTop: 45}}>
  <SectionLabel label={article.topic.talent ? "NEWS" : "事務局からのお知らせ"}/>
  <Topic topic={article.topic} scrollBottom={scrollBottom}/>
  <div css={css`
    background-color: #E8ECEF;
    padding: 10px;
    border-radius: 5px;
  `}>
    <header css={css`
      padding: 10px 0;
      border-bottom: 1px solid #CCC;
    `}>
      <h1 css={css`
        font-size: 18px;
      `}>{article.title}</h1>
      <div css={css`
        color: #999;
        margin-top: 20px;
      `}>{time(article.published_at)}</div>
    </header>
    <div css={css`
      padding: 10px 0;
    `}>
      {article.image && 
        <div css={css`
          margin: 0 0 10px;
          img {
            width: 100%;
          }
        `}><img src={article.image}/></div>
      }
      <RichText>{article.text}</RichText>
      {!app.signed && article.limited && <LimitedCard grad={article.text.length > 0} />}
    </div>
  </div>
  {app.signed && <div ref={formRef} css={{marginTop: -65, paddingTop: 65}}>
    {article.ticket && article.ticket.split('---').map((val, i) => (
      <div key={i} css={css`
        padding: 10px 0;
        margin-top: 30px;
        text-align: left;
        border: 1px solid rgb(204, 204, 204);
        padding: 15px;

        input {
          display: block;
          padding: 8px 32px;
          font-size: inherit;
          border-radius: 8px;
          text-align: center;
          border: none;
          background: ${theme.colors.accent};
          color: white;
          appearance: none;
          width: 100%;
          margin-top: 15px;
        }
      `} dangerouslySetInnerHTML={{__html: val}}>
      </div>
    ))}
  </div>}
</Container>
}

const Topic = ({topic, scrollBottom}) => (
  topic.talent ? (
    <div css={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20
    }}>
      <span css={{marginRight: 10}}>
      <TalentAvatar talent={topic.talent}/>
      </span>
      <div css={{flex: 'auto'}}>{topic.talent.name}</div>
      <div><img src={action_gobottom_png} css={{width: 120, cursor: 'pointer'}} onClick={() => scrollBottom()}/></div>
    </div>
  ) : <React.Fragment/>
);