import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect, withRouter} from 'react-router-dom';
import {render} from 'react-dom';
import Style from './style';
import App, {AppProvider} from './app';
import Signup from './screens/signup';
import SignupVerify from './screens/signup-verify';
import SignupSent from './screens/signup-sent';
import SignupComplete from './screens/signup-complete';
import Subscribe from './screens/subscribe';
import Home from './screens/home';
import Guide from './screens/guide';
import MyPage from './screens/mypage';
import Login from './screens/login';
import Rescue from './screens/rescue';
import RescueVerify from './screens/rescue-verify';
import RescueReset from './screens/rescue-reset';
import StaffsNews from './screens/staffs-news';
import TalentsNews from './screens/talents-news';
import Activities from './screens/activities';
import Article from './screens/article';
import Settings from './screens/settings';
import Card from './screens/card';
import Profile from './screens/profile';
import Email from './screens/email';
import EmailVerify from './screens/email-verify';
import Password from './screens/password';
import Talent from './screens/talent';
import Follows from './screens/follows';
import Contact from './screens/contact';
import FAQ from './screens/faq';
import Question from './screens/question';
import PrivacyPolicy from './screens/privacy-policy';
import Agreement from './screens/agreement';
import TradeLaw from './screens/tradelaw';
import Post from './screens/post';
import Magazine from './screens/magazine';
import Mile from './screens/mile';
import Media from './screens/media';

const Guard = ({children}) => {
  const app = React.useContext(App);
  return app.signed ? children : <Redirect to="/login"/>
};

const Mux = () => {
  return <>
  <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/guide" component={Guide}/>
    <Route exact path="/staffs/news/" component={StaffsNews}/>
    <Route exact path="/talents/news/" component={TalentsNews}/>
    <Route exact path="/news/:article" component={Article}/>
    <Route exact path="/activities/" component={Activities}/>
    <Route exact path="/login" component={Login}/>
    <Route exact path="/rescue" component={Rescue}/>
    <Route exact path="/rescue/reset" component={RescueReset}/>
    <Route exact path="/signup" component={Signup}/>
    <Route exact path="/signup/sent" component={SignupSent}/>
    <Route exact path="/signup/complete" component={SignupComplete}/>
    <Route exact path="/subscribe" component={Subscribe}/>
    <Route exact path="/contact" component={Contact}/>
    <Route exact path="/faq/" component={FAQ}/>
    <Route exact path="/faq/:question" component={Question}/>
    <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
    <Route exact path="/agreement" component={Agreement}/>
    <Route exact path="/tradelaw" component={TradeLaw}/>
    <Route exact path="/verify/signup" component={SignupVerify}/>
    <Route exact path="/verify/email" component={EmailVerify}/>
    <Route exact path="/verify/rescue" component={RescueVerify}/>
    <Route exact path="/mile" component={Mile}/>
    <Route exact path="/artists/:artist/blog/:post" component={Post}/>
    <Route exact path="/artists/:talent/" component={Talent}/>
    <Route exact path="/artists/:talent/news" component={Talent}/>
    <Route exact path="/artists/:talent/blog" component={Talent}/>
    <Guard>
      <Switch>
        <Route exact path="/artists/" component={Follows}/>
        <Route exact path="/artists/:talent/media" component={Talent}/>
        <Route path="/mypage" component={MyPage}/>
        <Route exact path="/settings/" component={Settings}/>
        <Route exact path="/settings/card" component={Card}/>
        <Route exact path="/settings/profile" component={Profile}/>
        <Route exact path="/settings/email" component={Email}/>
        <Route exact path="/settings/password" component={Password}/>
        <Route exact path="/settings/magazine" component={Magazine}/>
        <Route exact path="/artists/:artist/media/:media" component={Media}/>
      </Switch>
    </Guard>
  </Switch>
</>
}

const RouteHook = withRouter(
  ({location, children}) => {
    const app = React.useContext(App);
    const [current, change] = React.useState(location);

    React.useEffect(() => {
      if (current.pathname != location.pathname) {
        window.scrollTo({top: 0});
        change(location);
      }
    })
    
    return children;
  }
)

class Catch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>エラーが発生しました</h1>;
    }

    return this.props.children; 
  }
}

const Root = () => (
  <Router>
    <Style/>
    <AppProvider>
      <Catch>
        <RouteHook>
          <Mux/>
        </RouteHook>
      </Catch>
    </AppProvider>
  </Router>
);

render(<Root/>, document.getElementById('mount'));
