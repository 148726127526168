import React from 'react';
import styled, {css} from 'styled-components';

const withDisplay = css`
  ${({display}) => display ? `display: ${display};` : ''}
`;

export const Margin = styled.div`
  ${withDisplay}

  ${({all, left, top, right, bottom, y, x}) => `
    ${all ? `margin: ${all};` : ''}
    ${x ? `margin: 0 ${x};` : ''}
    ${y ? `margin: ${y} 0;` : ''}
    ${top ? `margin-top: ${top};` : ''}
    ${right ? `margin-right: ${right};` : ''}
    ${bottom ? `margin-bottom: ${bottom};` : ''}
    ${left ? `margin-left: ${left};` : ''}
  `}
`;

export const Padding = styled.div`
  ${withDisplay}
  ${({all, left, top, right, bottom, y, x}) => `
    ${all ? `padding: ${all};` : ''}
    ${x ? `padding: 0 ${x};` : ''}
    ${y ? `padding: ${y} 0;` : ''}
    ${top ? `padding-top: ${top};` : ''}
    ${right ? `padding-right: ${right};` : ''}
    ${bottom ? `padding-bottom: ${bottom};` : ''}
    ${left ? `padding-left: ${left};` : ''}
  `}
`;

export const withSpacing = css`
  ${({margin, marginLeft, marginTop, marginRight, marginBottom, marginY, marginX}) => `
    ${margin ? `margin: ${margin};` : ''}
    ${marginX ? `margin: 0 ${marginX};` : ''}
    ${marginY ? `margin: ${marginY} 0;` : ''}
    ${marginTop ? `margin-top: ${marginTop};` : ''}
    ${marginRight ? `margin-right: ${marginRight};` : ''}
    ${marginBottom ? `margin-bottom: ${marginBottom};` : ''}
    ${marginLeft ? `margin-left: ${marginLeft};` : ''}
  `}

  ${({padding, paddingLeft, paddingTop, paddingRight, paddingBottom, paddingY, paddingX}) => `
    ${padding ? `padding: ${padding};` : ''}
    ${paddingX ? `padding: 0 ${paddingX};` : ''}
    ${paddingY ? `padding: ${paddingY} 0;` : ''}
    ${paddingTop ? `padding-top: ${paddingTop};` : ''}
    ${paddingRight ? `padding-right: ${paddingRight};` : ''}
    ${paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
    ${paddingLeft ? `padding-left: ${paddingLeft};` : ''}
  `}
`;

export default styled.div`
  ${withDisplay}
  ${withSpacing}
`;