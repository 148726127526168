import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {Link} from 'react-router-dom';
import menu_logo_svg from '../img/menu_logo.svg';
import Button from './button';
import theme from './theme';
import App from '../app';

export default () => {
  const app = React.useContext(App);

  return (
    <div css={css`
      background-color: white;
      position: absolute;
      width: 300px;
      height: 100%;
      right: 0;
      top: 0;
    `}>
      <div css={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}}>
        <Link to="/"><img src={menu_logo_svg} alt=""/></Link>
        <div css={{width: '100%', marginTop: 30}}>
          {app.signed ? <Signed app={app}/> : <Unsigned/>}
          <div css={{padding: 20, marginTop: 20, fontSize: 12, textAlign: 'center'}}>
            <Link to="/faq">よくあるご質問(お問い合わせ)</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Signed = ({app}) => (
  <>
    <ul css={{borderTop: '1px solid #CCC'}}>
      <Item to="/mypage">マイページ</Item>
      <Item to="/artists/">アーティスト一覧</Item>
      <Item to="/settings">アカウント設定変更</Item>
      <Item to="/guide">会員特典・ご案内</Item>
      <Item to="/mile">ISMマイル</Item>
    </ul>
    <div css={{padding: '0 20px', marginTop: 40}}>
      <Button image={theme.colors.accentGradient} fit onClick={() => {
        app.signout()
        location.href = '/login'
      }}>ログアウト</Button>
    </div>
  </>
)

const Unsigned = () => (
  <>
    <ul css={{width: '100%', borderTop: '1px solid #CCC'}}>
      <Item to="/login">ログイン</Item>
      <Item to="/signup">新規会員登録</Item>
      <Item to="/guide">会員特典・ご案内</Item>
    </ul>
  </>
)

const Item = ({children, to}) => (
  <li>
    <Link to={to} css={{display: 'block', borderBottom: '1px solid #CCC', padding: 20, textAlign: 'center'}}>
      {children}
    </Link>
  </li>
);