import moment from 'moment';
import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import api from '../api';
import ActivityCard from './activity-card';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {special_movies: null};
  }

  componentDidMount() {
    api()('/special_movies')
      .then(rs => rs.data.special_movies)
      .then(special_movies => this.setState({special_movies}));
  }

  render() {
    const {special_movies} = this.state;
    return special_movies ? special_movies.map((s) => {
      const d = {
        media: {
          ...s,
          is_special: true
        },
        published_at: s.created_at
      }
      return <ActivityCard key={s.id} activity={d}/>
    }) : <React.Fragment/>;
  }
};
