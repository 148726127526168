import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {Link} from 'react-router-dom';
import {date} from '../fmt';

export default ({article}) => (
  <li css={css`
    margin-top: 4px;
  `}>
    <Link to={`/news/${article.id}`} css={css`display: block; padding: 10px;`}>
      <div css={{
        display: 'flex',
        alignItems: "flex-start"
      }}>
        <div css={{flex: 'none', marginRight: 10}}>
          <div css={{fontSize: '12px'}}>{date(article.published_at)}</div>
          {article.topic.talent &&
            <div css={{fontWeight: 'bold', paddingTop: '10px'}}>{article.topic.talent.name}</div>
          }
        </div>
        <div css={{flex: 'auto', textDecoration: 'underline'}}>
          {article.title}
        </div>
      </div>
    </Link>
  </li>
);