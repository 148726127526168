import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {Link as RouterLink} from 'react-router-dom';
import theme from './ui/theme';
import setting_icon_svg from './img/setting_icon.svg';
import tokuten_graph_png from './img/tokuten_graph.png';
import tokuten_graph_year_png from './img/tokuten_graph_year.png';

const Link = ({to, href, children}) => to ? <RouterLink css={{
  color: theme.colors.accent,
  textDecoration: 'underline'
}} to={to}>{children}</RouterLink> : <a href={href} target="_blank" css={{
  color: theme.colors.accent,
  textDecoration: 'underline'
}}>{children}</a>;

const SettingIcon = () => <img css={{verticalAlign: 'middle'}} src={setting_icon_svg} width="20"/>;

const all = [
  {
    id: 'q1',
    question: '入会方法を教えてください',
    answer: <>
      <Link to="/">ISM FCトップページ</Link>の新規会員登録ボタンよりお申し込みください。<br/>
      詳しいご登録方法、会員特典、会費と月額費と有効期限については<Link to="/guide">ご案内</Link>をご確認ください。
    </>
  },
  {
    id: 'q2',
    question: '退会はどこからすればよいですか',
    answer: <>
      マイページ内、<SettingIcon/> ＞ユーザー設定　の退会手続からお手続きください。
    </>
  },
  {
    id: 'q3',
    question: '登録情報の確認、変更をしたいです',
    answer: <>
      マイページ内、<SettingIcon/> ＞ユーザー設定　からご確認、変更を行えます。
    </>
  },
  {
    id: 'q4',
    question: '新規会員の確認メールが届きません',
    answer: <>
      メールはご登録のメールアドレスへ自動配信しておりますが、電子メールの特性上、到着が遅れたり、未着となる場合がございます。<br/>
      以下の可能性がございますので、ご確認をお願い致します。<br/>
      <br/>
      ・メール受信拒否の設定がされている（@ism-fc.comからのメールを受信できるよう設定確認をお願い致します）<br/>
      ・迷惑メールフォルダや「ゴミ箱」に振り分けられている<br/>
      ・メールボックスまたはサーバーの容量が制限を超えている<br/>
      ・プロバイダーの通信状態によって、メールの受信が遅れている<br/>
      ・登録したメールアドレスに誤りがある<br/>
      <br/>
      メールアドレス変更はマイページ内、<SettingIcon/> ＞ユーザー設定　のメールアドレス変更から変更できます。
    </>
  },
  {
    id: 'q5',
    question: 'パスワードを忘れてログインできません',
    answer: <>
      <Link to="/rescue">パスワードの再設定</Link>より再設定をおこなうことができます。
    </>
  },
  {
    id: 'q6',
    question: '機種変更する場合、何か手続きは必要ですか',
    answer: <>
      特にお手続きの必要はございません。機種変更後も変更前のメールアドレスとパスワードでログインいただけます。<br/>
      なお、メールアドレスの変更が発生する場合にはマイページ内、<SettingIcon/> ＞メールアドレス　の変更をお願い致します。
    </>
  },
  {
    id: 'q7',
    question: '会期はいつからいつまでですか',
    answer: <>
      本サービスはご入金された日が「入会日」となり、「入会日」から月額会員は1ヶ月間、年額会員は1年間が会員有効期間となり、以後自動更新となります。<br/>
      <div css={css`margin-top: 50px;`}>
        <p css={css`font-size: 20px; font-weight: bold; margin-bottom: 20px; color: #C9171E`}>月額会員の有効期限と更新日</p>
        <img src={tokuten_graph_png} alt="" css={{width: '100%', height: 'auto'}}/>
      </div>
      <div css={css`margin-top: 50px;`}>
      <p css={css`font-size: 20px; font-weight: bold; margin-bottom: 20px; color: #C9171E`}>年額会員の有効期限と更新日</p>
        <img src={tokuten_graph_year_png} alt="" css={{width: '100%', height: 'auto'}}/>
      </div>
      <br/>      
      *会員が契約期間途中で退会を希望する場合には、退会の手続きをしてください。退会した場合、既に支払われた会費等の払い戻し等は一切できかねます。ご了承ください。
    </>
  },
  {
    id: 'q8',
    question: '会員継続のための更新手続きは必要ですか',
    answer: <>
      ISM FCは月額会員・年額会員共に自動更新となっております。そのため更新の手続きは必要ございません。<br/>
      退会をご希望される場合はマイページ内、<SettingIcon/> ＞ユーザー設定　の退会手続からお手続きください。
    </>
  },
  {
    id: 'q9',
    question: 'いつまでに退会すれば次の更新がかからずに済みますか',
    answer: <>
      会期有効期限日までに退会手続きをお済ませください。<br/>
      例）<br />
      ・月額会員で4月1日に会員登録された場合は4月30日23:59までに退会手続を完了させてください。<br />
      ・年額会員で4月1日に会員登録された場合は翌年3月31日23:59までに退会手続を完了させてください。
    </>
  },
  {
    id: 'q28',
    question: '月額会員から年額会員への切替え方法を教えてください',
    answer: <>
      月額会員を１度退会し、再度新規会員登録ボタンより年額会員を選択しご入会ください。<br />
      1ヶ月以内に同じメールアドレスで新規会員登録した場合（復帰した場合）、継続扱いとなり初回登録時の会員番号、継続日数、ISMマイル等の情報が引き継がれます。<br />
      ただし異なったメールアドレスを使用したり、退会から1ヶ月以上過ぎてしまいますと、会員データ失効となりますのでご注意ください。<br />
      ※再登録の際、以前のメールアドレスと違う場合は継続扱いになりませんのでお気を付けください。<br />
      ※日割り計算などはされませんので、月額会員から年額会員への切替タイミングにはご注意ください。
    </>
  },
  {
    id: 'q10',
    question: '会費の支払い方法はクレジットカードのみですか',
    answer: <>
      クレジットカードのみのお取り扱いとなっております。ご了承ください。<br/>
      ご利用可能カードは、Visa、Mastercard、American Express、JCB、Diners Club、Discoverのクレジットカードとなっております。<br/>
      ※デビットカード、その他カードでのご利用可否は恐れ入りますが、カード発行元会社までお問い合わせいただきますようお願い申し上げます。
    </>
  },
  {
    id: 'q11',
    question: '登録クレジットカードの変更はどこからできますか',
    answer: <>
      マイページ内、<SettingIcon/> ＞ユーザー設定　のお支払い情報変更から変更できます。
    </>
  },
  {
    id: 'q12',
    question: 'クレジットカードの決済に失敗してしまいます',
    answer: <>
      決済に失敗する場合、しばらく時間を置いてから再度決済されると改善される場合がございます。誠に恐れ入りますがしばらく時間を置いてから再度決済をお試しください。<br/>
      なお、ご利用可能なクレジットカードに関しましては、<Link to="/faq/q10">こちら</Link>をご確認ください。
    </>
  },
  {
    id: 'q13',
    question: '会員更新の決済に失敗してしまいました',
    answer: <>
      決済の失敗などの理由により継続手続きが完了できなかった場合、<Link to="/signup">新規会員登録画面</Link>から再度ご登録手続きをしていただく必要がございます。<br/>
      決済失敗から1ヶ月以内に初回登録時と同メールアドレスを使用して再度ご登録いただくと、初回登録時の会員番号、継続日数等の情報が引き継がれます。<br/>
      ただし異なったメールアドレスを使用したり、決済失敗から1ヶ月以上過ぎてしまいますと、会員データ失効となりますのでご注意ください。<br/>
      *決済が完了しない間はコンテンツの観覧はできません。
    </>
  },
  {
    id: 'q14',
    question: '会員証は届きますか',
    answer: <>
      会員証はございません。マイページに表示される会員番号のみの発行となります。
    </>
  },
  {
    id: 'q15',
    question: '一度退会した後、1ヶ月以内にまた入会したのですが、会員継続扱いになりますか',
    answer: <>
      1ヶ月以内に同じメールアドレスで新規会員登録した場合（復帰した場合）、継続扱いになります。
    </>
  },
  {
    id: 'q16',
    question: '会員限定チケットや先行チケットに申し込みたいのですが、入会後いつから申し込み可能ですか',
    answer: <>
      ご入会をご検討いただきありがとうございます。<br/>
      チケット申し込みなどの各種ファンクラブ会員特典につきましては、<br/>
      新規ご入会のうえ会費お支払いが完了した時点で即時ご利用いただけます。
    </>
  },
  {
    id: 'q17',
    question: 'チケット先行について、追加先行はありますか',
    answer: <>
      チケット先行等の追加案内につきましては、サイトにて告知させていただきますので、お問い合わせいただきましてもご回答することはできかねます。予めご了承くださいませ。
    </>
  },
  {
    id: 'q18',
    question: 'イベントの申し込み状況を確認したいです',
    answer: <>
      チケットをお申し込みいただいたプレイガイドへお問い合わせください。
    </>
  },
  {
    id: 'q19',
    question: '申し込んだチケットの当落メールが届きません',
    answer: <>
      チケットをお申し込みいただいたプレイガイドへお問い合わせください。
    </>
  },
  {
    id: 'q25',
    question: '誕生日特典について',
    answer: <>
      誕生日特典はフォローしているアーティストからメッセージが届きます。<br/>
      なお、表示期間は登録されたお誕生日当日から一週間となっており、マイページトップよりご確認いただけます。
    </>
  },
  {
    id: 'q20',
    question: '動画コンテンツが見れません',
    answer: <>
      当サイトの推奨環境は下記です。<br/>
      <br/>
      Google Chrome, Safari, Mirosoft Edge, Firefox, iOS, Android の最新のリリース
      <br/>
      <br/>
      推奨環境の条件が満たされていない場合は、恐れ入りますが、OSやブラウザをアップデートいただいた後に改めて再生をお試しください。<br/>
      推奨環境の条件を満たした上で、動画が再生されない場合は、お使いのブラウザの設定画面より、キャッシュのクリアを実施していただくことで改善することがございます。キャッシュのクリアをお試しいただいても解決されない際は、端末を再起動していただき、再度再生をお試しください。<br/>
      <br/>
      それでも解決されない場合は原因をお調べいたしますので、<br/>
      <br/>
      お客様の、<br/>
      ・端末機種名<br/>
      ・OSバージョン<br/>
      ・ブラウザアプリ名<br/>
      <br/>
      をご記載の上、<Link to="/contact">お問い合わせフォーム</Link>からご連絡いただけますようお願いいたします。
    </>
  },
  {
    id: 'q21',
    question: 'ファンレター、プレゼントの送付先を教えてください',
    answer: <>
      下記、ISM ENTERTAINMENTまでお送りください。<br/>
      ＿＿＿＿＿＿＿＿<br/>
      106-0032<br/>
      東京都港区六本木3-9-11<br/>
      株式会社ISM ENTERTAINMENT<br/>
      ○○ ○○ 宛<br/>
      ＿＿＿＿＿＿＿＿<br/>
      *衛生管理上、飲食物、植物、クール便等の送付はお控えください<br/>
      *時節柄、弊社でもリモート作業を推奨しており、郵送等の対応をできる者が不在の場合が多く、荷物を受け取れない場合がございます。置き配可能な配達の利用など、ご理解ご協力をお願い申し上げます。
    </>
  },
  {
    id: 'q22',
    question: 'FCイベントにプレゼントボックスはありますか',
    answer: <>
      ファンレター・プレゼントをお預かりするプレゼントBOXの設置については、事前にご案内いたしかねますので、各会場にてご確認いただきますようお願い申し上げます。
    </>
  },
  {
    id: 'q23',
    question: '出演のお問い合わせ',
    answer: <>
      出演依頼につきましては、<Link href="https://ism-ent.com">株式会社ISM ENTERTAINMENT</Link>コンタクトフォームよりお問い合わせください。
    </>
  },
  {
    id: 'q24',
    question: 'ISM FCに関するご意見・ご要望',
    answer: <>
      <Link to="/contact">お問合せページ</Link>よりご連絡ください。順次、担当よりご返信致します。
      お問合せ内容によっては、ご回答できかねる場合もございます。予めご了承ください。
    </>
  },
  {
    id: 'q26',
    question: '入会・継続特典について',
    answer: <>
      入会初日、3・6・12ヵ月でもらえる入会・継続特典はISM ENTERTAINMENT所属アーティストの待ち受け画像をプレゼントさせて頂いております。こちらは該当日から一週間限定でマイページのタイムライン上に表示されます。
    </>
  },
  {
    id: 'q27',
    question: 'メールマガジンが届きません',
    answer: <>
      マイページ内、<SettingIcon/>＞ユーザー設定＞メールマガジンより設定を行ってください。<br/>
      メールマガジンは毎週月曜日 午前11時にフォローしているアーティストのアクティビティとニュース更新をご登録のアドレスへ配信いたします。<br/>
      更新がなかった場合、メールは配信されません。<br/>
      こちらの配信設定はマイページ＞<SettingIcon/>＞メールマガジンの設定よりいつでもご変更いただけます。<br/>
      ※期間が限られているチケット先行などは、更新情報と入れ違いに終了する場合もございますのでご注意ください。 
    </>
  }
];

const get = id => all.filter(q => q.id == id)[0];

const sections = [
  {
    id: 'qs1',
    label: '会員登録 /変更、退会、ログインについて',
    questions: ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q27'].map(id => get(id))
  },
  {
    id: 'qs2',
    label: '会期と会費の支払い方法について',
    questions: ['q7', 'q8', 'q9', 'q28', 'q10', 'q11', 'q12', 'q13'].map(id => get(id))
  },
  {
    id: 'qs3',
    label: 'FC特典・チケットについて',
    questions: ['q14', 'q15', 'q16', 'q17', 'q18', 'q19', 'q20', 'q25', 'q26'].map(id => get(id))
  },
  {
    id: 'qs4',
    label: 'その他お問い合わせ',
    questions: ['q21', 'q22', 'q23', 'q24'].map(id => get(id))
  }
];

export default {
  all,
  sections,
  get
};