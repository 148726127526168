import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import RichText from '../ui/richtext';
import Container from '../ui/container';

const txt = `
会員規約・チケット規約

会員規約

第1条（会員規約の適用範囲）
本規約は、株式会社ISM ENTERTAINMENTが、管理・運営するISM ENTERTAINMENTオフィシャルファンクラブである「ISM FC」（以下「当ファンクラブ」という）に関して、ファンクラブ会員（以下「会員」という）に適用されるものとします。
２　当ファンクラブは、ISM ENTERTAINMENT所属アーティスト（以下「アーティスト」という）を応援する会員によって構成され、所属アーティストを応援することを目的としています。この趣旨に賛同された方々が、本規約の定めるところにより会員資格を得て、会員として第3条の定める会員特典を受けることができます。

第2条（運営主体）
当ファンクラブは、ISM ENTERTAINMENTが管理・運営しております。

第3条（会員特典）
⑴　会員番号の発行
⑵　入会・継続特典
⑶　誕生日特典
⑷　会員限定ページの閲覧（所属アーティストによるブログ・写真・動画）
⑸　チケット先行抽選予約
⑹　ファンクラブイベント
⑺　その他、当ファンクラブが定める特典

第4条（会員規約の範囲）
本規約は、当ファンクラブが提供する会員特典およびサービスを利用できる会員に対して適用され、入会手続きが完了し、当ファンクラブの会員になった時点から、本規約の効力が及びます。
 
第5条（会員規約の変更）
当ファンクラブは、会員の了承を得ることなく本規約を随時変更・追加・削除等することができ、会員は予めこれを承諾するものとします。この場合、利用条件は変更後の会員規約によるものとします。
２　変更後の会員規約は、当ファンクラブが管理・運営するサイト（https://ism-fc.com）への掲載やメール配信など、その他当ファンクラブが適当と認める方法により通知します。
３　本規約の変更はサイトに掲載された時点から有効となりますので、定期的にご確認ください。
４　本規約の変更の効力が生じた後に会員が当ファンクラブへの入会を継続する場合、変更後の本規約のすべての内容に同意したものとみなします。
 
第6条（当ファンクラブからの通知と同意）
当ファンクラブが管理・運営するサイトへの掲載またはメール配信、その他当ファンクラブが適当と認める方法により、会員に対し随時必要な情報を通知します。
２　前項の通知は、当ファンクラブが管理・運営するサイトに掲載した時点、またはメール配信した時点をもって、通知が完了したものとみなします。
３　会員は、当ファンクラブからの通知を遅滞なく閲覧・熟読して、確認するものとします。
４　当ファンクラブからのメール配信は、会員が登録又は変更をしたメールアドレスに配信し、未登録又は誤登録による不達、プロバイダ（携帯電話会社を含む)都合によるメール不達などについて、当ファンクラブは一切の責めを負いません。
 

第7条（月会費・会員資格有効期間）
本契約は月額会員は1ヶ月間、年額会員は1年間を契約期間とする契約とし、会員は入会時に、月会費800円（税別）または年会費9,000円（税別）を当ファンクラブに支払うものとします。（前払いして頂きます。)
２　ご入金された日が「入会日」となり、「入会日」から月額会員は1ヶ月間、年額会員は1年間が会員有効期間となり、以後自動更新となります。自動更新継続を希望しない方は所定の方法にて退会の手続きを行ってください。
３　会員が決済の失敗などの理由により継続手続きを完了しなかった場合は、本契約は契約期間満了となり自動退会となります。
４　有効期限を過ぎ退会処理になった場合は、継続をご希望される場合は、再度新規入会手続きを行い、新しい会員番号が付与されます。この場合、期限が切れてから新規入会日までの期間に会員に発送された郵便物やメールサービスの再送は行いません。
５　会員が契約期間途中で退会を希望する場合には、所定の方法にて退会手続きを行うものとします。退会した場合、すでに支払われた会費等の払い戻し等は一切行わないものとします。
 
第8条（会員情報の登録と変更）
当ファンクラブは、必要な審査・手続き等を経て入会を承認します。
２　新規入会・変更手続きの際に架空名義や重複・虚偽の登録をしてはなりません。
３　会員は、会員登録した内容に変更があった場合には、速やかに所定の方法で、登録内容の変更を当ファンクラブに届け出なければなりません。
４　申請が遅れたことによる郵便物等の不達、特典等のサービスが受けられないことについて、当ファンクラブは一切の責めを負いません。
 
第9条（会員情報の登録と変更）
当ファンクラブは、入会希望者が次のいずれかに該当すると判断した場合、入会を承認しないことができるものとします。
(1)　入会希望者が、既に会員になっている場合
(2)　入会希望者が、過去に会員規約違反等により会員資格を取消されている場合
(3)　登録内容に虚偽の記載・誤記または記入漏れがあった場合
(4)　入会希望者が法人の場合
(5)　同一人物で多数の申し込みがあった場合
(6)　同一人物で、名義の異なる多数の申し込みがあった場合
(7)　入会希望者の情報が法人の営業所である場合
(8)　入会希望者が実在しない場合、あるいは実在が疑われる場合
(9)　入会希望者が、暴力団、暴力団関係企業・団体その他の反社会的組織に所属している場合、または暴力団員またはその他の反社会的勢力と密接な関係を有している場合
(10)　当ファンクラブより除名処分を過去に受けていた場合
(11)　その他、当ファンクラブが会員とすることを不適当と判断する場合
２　当ファンクラブは、入会を承認した後であっても、会員が前項のいずれか、また次項のいずれかに該当することが判明した場合には、事前に通知することなく直ちに会員資格を中断、停止または除名することができるものとします。
(1)　自ら退会を希望する場合
(2)　会費の未納の場合
(3)　当ファンクラブを通じて入手した全てのデータ（情報、文章、音、映像、画像、イラスト等）を、著作権法で認められた私的利用の範囲を超えて、複製、販売、出版、放送可能化等のために利用した場合
(4)　当ファンクラブを利用して、自己または第三者の営利を目的とした活動およびその準備を目的とした活動を行った場合
(5)　会員特典により得られたチケットの先行抽選予約権、チケット、グッズ、その他会員としての資格に基づき有する権利を、インターネットオークション等により第三者に転売、譲渡、貸与、名義変更した場合、または質権の設定、その他の担保に供する場合
(6)　アーティストや当ファンクラブを誹謗中傷する行為を行った場合
(7)　アーティストや他の会員や当ファンクラブ、その他第三者の著作権などの権利を侵した場合、また侵すおそれのある場合
(8)　アーティストや他の会員や当ファンクラブ、その他第三者の財産やプライバシーなどを侵害する行為や、損害を与えた場合、また侵害するおそれや損害を与えるおそれのある場合
(9)　アーティストや他の会員や当ファンクラブ、その他第三者の名誉を傷つけた場合
(10)　犯罪と思われることや犯罪に結びつくことに使用した場合
(11)　選挙運動、宗教や政治活動、性風俗活動などに利用した場合
(12)　本サービスを営利目的に使用した場合
(13)　会員番号やパスワードを不正に使用した場合
(14)　コンピューターウイルスや有害なプログラムを侵入させた場合
(15)　その他、本規約、第１７条の規定するチケット規約、法令または公序良俗に違反する行為、または当ファンクラブの運営を妨害する行為を行った場合
３　前項及び前々項の場合、既に納付された会費は、その理由の如何を問わず、一切返金しないものとします。
 
第10条（当ファンクラブの内容等の変更）
当ファンクラブは、会員への事前の通知・承諾なくして、当ファンクラブの諸条件・運用規則または内容・名称を変更することができ、会員はこれを承諾するものとします。
２　前項の変更については、当ファンクラブが所定の方法により会員に通知するものとします。

第11条（設備等）
会員は、当ファンクラブサービスを利用するために必要な通信機器・ソフトウェア、その他必要となる全ての機器・通信回線等について、自己の費用と責任において準備し、利用するものとします。

第12条（自己責任の原則）
　会員は、当ファンクラブサービスの利用により自己に損害が発生した場合、あるいは、会員が本規約上の義務を履行しないことにより自己に損害が発生した場合、自己の責任と費用をもって処理解決するものとします。
２　会員は、当ファンクラブサービスの利用により当ファンクラブまたは他者に対して損害を与えた場合、あるいは会員が本規約上の義務を履行しないことにより当ファンクラブまたは他者に対して損害を与えた場合、自己の責任と費用をもって損害を賠償するものとします。
３　当ファンクラブは、サービスの利用により発生した会員および第三者の損害一切について、当ファンクラブの責めに帰すべき事由がある場合を除き、一切の責任および損害賠償義務を負わないものとします。

第13条（会員番号およびパスワードの管理責任）
会員は、当ファンクラブが会員に付与する会員番号およびパスワードの管理責任を負うものとします。
２　会員は、会員番号およびパスワードを他者に利用させたり、他者と共有、あるいは貸与、譲渡、名義変更、売買、質入れ等をしたりしてはならないものとします。
３　当ファンクラブは、会員の会員番号およびパスワードが他者に使用されたことによって当該会員が被る損害については、当該会員の故意過失の有無にかかわらず一切の責任を負わないものとします。
４　会員は、会員番号およびパスワードが盗まれた場合や、第三者に使用されていることを知った場合には、直ちに当ファンクラブに連絡しなければならず、当ファンクラブからの指示がある場合にはこれに従うものとします。
 
第14条（禁止事項）
会員は当ファンクラブにおいて以下の行為を行わないものとします。
(1)　当ファンクラブ、会員または第三者の著作権・商標権等の知的財産権を侵害する行為、またはそのおそれのある行為
(2)　当ファンクラブ、会員または第三者の財産・プライバシーもしくは肖像権を侵害する行為、またはそのおそれのある行為
(3)　当ファンクラブ、会員または第三者を差別もしくは誹謗中傷し、または他者の名誉もしくは信用を毀損する行為
(4)　当ファンクラブに対して運営を妨害し、または不利益や損害を与える行為
(5)　当ファンクラブを利用した営業活動・営利目的の行為、またはその準備を目的とした行為
(6)　詐欺等の犯罪および犯罪に結びつく行為、またはそのおそれのある行為
(7)　無限連鎖講（ネズミ講またはこれに類似するもの）を開設する、またはこれらへの参加を勧誘する行為
(8)　公序良俗に反する行為、またはそのおそれのある行為
(9)　会員番号およびパスワードを不正に使用する行為
(10)　他人になりすまして当ファンクラブを利用する行為
(11)　当ファンクラブまたは他者のデータ等を改ざん・消去等する行為
(12)　有害なコンピュータプログラム等を使用、または提供する行為
(13)　アーティストに対する、過剰な追っかけ行為
(14)　その他、法令・本規約に違反する行為またはそのおそれのある行為
(15)　その他、当ファンクラブが不適切と判断する行為
２　前項に該当する会員の行為によって当ファンクラブ、会員または第三者に損害が生じた場合、会員資格を喪失した後であっても、当該会員は一切の責任を負うものとする。

第15条（会員個人情報の取り扱い）
当ファンクラブは、当ファンクラブが保有する会員の個人情報（以下『個人情報』という）に関して適用される法規を遵守し、 適切に取り扱うものとします。
２　当ファンクラブにおける会員の個人情報の取扱いについては、株式会社ISM ENTERTAINMENTが別途定める「個人情報保護方針」及び「プライバシーポリシー」 に従うものとします。

第16条（秘密保持）
当ファンクラブは、会員の秘密を守るものとします。ただし、国、地方公共団体その他公権力に基づく強制の処分が行われた場合には、当ファンクラブは、当該処分の及ぶ範囲で前条及び本条の守秘義務は免除されます。
２　当ファンクラブが管理・運営するサイトの利用記録およびアクセスログの集計、分析を行い、統計資料を作成し、新規サービスの開発等の業務を遂行するために利用、処理することができるものとします。また当ファンクラブは、業務上の必要に応じて統計資料を業務委託先等に提供することができるものとします。

第17条（チケット規約）
当ファンクラブが会員に対し、販売または配券等を行うチケット（以下「チケット」という）の取り扱いについては、当ファンクラブが別途定めるチケット規約に従うものとします。
２　チケット規約に定めがない事項については、本規約が適用されるものとします。
 
第18条（当ファンクラブの活動停止・解散）
当ファンクラブは、アーティストの事情、当ファンクラブの運営状況その他の事情により、当ファンクラブの運営を継続しがたいと判断した場合、解散するものとします。この場合、あらかじめ解散日を予告した上で解散し、 当該月会費は一切返金しないものとします 。
２　当ファンクラブは、以下のいずれかの事由が生じた場合、会員に事前に通知することなく、ファンクラブの運営を一時的に中断、もしくは中止することができるものとします。
(1)　システムの保守を定期的、または緊急に行う場合
(2)　火災・停電等の非常事態により、ファンクラブ運営ができなくなった場合
(3)　地震・噴火・洪水・津波等の天災により、ファンクラブ運営ができなくなった場合
(4)　戦争・暴動・騒乱・労働争議等により、ファンクラブ運営ができなくなった場合
(5)　その他、当ファンクラブが一時的な中断、または中止が必要と判断した場合
３　当ファンクラブ運営の一時的な中断もしくは中止により、会員または第三者が被った損害について、当ファンクラブの責めに帰すべき事由がある場合を除いて、一切の責任を負わないものとします。

第19条（免責事項）
当ファンクラブのサービス内容は、その時点で提供可能なものとします。当ファンクラブは、会員に提供するデータ等について、その完全性・正確性・有用性等について、いかなる責任も負わないものとします。
２　当ファンクラブは、会員が登録したデータ等の流出・消失・第三者による改ざん等について、別途定めがある場合を除いて、いかなる責任も負わないものとします。
３　前各項の他、当ファンクラブサービスの利用により発生した会員の損害、および当ファンクラブサービス提供の遅滞・変更・中断・中止・停止・廃止等の結果、会員または第三者が被った損害等について、当ファンクラブの責めに帰すべき事由がある場合を除いて、一切の責任および損害賠償義務を負わないものとします。

第20条（所有権）
１　当ファンクラブを構成するすべてのサービス・プログラム・ソフトウェア・手続き・商標・商号等その他知的財産権は、当ファンクラブに帰属するものとします。
２　会員は、当ファンクラブに提供した文章・情報等について知的所有権を保有せず、何らの請求権も保有しないものとします。
３　会員は、当ファンクラブに提供した文章・情報等について生じた全ての法的責任を負うものとします。

第21条（管轄裁判所）
会員と当ファンクラブとの間で紛争が生じた場合、当該当事者がともに誠意をもって協議するものとします。
２　協議しても解決せず訴訟の必要が生じた場合、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
 
第22条（準拠法）
本規約の成立・効力・履行および解釈に関しては、日本法が適用されるものとします。

付則　２０２０年５月　　日施行 
 
チケット規約

第1条（チケット規約）
ISM ENTERTAINMENTオフィシャルファンクラブ「ISM FC」（以下「当ファンクラブ」という）が業務委託をした会社名を提示した上で取り扱うチケットの販売または配券等（以下「チケット」という）の申し込みをした会員（以下「申込者」という）は、このチケット規約（以下「本規約」という）を承諾したものとみなします。

第2条（チケットの販売）
チケットの販売は、当ファンクラブが適当と判断した方法により行います。当ファンクラブは、申込者が多数の場合、当ファンクラブが適当と判断した公平かつ公正な方法により抽選を行うものとし、当選者のみがチケットを購入できるものとします。

第3条（申し込みの無効）
当ファンクラブは、次のいずれかに該当する事実がある場合、チケットの申し込みを無効、または抽選による当選を取り消すことができるものとします。
⑴　同一人物で多数の申し込みがあった場合
⑵　申込者の情報が法人の営業所である場合
⑶　申込者が実在しない場合、あるいは実在が疑われる場合
⑷　申込者が第三者への転売目的、その他申込者以外の第三者に利用させる目的で申し込みを行った場合、またはその目的で申し込みを行ったと当ファンクラブが判断した場合
⑸　申込者が、申し込みをした時点で、当ファンクラブ会則および本規約の違反等により、資格の停止処分中であった場合

第4条（代金の支払い）
申込者は、当ファンクラブが指定する支払方法および支払日に従い、チケット代金の入金を行うものとします。当ファンクラブは、支払日に申込者からのチケット代金の入金が確認できない場合、当該申込者の当選を無効とします。

第5条（チケットの配送）
当ファンクラブは、チケットのお申込みの際に提示した方法に則り、申込者が当ファンクラブに登録した住所宛にチケットを配送するものとします。当ファンクラブは、申込者が当ファンクラブに登録した住所宛にチケットの配送を行うことにより、チケットの送付についての一切の責任を免れるものとします。
※チケットの引取方法が変わる場合がございます。

第6条（申し込みの無効）
当ファンクラブは、次の各号に定める事実がある場合、当該チケットを無効とする措置をとることができるものとし、当該チケットが無効となった場合、申込者に対しチケット代金の返還はしないものとします。また、当ファンクラブは、同一人物により複数枚のチケットの申込みがあった場合で、申込者本人以外のチケットが次の各項により無効となった場合、申込者本人のチケットを含む申込みのあったすべてのチケットを無効とする措置をとることができるものとします。
⑴　第3条各項の事実
⑵　インターネットオークション等でチケットが提示されている場合。
⑶　金券ショップ等のチケット店で当該チケットが販売されている場合
⑷　各都道府県の定める「公衆に著しく迷惑をかける暴力的不良行為等の防止に関する条例」により禁止されている｢ダフ屋行為（以下『ダフ屋行為』という）｣の目的となっている場合
２　当ファンクラブは、前項の場合において客席番号が表示されている場合は、該当する客番号のチケットを購入した申込者全員に確認の連絡をすることがあります。

第7条（入場制限等）
当ファンクラブは、次の各号に該当する場合、当該来場者およびその同行者のライブ等の会場（以下『会場』という）への入場を制限し、または退場を求めることができます。なお、この場合、チケットの代金は返還しないものとします。
⑴　チケットが申込者本人のものであるかどうかを確認するために、ファンクラブの会員証および身分証明書の提示を求められた時に、これを理由なく拒否した場合
⑵　来場者が申込者本人であると確認できない場合
⑶　前条１項各号の場合
⑷　正規の販売代金以上の金額で購入したチケットを所持している場合
⑸　会場にカメラ、ビデオ等の録音、録画、撮影機器を持ち込んだ場合、または持ち込もうとした場合
⑹　会場において撮影、録画または録音行為を行った場合（携帯電話による撮影も含む）
⑺　会場において、主催者の指示に従わなかった場合
⑻　会場において、違法に撮影された写真またはビデオ等を購入した場合
⑼　出演者等に対し、過剰な追っかけ行為または誹謗中傷行為を行った場合
⑽　会場において、他の入場者に迷惑をかける行為を行った場合
２　申込者は、前項各号の行為により他の来場者、ライブスタッフ、アーティストその他の第三者に対し損害を与えた場合、これを賠償する責任を負います。
３　当ファンクラブは、第１項各号の行為を行った者に対しては、当ファンクラブの判断により、以後一切のチケットの販売を行わないものとする場合があります。

第8条（退会、除名）
　当ファンクラブは、会員において第6条1項各号及び前条1項各号に該当する場合には、会員資格を喪失し、当ファンクラブから除名することができるものとします。
２　前項の場合、既に納付された会費は、その理由の如何を問わず、一切返金しないものとします。

第9条（会員規約の適用）
本規約に定めがない事項については、会員規約が適用されるものとします。


付則　２０２０年７月１日施行
`;

export default () => <Container css={{paddingTop: 50, fontSize: 11}}><RichText text={txt}/></Container>;