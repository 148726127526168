import React from 'react'
/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import styled from '@emotion/styled'
import theme from './theme';

export const FormField = ({label, required, children}) => (
  <div css={css`margin: 15px 0;`}>
    <label css={css`
      display: block;
      width: 100%;
      margin-bottom: 5px;
      color: #666;
    `}>{label}{required && <span css={{color: theme.colors.accent, marginLeft: 4}}>*</span>}</label>
    {children}
  </div>
)
