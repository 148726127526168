import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import SettingHeader from '../ui/setting-header';
import {PasswordInput} from '../ui/input';
import Button from '../ui/button';
import {Center} from '../ui/flex';
import {Padding} from '../ui/spacing';
import App from '../app';
import api from '../api';
import { OutlinePillNavButton } from '../ui/button';
import theme from '../ui/theme';
import Container from '../ui/container';

export default () => {
  const app = React.useContext(App);
  const [password, setPassword] = React.useState('');

  const submit = ev => {
    ev.preventDefault();
    api()
      .patch('/user/password', {password})
      .then(() => {
        app.flash('パスワードを変更しました')
        setPassword('')
      })
      .catch(e => app.error(e));
  };

  const valid = !!password;

  return (
    <Container css={{paddingTop: 50}}>
      <SettingHeader title="パスワード変更"/>
      <form onSubmit={submit}>
        <PasswordInput value={password} onChange={setPassword} placeholder="新しいパスワード"/>
        <Padding top="20px">
          <Center>
            <Button disabled={!valid}>変更する</Button>
          </Center>
        </Padding>
      </form>
      <div css={{marginTop: 40}}>
        <OutlinePillNavButton color={theme.colors.accent} to="/settings" children="設定へ戻る" caret="left"/>
      </div>
    </Container>
  );
}