import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import api from '../api';
import CardFragment from '../ui/card';
import SettingHeader from '../ui/setting-header';
import Container from '../ui/container';
import { OutlinePillNavButton } from '../ui/button';
import theme from '../ui/theme';
import App from '../app';

export default () => {
  const app = React.useContext(App);
  const [card, setCard] = React.useState('xxxx');

  React.useEffect(() => {
    api()('/sub')
      .then(rs => rs.data.subscription.card)
      .then(card => setCard(card));
  }, []);

  const submit = ({payload, error}) => {
    if (error) {
      app.error();
      return
    }

    const card = payload;

    api().patch('/sub/card', {card})
      .then(rs => rs.data.subscription.card)
      .then(card => {
        app.flash('カードを変更しました');
        setCard(card);
      })
      .catch(() => {
        app.error();
      });
  };

  return <Card card={card} onSubmit={submit}/>;
};

const Card = ({card, onSubmit}) => {
  return (
    <Container css={{paddingTop: 50}}>
      <SettingHeader title="お支払い情報変更"/>
      <Current card={card}/>
      <Card.Spacing>
        <CardFragment submitLabel="変更する" onResult={onSubmit}/>
      </Card.Spacing>
      <div css={{marginTop: 40}}>
        <OutlinePillNavButton color={theme.colors.accent} to="/settings" children="設定へ戻る" caret="left"/>
      </div>
    </Container>
  );
}

Card.Spacing = styled.div`
  margin-top: 40px;
`;

const Current = ({card}) => (
  <Current.Box>
    <Current.Label>現在のカード</Current.Label>
    xxxx-xxxx-xxxx-{card}
  </Current.Box>
);

Current.Box = styled.div`
  border: 1px solid #999;
  padding: 10px;
`;

Current.Label = styled.span`
  font-weight: bold;
  padding-right: 10px;
`;