import React from 'react';
import styled from '@emotion/styled';

const SectionLabel = ({label, image, decorated}) => (
  <Wrap>
    {decorated ? <Decorated image={image}/> : <Plain label={label}/>}
  </Wrap>
);

export default SectionLabel;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:before {
    display: block;
    content: '';
    border-bottom: 1px solid black;
    flex: 1;
  }

  &:after {
    display: block;
    content: '';
    border-bottom: 1px solid black;
    flex: 1;
  }
`;

const Plain = ({label}) => <Plain.Root>{label}</Plain.Root>;

Plain.Root = styled.div`
  padding: 0 20px;
`;

const Decorated = ({image}) => (
  <Decorated.Root>
    <Decorated.Label><img src={image} width="120" height="79" alt=""/></Decorated.Label>
  </Decorated.Root>
);

Decorated.Root = styled.div`
  position: relative;
  margin: 0 20px;
`;

Decorated.Label = styled.div`
  position: relative;
  font-size: 12px;
`;