import React from 'react'
/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import Container from '../ui/container'
import faq from '../faq';
import theme from '../ui/theme';
import { Link } from 'react-router-dom';
import { OutlineButton } from '../ui/button';
import SectionLabel from '../ui/section-label';

export default () => <FAQ/>

export const FAQ = () => (
  <Container css={{paddingTop: 50}}>
    <SectionLabel label="よくあるご質問"/>
    {faq.sections.map(section => (
      <section key={section.id} css={{marginBottom: 40}}>
        <h3 css={css`
          background-color: ${theme.colors.primary};
          color: white;
          padding: 10px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom: 1px solid #CCC;
          text-align: center;
          font-weight: normal;
        `}>{section.label}</h3>
        <ul>
          {section.questions.map(question => (
            <li key={question.id} css={css`
              margin-top: 4px;
              padding: 10px;
              border-radius: 5px;
          
              &:nth-child(odd) {
                background-color: #E8ECEF;
              }
          
              &:nth-child(even) {
                background-color: #F3F5F7;
              }
            `}>
              <Link to={`/faq/${question.id}`}>{question.question}</Link>
            </li>
          ))}
        </ul>
      </section>
    ))}
    <OutlineButton to="/contact" fit color={theme.colors.primary}>
      <div css={{display: 'flex', alignItems: 'center'}}>
        <div css={{flex: 'auto'}}>
          当てはまる質問がない場合<br/>
          お問合せはこちら
        </div>
        <span className="material-icons" css={{color: theme.colors.primary}}>keyboard_arrow_right</span>
      </div>
    </OutlineButton>
  </Container>
)