import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Container from '../ui/container';
import Button, { OutlinePillNavButton } from '../ui/button';
import {TextInput, PasswordInput} from '../ui/input';
import {FormField} from '../ui/form';
import theme from '../ui/theme';
import SectionLabel from '../ui/section-label';
import App from '../app';
import api from '../api';
import { Link } from 'react-router-dom';
import qs from 'qs';

export default ({history}) => {
  const app = React.useContext(App);
  const q = qs.parse(location.search, {ignoreQueryPrefix: true});

  const submit = ({password}) =>
    api().post('/rescue/verify', {password, token: q.token})
      .then(() => app.flash('パスワードをリセットしました'))
      .then(() => history.push('/login'));

  return <RescueReset onSubmit={submit}/>;
};

const RescueReset = ({onSubmit}) => {
  const submit = ev => {
    ev.preventDefault();
    onSubmit({password});
  };

  const [password, setPassword] = React.useState('');
  const valid = password;

  return (
    <Container css={{paddingTop: 45}}>
      <SectionLabel label="LOGIN"/>
      <form onSubmit={submit}>
        <FormField label="新しいパスワード">
          <PasswordInput value={password} onChange={setPassword}/>
        </FormField>
        <div css={{marginTop: 40}}>
          <Button disabled={!valid} image={theme.colors.accentGradient} fit>パスワードリセット</Button>
        </div>
      </form>
    </Container>
  )
};
