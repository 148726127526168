import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {Link} from 'react-router-dom';
import theme from './theme';
import TalentAvatar from './talent-avatar';
import MediaPopup from '../screens/media-popup';
import PastPopup from '../screens/past-popup';
import users from '../users';
import App from '../app';
import { date, mdhm } from '../fmt';
import GA from '../ga';
import moment from 'moment';
import FavIcon from '../img/fav.svg';
import FavIconRed from '../img/fav_red.svg';
import CommentIcon from '../img/comment.svg';

export default ({activity, prize, now_year, past_prizes}) => {
  const app = React.useContext(App);
  return ! activity ? <></> : (
    <div css={{
      margin: '10px 0',
      display: 'flex'
    }}>
      {activity.talent &&
        <>
          <span css={{marginRight: 10, flex: 'none'}}>
            <Link to={`/artists/${activity.talent.id}`}>
              <TalentAvatar talent={activity.talent} link/>
            </Link>
          </span>
          <svg width="7.5" height="7.5" css={{marginTop: 7.5, flex: 'none'}}>
            <polygon points="0 3.75 7.5 0 7.5 7.5" fill={theme.colors.background}/>
          </svg>
        </>
      }
      <div css={{
        backgroundColor: theme.colors.background,
        flex: 'auto'
      }}>
        {activity.talent &&
          <div css={{display: 'flex', padding: 10}}>
            <div css={{
              flex: 'auto'
            }}><Link to={`/artists/${activity.talent.id}`}>{activity.talent.name}</Link></div>
            <div css={{
              fontSize: 11,
              color: '#999'
            }}>{activity.post ? 'BLOG' : activity.media.image ? 'PHOTO' : 'MOVIE'}</div>
          </div>
        }
        <div>
          {activity.post && <Post post={activity.post}/>}
          {activity.media && <Media past_prizes={past_prizes} user={app.user} prize={prize} now_year={now_year} media={activity.media} app={app} published_at={activity.published_at} ended_at={moment(activity.published_at).add(7, 'd')} onClick={activity.media.image ? () => { app.push(<MediaPopup media={{...activity.media, image: prize ? activity.media.image[now_year] : activity.media.image}}/>); if ( ! prize ) { GA.push("/media/" + activity.media.id, "click") } } : null}/>}
          <div css={{fontSize: 12, padding: 10, display: 'flex', justifyContent: "space-between"}}>
            <div>{date(activity.media && activity.media.started_at ? activity.media.started_at : activity.published_at)}</div>
            {activity.post && <PostFav post={activity.post} />}
            {activity.media && <MediaFav media={activity.media} />}
          </div>
        </div>
      </div>
    </div>
  );
}

const Post = ({post}) => (
  <Link to={`/artists/${post.talent.id}/blog/${post.id}`} css={{padding: 10, fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
    <div css={{flex: 'auto'}}>{post.title}</div>
    <span className="material-icons">keyboard_arrow_right</span>
  </Link>
);
const PostFav = ({post}) => <Link to={`/artists/${post.talent.id}/blog/${post.id}`}>
<div css={css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7.7px;
`}>
  <div css={css`
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    cursor: pointer;
    img {
      width: 18.52px;
      height: 15px;
    }
  `}><span>{post.fav_count}</span><img src={post.favorited ? FavIconRed : FavIcon} /></div>
  <div css={css`
    cursor: pointer;
    height: 16.51px;
    img {
      width: 15.76px;
      height: 100%;
    }
  `}><img src={CommentIcon} /></div>
</div>
</Link>;

const MediaFav = ({media}) => media.talent ? <Link to={`/artists/${media.talent.id}/media/${media.id}`}>
<div css={css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7.7px;
`}>
  <div css={css`
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    cursor: pointer;
    img {
      width: 18.52px;
      height: 15px;
    }
  `}><span>{media.fav_count}</span><img src={media.favorited ? FavIconRed : FavIcon} /></div>
  <div css={css`
    cursor: pointer;
    height: 16.51px;
    img {
      width: 15.76px;
      height: 100%;
    }
  `}><img src={CommentIcon} /></div>
</div>
</Link> : <></>

const Media = ({past_prizes, user, media, prize, now_year, app, published_at, ended_at, onClick}) => (
  <>
    {prize
      ? media.image && <img css={{display: 'block', width: '100%', height: 'auto', cursor: 'pointer'}} src={media.image[now_year]} onClick={onClick}/>
      : media.image && <Link to={`/artists/${media.talent.id}/media/${media.id}`}><img css={{display: 'block', width: '100%', height: 'auto', cursor: 'pointer'}} src={media.image} /></Link>}
    {media.video &&
      <div css={{backgroundColor: 'black'}}>
        <video poster={media.poster} src={media.video} controls playsInline css={{display: 'block', width: '100%', height: 'auto'}} onPlay={() => {GA.push(media.is_special ? "/special_movie/"+media.id : "/media/"+media.id, "play")}}/>
      </div>
    }
    {prize && media.caption && <div css={{padding: 12}}>{media.caption.split('\n').map((t) => (
    <>{t}<br/></>
    ))}</div>}
    {! prize && media.caption && <div css={{padding: 12}}>{media.caption}</div>}
    {prize && media.image &&
      <div css={{padding: 12, marginTop: 15, fontSize: 12}}>
        *特典画像は{ended_at.format('MM月DD日 HH:mm')}までタイムライントップに表示されます
      </div>
    }
    {prize && media.video && <div css={{padding: 12, marginTop: 15, fontSize: 12}}>*この動画は{published_at.format('MM月DD日 HH:mm')}までタイムライントップに表示されます</div>}
    {media.ended_at && <div css={{padding: 12, marginTop: 15, fontSize: 12}}>*この動画は{mdhm(media.ended_at)}までタイムライントップに表示されます</div>}
  </>
);
