import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {TextInput} from './input';

export const BirthdayInput = ({value, onChange, error}) => {
  let ps = value.split('/');
  if (ps.length < 3) {
    ps = [...ps, '', '', ''].slice(0, 3);
  }

  const y = ps[0];
  const m = ps[1];
  const d = ps[2];

  const emit = v => {
    if (v.y !== undefined) {
      onChange([v.y, ps[1], ps[2]].join('/'));
    } else if (v.m != undefined) {
      onChange([ps[0], v.m, ps[2]].join('/'));
    } else if (v.d != undefined) {
      onChange([ps[0], ps[1], v.d].join('/'));
    }
  };

  return (
    <div css={{display: 'flex', alignItems: 'center'}}>
      <div css={{width: '80px'}}>
        <TextInput placeholder="2001" value={y} onChange={v => emit({y: v})} error={error}/>
      </div>
      <span css={{margin: '0 0.5em'}}>年</span>
      <div css={{width: '48px'}}>
        <TextInput placeholder="2" value={m} onChange={v => emit({m: v})} error={error}/>
      </div>
      <span css={{margin: '0 0.5em'}}>月</span>
      <div css={{width: '48px'}}>
        <TextInput placeholder="3" value={d} onChange={v => emit({d: v})} error={error}/>
      </div>
      <span css={{margin: '0 0.5em'}}>日</span>
    </div>
  );
}