import React from 'react';
import qs from 'qs';
import api from '../api';
import App from '../app';

export default ({history, location}) => {
  const app = React.useContext(App);
  const q = qs.parse(location.search, {ignoreQueryPrefix: true});

  React.useEffect(() => {
    api().patch('/user/email/verify', {token: q.token})
      .then(() => {
        app.flash('Eメールを変更しました');
        history.push('/settings');
      })
      .catch(e => app.error(e));
  }, [])
  
  return <React.Fragment/>;
}