import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import produce from "immer";
import {Link, withRouter} from 'react-router-dom';
import Button from './ui/button';
import {Row, Center} from './ui/flex';
import theme from './ui/theme';
import Snackbar from './ui/snackbar';
import iam , {authorize, unauthorize} from './iam';
import api from './api';
import header_bg from './img/header_bg.svg';
import header_logoicon from './img/header_logoicon.png';
import header_logo from './img/header_logo.png';
import header_naviicon from './img/header_naviicon.svg';
import DrawerView from './ui/drawer';

const App = React.createContext();

const AppState = () => {
  const [ready, setReady] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const [user ,setUser] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState('');
  const [stack, setStack] = React.useState([]);

  const fetchUser = () => api()('/user')
    .then(rs => rs.data.user)
    .then(user => {
      user = produce(user, user => {
        if (user.follows === null) user.follows = [];
      });

      setUser(user);
    });

  React.useEffect(() => {
    if (iam()) {
      fetchUser()
        .then(() => setReady(true))
        .catch(e => {
          console.error(e);
          unauthorize();
          setReady(true);
        });
    } else {
      setReady(true);
    }
  }, []);
  
  return {
    ready,
    user,
    drawer,
    signed: user != null,
    snackbar,
    signin: token => {
        authorize(token);
        fetchUser();
    },
    signout: () => {
      unauthorize();
      setUser(null);
    },
    resetUser: user => setUser(user),
    openDrawer: () => {
      setDrawer(true);
    },
    closeDrawer: () => {
      setDrawer(false);
    },
    flash: text => {
      setSnackbar(text);
    },
    clearFlash: () => {
      setSnackbar('');
    },
    error: () => {
      setSnackbar('エラーが発生しました');
    },
    stack,
    async push(c) {
      let resolve = null;
      const p = new Promise(rs => { resolve = rs; });

      setStack(stack.concat({
        component: c,
        resolve
      }));
      return p;
    },
    pop(result) {
      const m = stack[stack.length-1];
      m.resolve(result);
      setStack(stack.slice(0, -1));
    },
    follow: async artist => {
      await api().patch(`/talents/${artist.id}/follow`);
      setUser(produce(user, user => {
        user.follows.push(artist);
      }))
    },
    unfollow: async artist => {
      await api().patch(`/talents/${artist.id}/unfollow`);
      setUser(produce(user, user => {
        user.follows = user.follows.filter(f => f.id != artist.id);
      }));
    }
  };
};

export const AppView = ({children}) => {
  const app = React.useContext(App);
  if (!app.ready) return <React.Fragment/>;
  return (
    <>
      <div css={{paddingTop: 65}}>
        <Header/>
      </div>
      <Drawer open={app.drawer} onClose={app.closeDrawer}>
        <DrawerView/>
      </Drawer>
      <div style={{maxWidth: '520px', margin: 'auto', padding: '0 0 50px 0', position: 'relative'}}>
        {children}
      </div>
      {app.snackbar && <Snackbar text={app.snackbar} onDisappear={app.clearFlash}/>}
      {app.stack.length != 0 && (
        <Shutter>
          {app.stack[0].component}
        </Shutter>
      )}
      <Footer/>
    </>
  );
};

const Shutter = ({children}) =>
  <div css={css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  `}>
    {children}
  </div>

const Header = () => {
  const app = React.useContext(App);

  return (
    <header css={css`
      height: 65px;
      padding: 0 20px;
      background-image: url(${header_bg});
      background-size: auto 100%;
      background-position: right;
      background-repeat: no-repeat;
      background-color: ${theme.colors.primary};
      color: white;
      position: fixed;
      z-index: 1;
      width: 100%;
      top: 0;
    `}>
      <Link to="/" css={{
        position: 'absolute',
        left: 20,
        top: 0,
        bottom: 0,
        margin: 'auto',
        height: 40.5
      }}><img src={header_logoicon} width="54" height="40.5" alt="ISM FC"/></Link>
      <Link to="/" css={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        width: 199.5,
        height: 39
      }}><img src={header_logo} width="199.5" height="39"/></Link>
      <img src={header_naviicon} width="30.5" height="25.5" onClick={app.openDrawer} css={{
        position: 'absolute',
        right: 20,
        top: 0,
        bottom: 0,
        margin: 'auto'
      }}/>
    </header>
  );
}

const Drawer = ({children, open, onClose}) =>
  <div onClick={onClose} open={open} css={css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2;
    right: ${open ? '0' : '-100%'}
  `}>
    <div css={css`
      position: absolute;
      width: 100%;
      height: 100%;  
      background-color: rgba(0, 0, 0, 0.5);
      opacity: ${open ? '1.0' : '0.0'};
      transition: opacity 0.25s;
    `}/>
    <div css={css`
      position: absolute;
      width: 100%;
      height: 100%;
      right: ${open ? '0' : '-100%'};
      transition: right 0.25s;
    `}>
      {children}
    </div>
  </div>;

const Footer = () => {
  const styles = {
    line: {
      padding: '5px 0',
      display: 'flex',
      justifyContent: 'center'
    },
    link: {
      padding: '0 10px'
    }
  };

  return (
    <footer>
      <div css={css`
        background-color: #E8ECEF;
        padding: 20px;
        font-size: 12px;     
      `}>
        <div css={styles.line}><a css={styles.link} href="/faq/">よくあるご質問（お問合せ）</a></div>
        <div css={styles.line}>
          <a css={styles.link} href="/agreement/">利用規約</a> | 
          <a css={styles.link} href="/privacy-policy">privacy policy</a> | 
          <a css={styles.link} href="/tradelaw">特定商取引法</a>
        </div>
      </div>
      <small css={css`
        display: block;
        background-color: ${theme.colors.primary};
        color: white;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-size: 9px;
      `}>
        ©︎ISM ENTERTAINMENT
      </small>
    </footer>
  );
}

export default App;

export const AppProvider = ({children}) => (
  <App.Provider value={AppState()}>
    <AppView>{children}</AppView>
  </App.Provider>
);