import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import SettingHeader from '../ui/setting-header';
import {TextInput} from '../ui/input';
import Button from '../ui/button';
import {Center} from '../ui/flex';
import {Padding} from '../ui/spacing';
import App from '../app';
import api from '../api';
import Container from '../ui/container';
import { OutlinePillNavButton } from '../ui/button';
import theme from '../ui/theme';

export default () => {
  const app = React.useContext(App);
  const submit = ({email}) => {
    api()
      .patch('/user/email', {email})
      .then(() => app.flash('Eメール確認メールを送信しました'))
      .catch(e => app.error(e));
  };

  return <Email user={app.user} onSubmit={submit}/>
};

export const Email = ({user, onSubmit}) => {
  const prevEmail = user.email;
  const [email, setEmail] = React.useState(user.email);

  const submit = ev => {
    ev.preventDefault();
    onSubmit({email});
  };

  const valid = prevEmail != email && !!email;

  return (
    <Container css={{paddingTop: 50}}>
      <SettingHeader title="Eメール変更"/>
      <form onSubmit={submit}>
        <TextInput value={email} onChange={setEmail} placeholder="新しいEメール"/>
        <Padding top="20px">
          <Center>
            <Button disabled={!valid}>変更する</Button>
          </Center>
        </Padding>
      </form>
      <div css={{marginTop: 40}}>
        <OutlinePillNavButton color={theme.colors.accent} to="/settings" children="設定へ戻る" caret="left"/>
      </div>
    </Container>
  );
}