import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Container from '../ui/container';
import Button, { OutlinePillNavButton } from '../ui/button';
import {TextInput, PasswordInput} from '../ui/input';
import {FormField} from '../ui/form';
import theme from '../ui/theme';
import SectionLabel from '../ui/section-label';
import App from '../app';
import api from '../api';
import { Link } from 'react-router-dom';

export default ({history}) => {
  const app = React.useContext(App);

  const submit = form =>
    api().post('/rescue', form)
      .then(() => app.flash('メールを送信しました'));

  return <Rescue onSubmit={submit}/>;
};

const Rescue = ({onSubmit}) => {
  const submit = ev => {
    ev.preventDefault();
    onSubmit({email});
  };

  const [email, setEmail] = React.useState('');
  const valid = email;

  return (
    <Container css={{paddingTop: 50}}>
      <SectionLabel label="パスワード忘れ"/>
      <form onSubmit={submit}>
        <FormField label="Eメール">
          <TextInput value={email} onChange={setEmail}/>
        </FormField>
        <div css={{marginTop: 40}}>
          <Button disabled={!valid} image={theme.colors.accentGradient} fit>リセットURLを送信する</Button>
        </div>
      </form>
    </Container>
  )
};
