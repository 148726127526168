import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Button from './button';
import theme from './theme';
import App from '../app';

const Confirm = ({message, attention}) => {
  const app = React.useContext(App);

  return <div css={{
    backgroundColor: 'white',
    maxWidth: 520,
    width: '100%',
    padding: 20
  }}>
    <p>{message}</p>
    {attention && (<p>{attention}</p>)}
    <div css={{display: 'flex', justifyContent: 'flex-end'}}>
      <Button onClick={() => app.pop(false)}>キャンセル</Button>
      <Button color={theme.colors.accent} onClick={() => app.pop(true)}>OK</Button>
    </div>
  </div>;
}

export default Confirm