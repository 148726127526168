let currentToken = localStorage.getItem('USER');

export const authorize = token => {
  currentToken = token;
  localStorage.setItem('USER', token);
};

export const unauthorize = () => {
  currentToken = null;
  localStorage.removeItem('USER');
}

export default () => currentToken;