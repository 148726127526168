import React from 'react';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.onScroll = this.onScroll.bind(this);
    this.state = {stopped: false, locked: false};
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const {next} = this.props;
    const {stopped, locked} = this.state;

    if (window.scrollY + window.innerHeight >= document.body.scrollHeight) {
      if (!stopped && !locked) {
        if (next) {
          this.setState({locked: true});
          next().then(() => this.setState({locked: false}));
          console.log('fetch');
        } else {
          this.setState({stopped: true});
          console.log('stop');
        }
      }
    }
  }
  
  render() {
    const {children} = this.props;
    return children;
  }
}