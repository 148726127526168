import React from 'react';
import qs from 'qs';
import api from '../api';
import Abort from './abort';

export default class SignupVerify extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  async componentDidMount() {
    try {
      const {history, location} = this.props;
      const q = qs.parse(location.search, {ignoreQueryPrefix: true});
      const rs = await api().post('/signup/verify', {token: q.token});
      const {token} = rs.data;
      sessionStorage.setItem("SUBSCRIBER", token);
      history.push('/subscribe');
    } catch(error) {
      this.setState({error});
    }
  }

  render() {
    const {error} = this.state;
    return error ? <Abort title="メール認証に失敗しました" message="再度新規会員登録をお試しください"/> : <React.Fragment/>;
  }
}