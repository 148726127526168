import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import favicon from '../img/favorite.svg';
import { bigInt } from '../fmt';

export const BaseButton = ({disabled, to, onClick, padding={x: 24, y: 8}, borderRadius=8, fit, className, children}) => {

  const style = css`
      display: inline-block;
      padding: ${padding.y}px ${padding.x}px;
      font-size: inherit;
      border-radius: ${borderRadius}px;
      ${fit ? 'width: 100%;' : ''}
      text-align: center;
  `;

  return to ?
    <Link to={to} css={style} className={className} disabled={disabled} onClick={onClick}>{children}</Link> :
    <button css={style} className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
};

const Button = ({color, image, disabled, to, onClick, padding, borderRadius, fit, children}) => (
  <BaseButton
    disabled={disabled}
    to={to}
    onClick={onClick}
    padding={padding}
    borderRadius={borderRadius}
    fit={fit}
    css={css`
      border: none;
      ${disabled ? `
        background-color: #CCC;
      ` : `
        background-color: ${color || '#333'};
        ${image ? `background-image: ${image};` : ''}
      `}
      color: white;
    `}
  >{children}</BaseButton>
);

export default Button;

export const PillNavButton = ({children, color, disabled, to, onClick}) => (
  <Button color={color} disabled={disabled} to={to} onClick={onClick} borderRadius={12.5} padding={{x: 24, y: 0}}>
    <div css={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      fontSize: 12
    }}>
      {children}
      <span className="material-icons">keyboard_arrow_right</span>
    </div>
  </Button>
);

export const OutlinePillNavButton = ({children, color, disabled, to, onClick, fit, caret='right'}) => (
  <OutlineButton color={color} disabled={disabled} to={to} onClick={onClick} borderRadius={12.5} padding={{x: 24, y: 0}} fit={fit}>
    <div css={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      fontSize: 12
    }}>
      {caret == 'left' && <span className="material-icons">keyboard_arrow_left</span>}
      {children}
      {caret == 'right' && <span className="material-icons">keyboard_arrow_right</span>}
    </div>
  </OutlineButton>
);

export const OutlineButton = ({color, disabled, to, onClick, padding, borderRadius, fit, children}) => (
  <BaseButton
    disabled={disabled}
    to={to}
    onClick={onClick}
    padding={padding}
    borderRadius={borderRadius}
    fit={fit}
    css={css`
      background-color: transparent;
      border: 1px solid grey;
      ${color ? `
        border-color: ${color};
        color: ${color};
      ` : ''}
    `}
  >{children}</BaseButton>
);

export const ImageButton = ({src, aspectRatio, to, onClick}) => {
  const style = css`
    display: block;
    cursor: pointer;
    background-image: url(${src});
    background-size: 100% 100%;
    ${aspectRatio ? `
      &:before {
        display: block;
        content: '';
        padding-top: ${aspectRatio * 100}%;
      }
    ` : ''}
  `;

  return to ?
    <Link to={to} css={style} onClick={onClick}/> :
    <span css={style} onClick={onClick}/>;
};


export const FollowButton = ({onClick, followCount, followed}) => {
  const style = css`
    display: inline-block;
    text-align: center;
    color: #c9171e;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 0;
    border: 2px solid #c9171e;
    border-radius: 15px;
    width: 115px;
    cursor: pointer;

    ${followed && `
      background-color: #c9171e;
      color: #fff;
    `}
  `;
    return <div css={style} onClick={onClick}>{followed ? 'フォロー中' : 'フォローする'}</div>
  ;
}

