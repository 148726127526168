import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import Container from '../ui/container';
import ArticleTile from '../ui/article-tile';
import {Padding} from '../ui/spacing';
import NewsTabBar from '../ui/news-tab-bar';
import api from '../api';
import ActivityTile from '../ui/activity-tile';
import InfiniteScroll from '../ui/infinite-scroll';
import App from '../app';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {snapshot: null};
  }

  componentDidMount() {
    api()('/activities')
      .then(res => res.data)
      .then(({activities, meta: {links: {next}}}) => this.setState({snapshot: {activities, next}}));  
  }
  
  render() {
    const {history} = this.props;
    const {snapshot} = this.state;

    if (!snapshot) return <React.Fragment/>;

    const {activities, next} = snapshot;

    return (
      <App.Consumer>
        { app =>
          <Paginator initialSlice={activities} initialNext={next}>
            { (activities, next) => <Activities signed={app.signed} activities={activities} next={next} history={history}/>}
          </Paginator>
        }
      </App.Consumer>
    );
  }
};

const Paginator = ({initialSlice, initialNext, children}) => {
  const [slice, setSlice] = React.useState(initialSlice)
  const [next, setNext] = React.useState(initialNext)

  const fetch = () => api()(next)
    .then(rs => rs.data)
    .then(({activities, meta: {links: {next}}}) => {
      setSlice(slice.concat(activities))
      setNext(next)
    }); 

  return children(slice, next ? fetch : null);
};

export const Activities = ({signed, activities, history, next}) => (
  <InfiniteScroll next={next}>
    <Container css={{paddingTop: 50}}>
      <Padding bottom="10px">
        <NewsTabBar history={history}/>
      </Padding>
      <div>{activities.map(a => a.talent && <ActivityTile key={a.id} activity={a} link={signed}/>)}</div>
    </Container>
  </InfiniteScroll>
);

