import React from 'react';
import styled from 'styled-components';
import TabBar from './tab-bar';
import ttl_jimu_png from '../img/ttl_jimu.png';
import ttl_jimu_wh_png from '../img/ttl_jimu_wh.png';
import ttl_news_png from '../img/ttl_news.png';
import ttl_news_wh_png from '../img/ttl_news_wh.png';
import ttl_act_png from '../img/ttl_act.png';
import ttl_act_wh_png from '../img/ttl_act_wh.png';

export default ({history}) => {
  return (
    <TabBar>
      <NewsTab image={ttl_jimu_wh_png} selectedImage={ttl_jimu_png} selected={location.pathname == '/staffs/news/'} onClick={() => { history.push('/staffs/news/'); }}/>
      <NewsTab image={ttl_news_wh_png} selectedImage={ttl_news_png} selected={location.pathname == '/talents/news/'} onClick={() => { history.push('/talents/news/'); }}/>
      <NewsTab image={ttl_act_wh_png} selectedImage={ttl_act_png} selected={location.pathname == '/activities/'} onClick={() => { history.push('/activities/'); }}/>
    </TabBar>
  )
};

const NewsTab = TabBar.Tab;