import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import api from '../api';
import {ScrollPaginator} from '../ui/paginator';
import BlogBanner from '../ui/blog-banner';
import Container from '../ui/container';
import { date } from '../fmt';
import { Link } from 'react-router-dom';
import FavIcon from '../img/fav.svg';
import FavIconRed from '../img/fav_red.svg';
import CommentIcon from '../img/comment.svg';

export default ({match}) => {
  const fetch = next => api()(next || `/talents/${match.params.talent}/blog`)
    .then(({data: {blog, meta: {links: {next}}}}) => [blog, next]);

  return (
    <ScrollPaginator fetch={fetch}>
      {blog => <TalentBlog talent={{id: match.params.talent}} blog={blog}/>}
    </ScrollPaginator>
  )
}

export const TalentBlog = ({talent, blog}) => (
  <Container>
    <div css={{marginBottom: 5}}>
      <BlogBanner talent={talent}/>
    </div>
    <ul>
      {blog.map(post => (
        <PostTile key={post.id} post={post}/>
      ))}
    </ul>
  </Container>
);

const PostTile = ({post}) => (
  <li css={{
    borderBottom: '1px solid #CCC'
  }}>
    <Link to={`/artists/${post.talent.id}/blog/${post.id}`} css={{
      padding: 15,
      display: 'block'
    }}>
      <div>{post.title}</div>
      <div css={{marginTop: 10}}>
        <span css={{fontSize: 12}}>{date(post.published_at)}</span>
      </div>
      <div css={css`padding: 0 10px 10px`}>
      {<PostFav post={post} />}
    </div>
    </Link>
  </li>
);

const PostFav = ({post}) => <Link to={`/artists/${post.talent.id}/blog/${post.id}`}>
<div css={css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7.7px;
`}>
  <div css={css`
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    cursor: pointer;
    img {
      width: 18.52px;
      height: 15px;
    }
  `}><span>{post.fav_count}</span><img src={post.favorited ? FavIconRed : FavIcon} /></div>
  <div css={css`
    cursor: pointer;
    height: 16.51px;
    img {
      width: 15.76px;
      height: 100%;
    }
  `}><img src={CommentIcon} /></div>
</div>
</Link>;
