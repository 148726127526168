import React, { useEffect } from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import moment from 'moment';
import {Link, Switch, Redirect, Route, useParams} from 'react-router-dom';
import App from '../app';
import Container from '../ui/container';
import TabBar from '../ui/tab-bar';
import MyTimeline from './my-timeline';
import MyNews from './my-news';
import users from '../users';
import theme from '../ui/theme';
import ttl_news_png from '../img/ttl_news.png';
import ttl_news_wh_png from '../img/ttl_news_wh.png';
import ttl_tl_png from '../img/ttl_tl.png';
import ttl_tl_wh_png from '../img/ttl_tl_wh.png';
import ico_flag_svg from '../img/ico_flag.svg';
import prizes from '../prizes';
import setting_icon_svg from '../img/setting_icon.svg';
import mypage_premiummember_png from '../img/mypage_premiummember.png';
import mile_bronze from '../img/mile/mile-bronze.svg';
import mile_silver from '../img/mile/mile-silver.svg';
import mile_gold from '../img/mile/mile-gold.svg';
import mile_diamond from '../img/mile/mile-diamond.svg';
import mile_platinum from '../img/mile/mile-platinum.svg';
import quiz_banner from '../img/mile/quiz-banner.png';
import icon_close from '../img/icon-close.png';
import point10 from '../img/point-10.svg';
import point1 from '../img/point-1.svg';
import point_collect_bg from '../img/point-collect-bg.svg';
import point_incollect_bg from '../img/point-incollect-bg.svg';
import quiz_tamaki from '../img/quiz/quiz-tamaki.png';
import quiz_kakegawa from '../img/quiz/quiz-kakegawa.png';
import quiz_matsuda from '../img/quiz/quiz-matsuda.png';
import quiz_hirata from '../img/quiz/quiz-hirata.png';
import quiz_ishii from '../img/quiz/quiz-ishii.png';
import balloon from '../img/quiz/balloon.svg';

import prize_bronze_hirata from '../img/prize-bronze/hirata_kabe.png';
import prize_bronze_ishii from '../img/prize-bronze/ishii_kabe.png';
import prize_bronze_kakegawa from '../img/prize-bronze/kakegawa_kabe.png';
import prize_bronze_matsuda from '../img/prize-bronze/mastuda_kabe.png';
import prize_bronze_tamaki from '../img/prize-bronze/tamaki_kabe.png';

import api from '../api';

const rank = [
  {
    "id": "regular",
    "range": 1000,
    "name": "レギュラー会員",
    "label": "REGULAR",
    "color": "#6CC6CC"
  },
  {
    "id": "bronze",
    "range": 3000,
    "name": "ブロンズ会員",
    "label": "BRONZE",
    "banner": mile_bronze,
    "color": "#CA8059"
  },
  {
    "id": "silver",
    "range": 5000,
    "name": "シルバー会員",
    "label": "SILVER",
    "banner": mile_silver,
    "color": "#A8ADB4"
  },
  {
    "id": "gold",
    "range": 10000,
    "name": "ゴールド会員",
    "label": "GOLD",
    "banner": mile_gold,
    "color": "#CFAC0D"
  },
  {
    "id": "platinum",
    "range": 30000,
    "name": "プラチナ会員",
    "label": "PLATINUM",
    "banner": mile_platinum,
    "color": "#6E6C6C"
  },
  {
    "id": "diamond",
    "range": 9999999999,
    "name": "ダイヤモンド会員",
    "label": "DIAMOND",
    "banner": mile_diamond,
    "color": "#2A9CCB"
  },
]

export default ({history}) => {
  const app = React.useContext(App);
  const [quiz, setQuiz] = React.useState()
  const [quizSelected, setQuizSelected] = React.useState()
  const [openQuiz, setOpenQuiz] = React.useState(false)
  const [quizMode, setQuizMode] = React.useState("normal")
  const [quizCollect, setQuizCollect] = React.useState(false)
  const [continued, setContinued] = React.useState(false)
  const [canapply, setCanApply] = React.useState(false)
  const [canbronzeapply, setCanBronzeApply] = React.useState(false)
  const [rankinfo, setRankInfo] = React.useState()

  useEffect(() => {
    api()(`/quiz`)
      .then((res) => {
        if (res.data.quiz) {
          setQuiz(res.data.quiz)
        }
      })

      let nowRank
      let nextRank
      let needle
      rank.map((r, i) => {
        if (! nextRank && app.user.mile < r.range) {
          nowRank = r
          nextRank = rank[i + 1]
          if (nextRank) {
            needle = nowRank.range - app.user.mile
          }
        }
      })
    
    setRankInfo({
      nowRank, nextRank, needle
    })

  }, [])

  useEffect(() => {
    if (rankinfo && rankinfo.nowRank.id != 'regular') {
      rankinfo.nowRank.id != 'bronze' && api()(`/apply/${rankinfo.nowRank.id}`).then((res) => {
        if (res.data.ok) {
          setCanApply(true)
        }
      })

      api()(`/apply/bronze`).then((res) => {
        if (res.data.ok) {
          setCanBronzeApply(true)
          }
      })
    }
  }, [rankinfo])
  let url = new URL(window.location.href);
  let params = url.searchParams;

  const quizJudge = () => {
    api().post(`/quiz/judge`, {
      id: quiz.id,
      answer: quizSelected,
    }).then((res) => {
      setQuizMode("answerd")
      if (res.data.judge == "collect") {
        setQuizCollect(true)
        if ( params.get('con') ) {
          setContinued(params.get('con'))
        } else {
          setContinued(res.data.continued)
        }
      }
    })
  }

 
  return (
    <MyPage nowRank={rankinfo && rankinfo.nowRank} nextRank={rankinfo && rankinfo.nextRank} needle={rankinfo && rankinfo.needle} canbronzeapply={canbronzeapply} canapply={canapply} quizMode={quizMode} quizCollect={quizCollect} continued={continued} openQuiz={openQuiz} setOpenQuiz={setOpenQuiz} quizJudge={quizJudge} quizSelected={quizSelected} setQuizSelected={setQuizSelected} quiz={quiz} user={app.user} history={history}>
      <Switch>
        <Redirect exact path="/mypage/" to="/mypage/timeline"/>
        <Route exact path="/mypage/timeline" component={MyTimeline}/>
        <Route exact path="/mypage/news" component={MyNews}/>
      </Switch>
    </MyPage>
  );
};

function Quiz({ quiz, quizSelected, setQuizSelected, openQuiz, setOpenQuiz, quizJudge, quizCollect, continued, quizMode }) {
  return <div css={css`
  opacity: 0;
  pointer-events: none;
  transition: .5s opacity;

  ${openQuiz && `
    opacity: 1;
    pointer-events: all;
  `}

    position: fixed;
    z-index: 10;
    top: 65px;
    left:  0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: calc( 100vh - 65px );
    overflow: scroll;
    background-color: rgba(0, 0, 0, .5);
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  `}>
    <div css={css`
      width: 100%;
      padding: 30px 20px;
      background: white;
      border-radius: 10px;
      max-width: 390px;
      width: 100%;
      position: relative;
    `}>
      <div css={css`
        position: absolute;
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          corsor: pointer;
        }
      `}>
        <img src={icon_close} onClick={() => setOpenQuiz(false)} />
      </div>

      {
        quiz.talent && <div css={css`
          position: absolute;
          top: 20px;
          left: 20px;
          width: 70px;
          height: 70px;

          img {
            width: 100%;
          }
        `}>
          {quiz.talent.id == 'y-tamaki' && <img src={quiz_tamaki} />}
          {quiz.talent.id == 's-matsuda' && <img src={quiz_matsuda} />}
          {quiz.talent.id == 'y-hirata' && <img src={quiz_hirata} />}
          {quiz.talent.id == 'r-kakegawa' && <img src={quiz_kakegawa} />}
          {quiz.talent.id == 'r-ishii' && <img src={quiz_ishii} />}
        </div>
      }

      <div css={css`
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" width="99.502" height="50" viewBox="0 0 99.502 50">
          <path id="パス_613" data-name="パス 613" d="M-47.222-6.771V-22.322h-2.925V-6.771ZM-29.9-7.886a12.114,12.114,0,0,0,5.556,1.473c4.314,0,6.355-1.768,6.355-4.461,0-2.462-1.62-3.956-5.3-5.3-3.3-1.2-3.746-1.873-3.746-3.346,0-1.136.673-2.189,3.093-2.189a6.042,6.042,0,0,1,4.272,1.789l.463-1.62a10.812,10.812,0,0,0-4.966-1.136c-3.851,0-5.471,1.768-5.471,4.125,0,2,1.094,3.556,4.566,4.777,3.283,1.157,4.293,2.294,4.293,3.7,0,1.536-1.073,2.588-3.7,2.588a6.22,6.22,0,0,1-4.777-2.02Zm45.1,1.115V-22.322H12.185c-1.073,2.736-3.977,9.764-5.008,12.184C6.23-12.579,3.494-19.86,2.484-22.68l-2.946.358s.021,2.483.021,5.2c0,1.368,0,2.778-.021,3.977-.021,2.483-.126,5.072-.21,6.376H.884C.758-8.034.674-10.685.653-13.189v-1.747c0-2.462.021-5.114.021-5.114C1.831-17.272,4.63-9.97,5.746-6.981H7.071c1.3-3.22,4.335-10.354,5.24-12.479V-6.771ZM-43.371,5.5H-56A26.141,26.141,0,0,0-53.4.629L-58.592-.844c-1.263,5.331-5.191,11.679-9.961,14.871l.982.947a28.129,28.129,0,0,0,10.522-8h8.277c-2,9.329-8.979,15.958-20.728,19.045l.666,1.3c13.819-1.929,22.587-8.7,25.463-19.746Zm26.34,21.71V10.66h-3.3A44.12,44.12,0,0,0-8.719,2.278L-13.453-.738c-3.683,5.682-12.03,12.416-20.412,15.222l.491,1.192a71.721,71.721,0,0,0,11.4-4.174V27.215ZM30,.98a21.017,21.017,0,0,0-4.595-2.806l-.631.6a18.5,18.5,0,0,1,3.016,4.84ZM26.88,3.05A16.868,16.868,0,0,0,22.356-.528l-.666.6a19.572,19.572,0,0,1,2.63,5.366Zm1.789,19.36a66.725,66.725,0,0,0-11.644-6.138A32.17,32.17,0,0,0,22.987,4.558V3.05H4.82V4.523H17.411C14.921,13.887,9.519,21.287-.266,25.917l.772,1.228a35.454,35.454,0,0,0,15.467-9.68,39.715,39.715,0,0,1,9.049,9.645Z" transform="translate(69.5 22.68)"/>
        </svg>
      </div>

      {quizMode == 'normal' &&
        <div css={css`
        p {
          font-size: 17px;

          br:last-child {
            display: none;
          }
        }
        ul {
          margin-top: 50px;

          li {
            margin-bottom: 30px;
            font-size: 22px;
            font-weight: bold;
            &:last-child {
              margin-bottom: 0;
            }

            label {
              display: flex;
              gap: 15px;
              align-items: center;
            }
          }
        }
      `}>
          <div css={css`
            position: relative;
            &:after {
              content: "";
              ${quiz.talent && `background: url(${balloon}) center center no-repeat;`}
              background-size: contain;
              height: 19.36px;
              width: 15.07px;

              position: absolute;
              top: -15px;
              left: 20px;
            }
          `}>
            <p css={css`
            padding: 10px;
            border: 1px solid #A3B3BF;
            border-radius: 10px;
            position: relative;
            background: white;
            `}>{quiz.text.split('\n').map(t => <>{t}<br /></>)}</p>
          </div>

          <ul>
            {quiz.selectors.map((s, i) =>
              s !== '' && <li key={i + 1}>
                <label><input type="radio" value={i + 1} checked={quizSelected == i + 1} onChange={() => setQuizSelected(i + 1)} />{s}</label>
              </li>
            )}
          </ul>

          <div css={css`
          display: flex;
          justify-content: center;
          margin-top: 70px;
        `}>
            <span css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 47px;
            background: ${quizSelected == null ? `gray` : `#C9171E`};
            border-radius: 24px;
            color: white;
            font-size: 17px;
            font-weight: bold;
            cursor: pointer;
          `} onClick={quizJudge}>クイズに回答する</span>
          </div>
        </div>}
      
      {quizMode == "answerd" && 
        <div css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
        `}>
          <div css={css`
            ${quizCollect && `color: #C9171E;`}
            font-size: 35px;
            font-weight: bold;
            margin-bottom: 20px;
          `}>{quizCollect ? `正解！` : `不正解`}</div>
          <div css={css`
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 8px;
            span {
              font-weight: normal;
            }
            em {
              font-style: normal;
            }
          `}>
            {!quizCollect && <span>正解：</span>}{quiz.selectors.map((s, i) => String(i + 1) == quiz.answer && <em>{s}</em>)}
          </div>
          <div css={css`
            width: 271px;
            height: 217px;
            margin-bottom: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            ${quizCollect ? `
              background: url(${point_collect_bg}) center center no-repeat;
            ` : `
              background: url(${point_incollect_bg}) center center no-repeat;
            `}
            background-size: contain;
            `}>
            <p css={css`
            font-size: 23px;
            font-weight: bold;

            text-align: center;

            em {
              font-size: 60px;
              font-style: normal;
              color: #69BCC1;
              margin-right: 5px;
            }
            span {
              letter-spacing: 1em;
              margin-right: -1em;
            }
            `}>
            {quizCollect && quiz.talent && <><em>20</em>ISM MILE<br /><span>GET</span></>}
            {quizCollect && !quiz.talent && <><em>10</em>ISM MILE<br /><span>GET</span></>}
            {!quizCollect && <><em>1</em>ISM MILE<br /><span>GET</span></>}
            </p>
          </div>
          {continued > 0 && <div css={css`text-align: center; margin-bottom: 10px;`}>
            <ContinueNumber val={continued} /><span css={css`font-size: 23px; font-weight: bold;`}>日連続正解！</span>
          </div>}

          <div css={css`
            display: flex;
            justify-content: center;
          `}>
            <span css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 200px;
              height: 47px;
              border: 1px solid black;
              border-radius: 24px;
              font-size: 17px;
              font-weight: bold;
            `} onClick={() => setOpenQuiz(false)}>閉じる</span>
          </div>
        </div>
      }
    </div>
  </div>
}

function CanApply({ rank }) {
  const doApplied = () => {
    api().post(`apply`, {rank: rank.id})
  }
  const goform = () => {
    window.open('https://forms.gle/nWbFvue8JHuqPdZp9')
    doApplied()
  }
  return <div css={css`
    border: 1px solid black;
    padding: 20px;
    margin-bottom: 40px;
  `}>
    <p css={css`
      font-size: 14px;

      em {
        display: block;
        font-weight: bold;
        font-style: normal;
        margin-top: 10px;
        font-size: 12px;
      }
    `}>
      {rank.name}特典に応募できるようになりました。下記のボタンから応募フォームにアクセス可能です。
      <em>※「応募フォームを開く」をクリックしたあと、ページをリロードすると再度アクセスはできませんのでご注意ください</em>
    </p>

    <div css={css`
      margin-top: 20px;

      span {
        display: flex;
        width: 200px;
        height: 47px;
        align-items: center;
        justify-content: center;
        background-color: #C9171E;
        color: white;
        font-size: 17px;
        border-radius: 24px;
        margin-inline: auto;
        font-weight: bold;
        cursor: pointer;
      }
    `}>
      <span onClick={goform}>応募フォームを開く</span>
    </div>
  </div>
}


function CanBronzeApply() {
  const [open, setOpen] = React.useState(false)

  const doApplied = () => {
    api().post(`apply`, { rank: 'bronze' })
  }
  const openBronz = () => {
    setOpen(true)
    doApplied()
  }
  return <><div css={css`
    border: 2px solid #CA8059;
    padding: 20px;
    margin-bottom: 40px;
  `}>
    <p css={css`
      font-size: 14px;

      em {
        display: block;
        font-weight: bold;
        font-style: normal;
        margin-top: 10px;
        font-size: 12px;
      }
    `}>
        ブロンズ特典の壁紙がダウンロード可能です。<br />
      <em>※「ブロンズ特典を開く」をクリックしたあと、ページをリロードすると再度ダウンロードはできませんのでご注意ください</em>
      <em>※パソコンの方は右クリックから画像の保存、スマホの方は画像を長押しすることで画像の保存が可能です</em>
    </p>

    <div css={css`
      margin-top: 20px;

      span {
        display: flex;
        width: 200px;
        height: 47px;
        align-items: center;
        justify-content: center;
        background-color: #C9171E;
        color: white;
        font-size: 17px;
        border-radius: 24px;
        margin-inline: auto;
        font-weight: bold;
        cursor: pointer;
      }
      ${open && `
        display: none;
      `}
    `}>
      <span onClick={openBronz}>ブロンズ特典を開く</span>
    </div>

    <div css={css`
      height: 0;
      opacity: 0;
      transition: all .5s ease;
      pointer-events: none;

      ${open && `
      height: 100%;
      opacity: 1;
      pointer-events: all;
      `}
    `}>
      <div css={css`
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between; 

        img {
          width: calc(50% - 5px);
        }
      `}>
        <img src={prize_bronze_hirata} />
        <img src={prize_bronze_ishii} />
        <img src={prize_bronze_kakegawa} />
        <img src={prize_bronze_matsuda} />
        <img src={prize_bronze_tamaki} />
      </div>
    </div>
  </div>
  </>
}


export const MyPage = ({canbronzeapply, canapply, nowRank, nextRank, needle, quizMode, quizCollect, continued, quiz, openQuiz, setOpenQuiz, quizJudge, quizSelected, setQuizSelected, user, history, children}) => {
  const tabBar = (tab) => (
    <TabBar>
      <TabBar.Tab selected={tab == 'timeline'} image={ttl_tl_wh_png} selectedImage={ttl_tl_png} onClick={() => history.push('/mypage/timeline')}/>
      <TabBar.Tab selected={tab == 'news'} image={ttl_news_wh_png} selectedImage={ttl_news_png} onClick={() => history.push('/mypage/news')}/>
    </TabBar>
  );
  
  return (
    <>
      {quiz && <Quiz quizMode={quizMode} quizCollect={quizCollect} continued={continued} quiz={quiz} quizSelected={quizSelected} setQuizSelected={setQuizSelected} openQuiz={openQuiz} setOpenQuiz={setOpenQuiz} quizJudge={quizJudge} />}
      <Container>
        <div css={css`
          position: absolute;
          top: 15px;
          left: 20px;
          font-size: 20px;
          text-align: center;
          line-height: 1;
          font-weight: bold;

          span {
            display: block;
            margin-bottom: 10px;
            font-size: 13px;
            font-weight: normal;
          }
        `}>
          <span>会員番号</span>{`000000${user.id}`.slice(-6)}
        </div>
        <div css={css`
          position: absolute;
          top: 15px;
          right: 20px;
          font-size: 13px;
          font-weight: bold;
          text-align: center;
          line-height: 1;

          span {
            display: block;
            margin-bottom: 10px;
            font-weight: normal;
          }
          em {
            font-size: 20px;
            font-style: normal;
          }
        `}>
          <div>
            <span>FC会員継続期間</span><em>{users.continued(user)}</em>日目
          </div>
          <div css={css`
            text-align: right;
            margin-top: 15px;
          `}>
            <Link to="/settings/">
              <img src={setting_icon_svg} width="30"/>
            </Link>
          </div>
        </div>

        <div css={css`
          padding: 0 20px;
          height: 233px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 30px;
          padding-top: 15px;
        `}>
          <img css={{ borderRadius: '50%' }} src={user.avatar} alt="" width="80" height="80" />
          
          <div>
            {nowRank && <div css={css`
              text-align: center;
            `}><span css={css`
              font-size: 20px;
              font-weight: bold;
              color: #CA8059;
              margin-bottom: 15px;
              line-height: 1;

              color: ${nowRank.color}
            `}>{nowRank.label}</span></div>}
            <p css={css`
              font-size: 15px;
              em {
                font-size: 30px;
                font-weight: bold;
                font-style: normal;
                margin-right: 7px;
              }
            `}><em>{user.name}</em>さん</p>
          </div>
        </div>
        
        <div css={css`
          position: relative;
          height: 120px;
          margin-bottom: 40px;
        `}>
          {nowRank && <div css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 40px;
            font-size: 16px;
            color: #A3B3BF;
            background-color: #E8ECEF;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            padding-right: 13px;

            &:after {
              content: "";
              display: block;
              height: 100%;
              width: 79px;
              left: 0;
              top: 0;
              position: absolute;
              background-color: #BE272E;
            }

            em {
              font-style: normal;
              font-weight: bold;
            }
          `}>{nextRank ? <>あと<em>{needle}</em>マイルで{nextRank.name}</> : nowRank.name}</div>}
          {nowRank && nowRank.banner ? <img src={nowRank.banner} css={css`
            position: absolute;
            width: 120px;
            height: 120px;
            top: 0;
            bottom: 0;
            left: 20px;
          `} />
            : <div css={css`
              width: 120px;
              height: 120px;
              font-size: 37px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background-color: #121133;
              border-radius: 50%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 20px;
              color: white;
              font-weight: bold;
              line-height: 1;

              &:before {
                content: "";
                display: block;
                width: 104px;
                height: 104px;
                border: 2px solid white;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }

              em {
                letter-spacing: .2em;
                margin-right: -.2em;
                font-style: normal;
                position: relative;
              }
              span {
                font-size: 10px;
                letter-spacing: .7em;
                margin-right: -.7em;
                margin-top: 5px;
                position: relative;
              }
            `}><em>{user.mile}</em><span>MILE</span></div>
          }
          <div css={css`
            position: absolute;
            top: 0;
            right: 0;
            font-size: 17px;
            line-height: 1;

            em {
              font-size: 33px;
              font-weight: bold;
              font-style: normal;
            }

            ${nowRank && nowRank.label == "ノーマル" && `display: none`}
          `}><em>{user.mile}</em> マイル</div>
        </div>

        {quiz && <div css={css`
          margin-bottom: 40px;
          cursor: pointer;
          img {
            width: 100%;
            vertical-align: top;
          }
        `}>
          <img src={quiz_banner} onClick={() => setOpenQuiz(true)} />
        </div>}

        {canbronzeapply && <CanBronzeApply/>}
        {canapply && <CanApply rank={nowRank} />}

        <div css={{ margin: '20px 0' }}>
          <Switch>
            <Route exact path="/mypage/timeline">{() => tabBar('timeline')}</Route>
            <Route exact path="/mypage/news">{() => tabBar('news') }</Route>
            {tabBar('')}
          </Switch>
        </div>
        {children}
      </Container>
    </>
  );
}

function ContinueNumber({val}) {
  let color;

  if ( val < 5 ) {
    color = ['#69BCC1']
  } else if ( val < 10 ) {
    color = ['#C9171E']
  } else if ( val < 50 ) {
    color = ['#ad7d5a 12%', '#a46c4c 32%', '#ffad7f 60.8%', '#975533 90%']
  } else if ( val < 100 ) {
    color = ['#858e9b 12%', '#757e8c 32%', '#dcdddd 60.8%', '#767f8d 90%']
  } else {
    color = ['#ad751e 12%', '#a47c18 32%', '#ffe300 60.8%', '#a47b19 90%']
  }
  return <span css={css`
    display: inline-block;
    background: ${color.length == 1 ? color.concat() : `linear-gradient(-20deg, ${color.join(',')})`};
    background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size: ${val >= 50 ? `40px` : `35px`};
  `}>
    {val}
  </span>

}