import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Container from '../ui/container';
import Button from '../ui/button';
import {TextInput, PasswordInput} from '../ui/input';
import {BirthdayInput} from '../ui/birthday';
import {FormField} from '../ui/form';
import theme from '../ui/theme';
import SectionLabel from '../ui/section-label';
import Checkbox from '../ui/checkbox';
import {Row, Center} from '../ui/flex';
import {Padding} from '../ui/spacing';
import {OutlinePillNavButton} from '../ui/button';
import api from '../api';
import { isEmail } from '../validators';
import App from '../app';

export default ({history}) => {
  const app = React.useContext(App)

  const submit = async (body) => {
    try {
      await api().post('/signup', body);
      history.push('/signup/sent');
    } catch(e) {
      if (e.response && e.response.status == 403) {
        app.flash('このEメールアドレスは現在ご利用いただけません')
      } else {
        app.error();
      }
    }
  };

  return <Signup onSubmit={submit}/>
};

export const Signup = ({onSubmit}) => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [agree, setAgree] = React.useState(false);
  const [birthday, setBirthday] = React.useState('');
  const [cycle, setCycle] = React.useState('monthly');
  const [errors, setErrors] = React.useState({});
  
  const filled = name && email && password && birthday && agree && cycle;

  const valid = () => {
    let errors = {}

    if (!isEmail(email)) {
      errors['email'] = true;
    }

    if (/[0-9a-zA-Z]{6,}/.test(password)) {
      if (!/[0-9]/.test(password) || !/[a-zA-Z]/.test(password)) {
        errors['password'] = true;
      }
    } else {
      errors['password'] = true;
    }

    if (!/^[0-9]{4}\/[0-9]{1,2}\/[0-9]{1,2}$/.test(birthday)) {
      errors['birthday'] = true;
    } else {
      if (moment().diff(moment(birthday, 'YYYY/M/D'), 'years') <= 12) {
        errors['birthday'] = true;
      }
    }
    
    setErrors(errors);
    return Object.keys(errors).length == 0;
  };

  return (
    <Container css={{paddingTop: 50}}>
      <SectionLabel label="新規会員登録"/>
      <form onSubmit={ev => { ev.preventDefault(); valid() && onSubmit({name, email, password, cycle, birthday: moment(birthday, 'YYYY/M/D').format('YYYY-MM-DD')}); }}>
        <FormField label="お名前">
          <TextInput value={name} onChange={setName}/>
        </FormField>
        <FormField label="メールアドレス">
          <TextInput value={email} onChange={setEmail} error={errors.email}/>
        </FormField>
        <FormField label="パスワード">
          <PasswordInput value={password} onChange={setPassword} error={errors.password}/>
          <p css={{color: '#666', fontSize: 11, marginTop: 10}}>
            6文字以上で、アルファベットと数字を1文字以上使ってください
          </p>
        </FormField>
        <FormField label="誕生日">
          <BirthdayInput value={birthday} onChange={setBirthday} error={errors.birthday} />
          <p css={{color: '#666', fontSize: 11, marginTop: 10}}>
            12才以下の方はご利用できませんので、ご注意ください
          </p>
        </FormField>
        <FormField label="契約期間">
          <div css={css`
            background: #E8ECEF;
            border-radius: 5px;
            overflow: hidden;
          `}>
            <label css={css`
              display: flex;
              align-items: center;
              padding: 20px;
              border-bottom: 1px solid #fff;
              cursor: pointer;
              gap: 20px;
            `}>
              <input type="radio" name="cycle" value="monthly" checked={cycle == 'monthly'} onClick={() => setCycle('monthly')}/>
              <p>
                1ヶ月 月額: 800円（税別）<br /><span css={css`color: red`}>※登録から3ヶ月間は利用料半額！</span><br />
                <span css={css`font-size: 10px; color: gray; line-height: 1.2em; display: block;`}>退会から1ヶ月以内のご入会の場合、本キャンペーンは対象外とさせていただきます。</span>
              </p>
              
            </label>
            <label css={css`
              display: flex;
              align-items: center;
              padding: 20px;
              cursor: pointer;
              background-color: #E8ECEF;
              gap: 20px;
              
            `}>
              <input type="radio" name="cycle" value="yearly" checked={cycle == 'yearly'} onClick={() => setCycle('yearly')}/>
              <p>1年 年額: 9,000円（税別）</p>
            </label>
          </div>
        </FormField>
        <Padding y="5px">
          <Row>
            <Padding right="10px">
              <Checkbox checked={agree} onChange={setAgree}/>
            </Padding>
            <div>
              <Link css={{color: theme.colors.accent, textDecoration: 'underline'}} to="/agreement" target="_blank">利用規約</Link>に同意します
              <p css={{fontSize: 12}}><span css={{color: theme.colors.accent}}>*</span>必ずご確認ください</p>
            </div>
          </Row>
        </Padding>
        <div css={{marginTop: 20}}>
          <Center>
            <p css={css`padding-left: 3em; text-indent: -3em;`}>会費：月額800円(税別)<br />
            年額9,000円(税別)</p>
          </Center>
          <p css={{marginTop: 10, color: theme.colors.accent, textDecoration: 'underline', textAlign: 'center', fontSize: 12}}><Link to="/guide#price" target="_blank">会費と有効期限について</Link></p>
        </div>
        <div css={{marginTop: 20}}>
          <Button disabled={!filled} image={theme.colors.accentGradient} fit>
            会員登録する
          </Button>
        </div>
        <div css={{marginTop: 40}}>
          <OutlinePillNavButton to="/login" color={theme.colors.accent} children="ご登録済みの方はこちらからログイン" fit/>
        </div>
      </form>
    </Container>
  );
};