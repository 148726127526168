import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Container from '../ui/container';
import SectionLabel from '../ui/section-label';
import theme from '../ui/theme';
import Button from '../ui/button';

export default () => (
  <Container css={{paddingTop: 45}}>
    <SectionLabel label="会員登録完了"/>
    <p css={{textAlign: 'center', marginBottom: 20}}>会員登録が完了いたしました。</p>
    <div css={{
      textAlign: 'center',
      color: theme.colors.accent,
      border: `1px solid ${theme.colors.accent}`,
      padding: 20
    }}>
      <span css={{fontWeight: 'bold'}}>
        ご登録メールアドレスおよびパスワードはログイン、各種お問合せの際に必要です。<br/>
      </span>
      <br/>
      お忘れにならないよう、<br/>
      必ずメモや写真などで、お控えください。
    </div>
    <div css={{marginTop: 40}}>
      <Button to="/login" image={theme.colors.accentGradient} fit>
        ログイン
      </Button>
    </div>
  </Container>
);