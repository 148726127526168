import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Redirect} from 'react-router-dom';
import Button from '../ui/button';
import {FormField} from '../ui/form';
import api from '../api';
import App from '../app';
import Container from '../ui/container';
import SectionLabel from '../ui/section-label';
import theme from '../ui/theme';
import CardFragment from '../ui/card';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      key: sessionStorage.getItem('SUBSCRIBER')
    };
  }

  render() {
    const {history} = this.props;
    const {key} = this.state;
    if (!key) return <Redirect to="/signup"/>;
    
    return (
      <App.Consumer>
        { app => <Screen token={key} history={history} app={app}/>}
      </App.Consumer>
    );
  }
}

const Screen = ({token, history, app, cycle}) => {
  const postAPI = async (card) => {
    const rs = await api().post('/subscribe', {
      token,
      card,
      cycle,
    });
    return rs.data.token;
  }

  const submit = async ({payload, error}) => {
    if (error) {
      app.error();
      console.error(error);
      return
    }
    
    const card = payload;
    try {
      token = await postAPI(card);
      await app.signin(token);
      history.push('/signup/complete');
    } catch(e) {
      if (e.response && e.response.status == 403) {
        app.flash('この支払い情報が現在ご利用いただけません')
      } else {
        app.error()
      }
      console.error(e);
    }
  };

  return <Subscribe onSubmit={submit}/>;
};

export const Subscribe = ({onSubmit}) => (
  <Container css={{paddingTop: 45}}>
    <SectionLabel label="新規会員登録"/>
    <p css={{textAlign: 'center', marginBottom: 20}}>お支払い方法をご入力ください。</p>
    <CardFragment submitLabel="購入" onResult={onSubmit}/>
  </Container>
);
