import React from 'react';
import styled from '@emotion/styled';
import {Expanded, Row} from './flex';
import theme from './theme';

const TabBar = ({children}) => {
  return (
    <Root>
      <Row justifyContent="stretch" alignItems="stretch">
        {children.map((t, i) => <Expanded key={i}>{t}</Expanded>)}
      </Row>
    </Root>
  )
};

const Root = styled.div`
  background-color: ${theme.colors.primary};
  color: white;
  padding: 5px 5px 0 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

TabBar.Tab = ({label, image, selectedImage, selected, onClick}) => (
  <TabBar.Tab.Root selected={selected} onClick={onClick}>
    {image ? (selected ? <img src={selectedImage} width="90" height="30"/> : <img src={image} width="90" height="30"/>) : label}
  </TabBar.Tab.Root>
);

TabBar.Tab.Root = styled.div`
  ${({selected}) => selected ? `
    background-color: white;
    color: ${theme.colors.primary};
  ` : ''}
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 100%;
`;

export default TabBar;