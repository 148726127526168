import React from 'react';
import styled from 'styled-components';

export class CarouselController {
  constructor(page=0.0, onPage=function(){}) {
    this.page = page;
    this.onPage = onPage;
  }

  attach({onMove}) {
    this.onMove = onMove;
  }

  moveTo(page) {
    this.onMove(page);
  }
};

class GestureRecognizer {
  constructor(el, observer) {
    this.el = el;
    this.observer = observer;
    el.addEventListener('touchstart', this.onTouchStart.bind(this));
    el.addEventListener('touchmove', this.onTouchMove.bind(this));
    el.addEventListener('touchend', this.onTouchEnd.bind(this));
  }

  onTouchStart(e) {
    this.startX = e.touches[0].clientX;
    this.startY = e.touches[0].clientY;
    this.locX = this.startX;
    this.locY = this.startY;
    this.startTime = (new Date).getTime();
    this.phase = 1;
    this.recognized = false;
    this.startScrollTop = window.scrollY;
    this.scroll = 0;

    if (this.observer.onStart) this.observer.onStart();
  }

  onTouchMove(e) {
    var prevX = this.locX;
    var prevY = this.locY;
    this.locX = e.touches[0].clientX;
    this.locY = e.touches[0].clientY;
    var deltaX = this.locX - prevX;
    var deltaY = this.locY - prevY;

    if (this.phase === 1 && window.scrollY == this.startScrollTop) {
      var angle = Math.atan2(deltaY, deltaX);
      if (
        angle >= 0 && angle <= Math.PI / 4 ||
        angle >= Math.PI / 4 * 3 && angle <= Math.PI ||
        angle > -Math.PI && angle < -Math.PI / 4 * 3 ||
        angle > -Math.PI / 4 && angle < 0
      ) {
        this.recognized = true;
        this.phase = 2;
      }
    }

    if (this.recognized) {
      this.scroll += deltaX;
      if (this.observer.onPan) this.observer.onPan(deltaX);
      if (e.cancelable) e.preventDefault();
    }
  }

  onTouchEnd(e) {
    if (!this.recognized) return;

    var stopTime = (new Date).getTime();
    var deltaX = this.locX - this.startX;
    var deltaNorm = Math.abs(deltaX);
    var deltaTime = stopTime - this.startTime;

    if (deltaTime < 200 && deltaNorm > 10) {
      if (this.observer.onSwipe) this.observer.onSwipe(deltaX > 0 ? -1 : 1);
    } else {
      var t = Math.round(this.scroll / this.el.clientWidth);
      if (t != 0) {
        if (this.observer.onSwipe) this.observer.onSwipe(t >= 1 ? -1 : 1);
      } else {
        if (this.observer.onStop) this.observer.onStop();
      }
    }
  }
};

class Ticker {
  constructor(duration, update) {
    this.update = update;
    this.start = new Date().getTime();
    this.duration = duration;
    this.loop();
  }

  loop() {
    const t = new Date().getTime() - this.start;

    if (t < this.duration) {
      this.update(t / this.duration);
      requestAnimationFrame(() => this.loop());
    } else {
      this.update(1);
    }
  }
}

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.$root = React.createRef();
    const controller = this.props.controller || new CarouselController();

    controller.attach({
      onMove: page => this.animateTo(page)
    });

    this.state = {
      width: 0,
      height: 0,
      page: 0.0,
      pivot: 0.0,
      controller
    };
  }

  setPage(page) {
    this.setState({page});
    this.state.controller.onPage(page);
  }

  componentDidMount() {
    const bbox = this.$root.current.getBoundingClientRect();
    this.setState({width: bbox.width, height: bbox.height});
    this.gesture = new GestureRecognizer(
      this.$root.current,
      {
        onStart: () => {
          this.setState({pivot: this.state.page});
        },
        onPan: tx => {
          const {page, width} = this.state;
          this.setPage(page-tx/width);
        },
        onSwipe: d => {
          const {children} = this.props;
          const {page, pivot} = this.state;

          if (page < 0 || page > children.length-1) this.animateTo(pivot);
          else {
            this.animateTo(pivot + d);
          }
        },
        onStop: () => {
          const {pivot} = this.state;
          this.animateTo(pivot);
        }
      }
    );
  }

  animateTo(page) {
    const pivot = this.state.page;
    new Ticker(200, t => this.setPage(pivot + (page-pivot)*(1-(1-t)*(1-t))))
  }

  render() {
    const {children} = this.props;
    const {width, height, page} = this.state;

    return (
      <Root ref={this.$root}>
        <Board width={width} src={children} style={{transform: `translateX(${-page*width}px)`}}>
          {children.map((ch, i) => (
            <Slide key={i} width={width} height={height}>{ch}</Slide>
          ))}
        </Board>
      </Root>
    );
  }
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Board = styled.div`
  ${({width, src}) => `
    width: ${width * src.length}px;
    display: flex;
  `}
`;

const Slide = styled.div`
  ${({width, height}) => `
    width: ${width}px;
    height: ${height}px;
  `}  
`;