import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Container from '../ui/container';
import SectionLabel from '../ui/section-label';
import theme from '../ui/theme';
import { Link } from 'react-router-dom';
import tokuten_graph_png from '../img/tokuten_graph.png';
import tokuten_graph_year_png from '../img/tokuten_graph_year.png';
import { OutlinePillNavButton } from '../ui/button';

export default () => {

  return (
    <Container css={{paddingTop: 50}}>
      <SectionLabel label="ご案内"/>
      <Section label="会員特典" id="prize">
        <PrizeCard
          subTitle="ISM ENTERTAINMENT所属アーティスト全員の"
          title="FC先行チケット販売"/>
        <PrizeCard
          title="FC限定イベント開催"/>
        <PrizeCard
          subTitle="アーティスト本人による"
          title="FC限定ブログ・写真・動画"/>
        <PrizeCard
          subTitle="初日、3・6・12ヵ月でもらえる"
          title="入会・継続特典の待受画像"/>
        <PrizeCard
          subTitle="ISMマイルを貯めると"
          title="限定グッズプレゼント"/>
        <PrizeCard
          subTitle="ISM ENTERTAINMENT所属アーティストからの"
          title="お誕生日メッセージ"
        />
        <p css={{marginTop: 25, textAlign: 'center'}}>
          他にも様々な特典や、<br/>
          サービスを実施予定です。<br/>
          皆様のご入会、心よりお待ちしております。
        </p>
      </Section>
      <Section label="ご登録方法" id="register">
        <StepTile number="1">
          <Link to="/signup" css={{color: theme.colors.accent, textDecoration: 'underline'}}>新規会員登録フォーム</Link>に情報を入力し、利用規約を確認後、「利用規約に同意します」にチェックを入れ「会員登録する」で次へ進む
        </StepTile>
        <StepTile number="2">
          確認メールに記載されているリンクを開く
        </StepTile>
        <StepTile number="3">
          お支払いクレジットカード情報を入力
        </StepTile>
        <StepTile number="4">
          会員登録完了
        </StepTile>
      </Section>
      <Section label="会費と有効期限について" id="price">
        <p css={{textAlign: 'left', margin: '40px 0', paddingLeft: '3em', textIndent: '-3em'}}>会費：月額800円（税別）<br />年額9,000円（税別）</p>
        <hr css={{marginTop: 25, marginBottom: 25, borderTop: `1px solid ${theme.colors.background}`}}/>
        <div>
          <p css={css`font-size: 20px; font-weight: bold; margin-bottom: 20px; color: #C9171E`}>月額会員の有効期限と更新日</p>
          <img src={tokuten_graph_png} alt="" css={{width: '100%', height: 'auto'}}/>
        </div>
        <div css={css`margin-top: 50px;`}>
        <p css={css`font-size: 20px; font-weight: bold; margin-bottom: 20px; color: #C9171E`}>年額会員の有効期限と更新日</p>
          <img src={tokuten_graph_year_png} alt="" css={{width: '100%', height: 'auto'}}/>
        </div>
        <p css={{fontSize: 12, marginTop: 40}}>
          ※ISM FCは「入会日」から月額会員は1ヶ月間、年額会員は1年間が会員有効期間となり、以後自動更新となります。そのため更新の手続きは必要ございません。<br/>
          <br/>
          ※退会をご希望の場合は会員期間内にマイページ、アカウント設定からお手続きください。<br/>
          <br/>
          ※<Link to="/agreement" css={{color: theme.colors.accent, textDecoration: 'underline'}}>利用規約</Link>を必ずご確認ください。
        </p>
      </Section>
      <div>
        <OutlinePillNavButton color={theme.colors.accent} to="/" children="トップへ戻る" caret="left"/>
      </div>
    </Container>
  );
}

const SubSection = ({heading, children}) => (
  <section css={{
    display: 'flex',
    borderBottom: `1px solid ${theme.colors.background}`,
    paddingBottom: 10,
    margin: '25px 0'
  }}>
    <h3>{heading}</h3>
    {children}
  </section>
)

const StepTile = ({number, children}) => (
  <div css={{
    display: 'flex',
    borderBottom: `1px solid ${theme.colors.background}`,
    paddingBottom: 10,
    margin: '10px 0'
  }}>
    <div css={{marginRight: 10}}>
      <span css={{
        width: 20,
        height: 20,
        backgroundColor: theme.colors.background,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold'
      }}>
        {number}
      </span>
    </div>
    <div css={{flex: 'auto'}}>
      {children}
    </div>
  </div>
);

const PrizeCard = ({title, subTitle}) => (
  <div css={{
    border: `2px solid ${theme.colors.background}`,
    borderRadius: 4,
    textAlign: 'center',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 0'
  }}>
    <p css={{fontSize: 12}}>{subTitle}</p>
    <p css={{
      fontSize: 18,
      color: theme.colors.accent,
      marginTop: 6,
      fontWeight: 'bold'
    }}>{title}</p>
  </div>
);

const Section = ({label, children}) => (
  <section css={{margin: '40px 0'}}>
    <h2 css={{
      textAlign: 'center',
      marginBottom: 20
    }}>
      <span css={css`
        font-size: 15px;
        color: white;
        padding: 0 15px;
        background-color: ${theme.colors.primary};
        display: inline-block;
      `}>{label}</span>
    </h2>
    {children}
  </section>
);