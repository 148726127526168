import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import theme from './theme';

export default class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {shown: false};
  }

  componentDidMount() {
    this.appear();
  }

  appear() {
    this.setState({shown: true});
    this.timeout = setTimeout(() => {
      this.setState({shown: false});
      this.props.onDisappear();
    }, 10000);
  }

  render() {
    const {text} = this.props;
    const {shown} = this.state;

    return shown ? (
      <div css={{position: 'fixed', width: '100%', bottom: 0, textAlign: 'center', backgroundColor: theme.colors.accent, color: 'white', padding: '10px'}} onClick={() => {
        clearTimeout(this.timeout);
        this.setState({shown: false});
        this.props.onDisappear();
      }}>
        {text}
      </div>
    ) : <div/>;
  }
}