import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Container from '../ui/container';

import mile_bronze from '../img/mile/mile-bronze.svg';
import mile_silver from '../img/mile/mile-silver.svg';
import mile_gold from '../img/mile/mile-gold.svg';
import mile_diamond from '../img/mile/mile-diamond.svg';
import mile_platinum from '../img/mile/mile-platinum.svg';
import mile_ex_goods from '../img/mile/mile-ex-goods.png';
import mile_ex from '../img/mile/mile-ex.png';
import mile_bro from '../img/mile/mile-bro.png';
import mile_image1 from '../img/mile/mile-image1.svg';
import mile_image2 from '../img/mile/mile-image2.svg';
import mile_rank from '../img/mile/mile-rank.svg';
import mile_secret from '../img/mile/mile-secret.png';
import mile_title from '../img/mile/mile-title.svg';
import mile_voice from '../img/mile/mile-voice.png';

import mail_bg_white from '../img/mile/mile-bg-white.svg';
import mail_bg_black from '../img/mile/mile-bg-black.svg';
import mile_bg_n_white from '../img/mile/mile-bg-n-white.svg';
import mile_bg_n_black from '../img/mile/mile-bg-n-black.svg';

export default () => (
  <div>
    <div css={css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 40px;
      padding: 40px 20px 0;

      img {
        width: 261px;
        margin-bottom: 50px;
      }
      p {
        font-size: 17px;
        text-align: center;
        span {
          display: inline-block;
          font-weight: bold;
          position: relative;

          &:after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #C9171E;
          }
        }
      }
    `}>
      <img src={mile_title} />
      <p>ISM所属俳優を応援して貯めたISMマイルで<br />ここでしか手に入らない<span>限定グッズ</span>をもらおう！</p>
    </div>

    <div css={css`
    background-color: #F4FAFF;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 40px;
      text-align: center;
    }
    img {
      margin-bottom: 40px;
      width: 202px;
    }
    p {
      font-size: 17px;
    }
    `}>
      <h2>ISMマイルを貯めよう</h2>
      <img src={mile_image1} />
      <p>ログイン、ニュース記事閲覧、ブログ記事閲覧、クイズなどさまざまな場面でISMマイルを貯めることができます。</p>
    </div>

    <div css={css`
      padding: 40px 0;

      h2 {
        text-align: center;
        font-size: 30px;
        margin-bottom: 31.8px;
      }
    `}>
      <h2>ISMマイルの貯め方</h2>
      <div css={css`
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 157.83px;
      }`}><img src={mile_image2} /></div>

      <Tame name="1年継続" image={mail_bg_white} mile="100" />
      <Tame name="誕生日" image={mail_bg_black} even={true} mile="100" />
      <Tame name="NEWS記事、ブログ記事閲覧" image={mile_bg_n_white} mile="1" />
      <Tame name="1日1回のクイズに正解" image={mile_bg_n_black} text="※不正解でも<span>１</span>ISMマイル" even={true} mile="10" pb={0} />
      
      <div css={css`margin-top: -30px;`}>
        <p css={css`
          text-align: center;
          font-weight: bold;
          margin-bottom: 10px;
        `}>＼俳優本人からのレアクイズも！／</p>
        <a href="/mypage" css={css`
          display: flex;
          width: 200px;
          height: 47px;
          align-items: center;
          justify-content: center;
          background-color: #C9171E;
          color: white;
          font-size: 17px;
          border-radius: 24px;
          margin-inline: auto;
          font-weight: bold;
          margin-bottom: 50px;
        `}>クイズに答える</a>
        <p css={css`
          font-size: 17px;
          text-align: center;
        `}>その他特定記事などでボーナスISMマイルが<br />付与されるなどの企画も！</p>
      </div>
    </div>


    <div css={css`
    background-color: #F4FAFF;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;


      h2 {
        text-align: center;
        text-align: center;
        font-size: 30px;
        margin-bottom: 40px;
      }
      img {
        width: 100%;
      }
      p {
        font-size: 17px;
        margin-top: 40px;
      }
    `}>
      <h2>ランク</h2>
      <img src={mile_rank} />
      <p>ランクに応じてココでしか手に入らないグッズが貰えます。</p>
    </div>

    <div css={css`
      padding: 40px 20px ;

      h2 {
        text-align: center;
        font-size: 30px;
        margin-bottom: 30px;
      }
      p {
        font-size: 15px;
        text-align: center;
      }
    `}>
      <h2>ISMマイルが貯まったら<br />限定グッズと交換しよう！</h2>
      <p>※ISMマイルをグッズ交換してもマイルは減少しません。</p>
    </div>

    <div>
      <div css={css`
      text-align: center;
      padding: 0 20px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      `}>
        <img src={mile_bronze} css={css`width: 80px;`} />
        <p css={css`
          margin-top: 20px;
          margin-bottom: 40px;
          span {
            font-size: 26px;
            font-weight: bold;
          }
        `}>
          <span>1000</span> ISMマイル
        </p>
        <img src={mile_ex} css={css`
          margin-bottom: 40px;
          width: 250px;
        `} />
        <div css={css`
          display: inline-flex;
                    padding: 7px 30px 8px;

          align-items: center;
          justify-content: center;
          background-color: #CA8059;
          margin-inline: auto;
          color: white;
          font-size: 17px;
          font-weight: bold;
          margin-bottom: 20px;
        `}>
        オリジナル壁紙
        </div>
        <p css={css`
        font-size: 15px;
        text-align: center;
        `}>※希望俳優をお選びいただけます<br />
        ※壁紙はスマホサイズとなります</p>
      </div>
    </div>

    <div>
      <div css={css`
      text-align: center;
      padding: 40px 20px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #F4F4F5;
      `}>
        <img src={mile_silver} css={css`width: 80px;`} />
        <p css={css`
          margin-top: 20px;
          margin-bottom: 40px;
          span {
            font-size: 26px;
            font-weight: bold;
          }
        `}>
          <span>3000</span> ISMマイル
        </p>
        <img src={mile_ex_goods} css={css`
          margin-bottom: 40px;
          width: 302px;
        `} />
        <div css={css`
          display: inline-flex;
                    padding: 7px 30px 8px;

          align-items: center;
          justify-content: center;
          background-color: #A8ADB4;
          margin-inline: auto;
          color: white;
          font-size: 17px;
          font-weight: bold;
          margin-bottom: 20px;
        `}>
        キャラクターグッズ
        </div>
      </div>
    </div>

    <div>
      <div css={css`
      text-align: center;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #CFAC0D;
      `}>
        <img src={mile_gold} css={css`width: 80px;`} />
        <p css={css`
          margin-top: 20px;
          margin-bottom: 40px;
          color: white;
          span {
            font-size: 26px;
            font-weight: bold;
          }
        `}>
          <span>5000</span> ISMマイル
        </p>
        <img src={mile_bro} css={css`
          margin-bottom: 40px;
          width: 250px;
        `} />
        <div css={css`
          display: inline-flex;
                    padding: 7px 30px 8px;

          align-items: center;
          justify-content: center;
          background-color: white;
          margin-inline: auto;
          color: white;
          font-size: 17px;
          font-weight: bold;
          margin-bottom: 20px;
          color: #CFAC0D;
        `}>
        サイン入りブロマイド
        </div>
        <p css={css`
        font-size: 15px;
        text-align: center;
        `}>※希望俳優をお選びいただけます</p>
      </div>
    </div>

    <div>
      <div css={css`
      text-align: center;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #6E6C6C;
      `}>
        <img src={mile_platinum} css={css`width: 80px;`} />
        <p css={css`
          margin-top: 20px;
          margin-bottom: 40px;
          color: white;
          span {
            font-size: 26px;
            font-weight: bold;
          }
        `}>
          <span>10000</span> ISMマイル
        </p>
        <img src={mile_voice} css={css`
          margin-bottom: 40px;
          width: 147px;
        `} />
        <div css={css`
          display: inline-flex;
                    padding: 7px 30px 8px;

          align-items: center;
          justify-content: center;
          background-color: white;
          margin-inline: auto;
          color: white;
          font-size: 17px;
          font-weight: bold;
          margin-bottom: 20px;
          color: #6E6C6C;
        `}>
        名前呼びボイスメッセージ
        </div>
        <p css={css`
        font-size: 15px;
        text-align: center;
        `}>※希望俳優をお選びいただけます</p>
      </div>
    </div>

    <div>
      <div css={css`
      text-align: center;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #2A9CCB;
      `}>
        <img src={mile_diamond} css={css`width: 80px;`} />
        <p css={css`
          margin-top: 20px;
          margin-bottom: 40px;
          color: white;
          span {
            font-size: 26px;
            font-weight: bold;
          }
        `}>
          <span>30000</span> ISMマイル
        </p>
        <img src={mile_secret} css={css`
          margin-bottom: 40px;
          width: 250px;
        `} />
        <div css={css`
          display: inline-flex;
                    padding: 7px 30px 8px;

          align-items: center;
          justify-content: center;
          background-color: white;
          margin-inline: auto;
          color: white;
          font-size: 17px;
          font-weight: bold;
          margin-bottom: 20px;
          color: #2A9CCB;
        `}>
        シークレット
        </div>
      </div>
    </div>

    <div css={css`
      padding: 40px 20px 50px;
      h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
      }
      p {
        font-size: 15px;
        text-align: center;
        margin-bottom: 40px;
      }
    `}>
      <h3>ISMマイルを確認しよう</h3>
      <p>ISMマイルはマイページで確認できます。</p>

      <div>
        <a href="/mypage" css={css`
          display: flex;
          width: 200px;
          height: 47px;
          align-items: center;
          justify-content: center;
          background-color: #C9171E;
          color: white;
          font-size: 17px;
          border-radius: 24px;
          margin-inline: auto;
        `}>マイページへ</a>
      </div>
    </div>

  </div>
);

function Tame({name, image, text, even, mile}) {
  return (

    <div css={css`
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 216px;
    background: url(${image}) center center no-repeat;
    ${!even && 'background-color: #0F1432;'}

    h3 {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
      color: ${even ? 'black' : 'white'};
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      &::after{ 
        content: "";
        display: block;
        width: calc(100% + 20px);
        height: 2px;
        background-color: #C9171E;
        border-radius: 10px;
      }
    }
  `}>
      <h3>{name}</h3>
    <div css={css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 13.6px;

      img {
        width: 74.72px;
      }
    `}>
      </div>
      <div css={css`
        font-size: 17px;
        color: ${even ? 'black' : 'white'};
        font-weight: bold;
        margin-top: 33.8px;
        em {
          font-style: normal;
          font-size: 36px;
          color: #69BCC1;
          margin-right: 13px;
        }
      `}>
        <em>{mile}</em>ISMマイル
      </div>
      {text && <p css={css`
        text-align: center;
        font-size: 15px;
        margin-top: 70px;
        transform: translate(0, -20px);
        span {
          font-weight: bold;
        }
      `} dangerouslySetInnerHTML={{__html: text}}></p>}
  </div>

  )
}