import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import RichText from '../ui/richtext';
import Container from '../ui/container';

const txt = `
特定商取引法に基づく表記


●運営責任者
鬼頭悠佳

●運営管理会社
ISM ENTERTAINMENT
106-0032 東京都港区六本木3-9-11


●連絡先
ISMオフィシャルファンクラブ
メールアドレス fc@ism-ent.com
TEL: 03-6434-7730
受付時間：土日祝日を除く11：00～17：00
（年末年始・お盆・ゴールデンウィークその他の事情により休業させていただく場合があります。）

●販売価格
【会費】
月会費　800円（別途消費税）
年会費　9,000円（別途消費税）

●代金支払方法
・クレジットカード決済

●代金支払時期
・入会時：クレジットカード決済
・入会後は月額会員は毎月、年額会員は毎年、自動更新となります。退会は会員期間中に指定の方法で手続きしてください。

●サービスの提供時期
・入会日：クレジットカード決済が完了され次第、当会の会員となります。
・期限：ご入金（決済）された日が「入会日」となり、「入会日」から月額会員は1ヶ月間、年額会員は1年間が会員有効期間となります。退会手続きをしない限り自動更新されます。

●キャンセルについて
お客様のご都合による入会申込のキャンセル等はお受けしておりません。
（ただし会員規約に従い退会することは可能です。）
尚、一旦入金された会費は、理由の如何を問わず一切返金いたしかねますので予めご了承ください。
※上記以外の当ファンクラブの詳細は、当ファンクラブが別途定めたISM FC会員規約をご確認ください。
`;

export default () => <Container css={{paddingTop: 50, fontSize: 11}}><RichText text={txt}/></Container>;