import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Abort from './abort';

export default ({error}) => {
  let status = 500;
  let message = 'エラーが発生しました';

  if (error.response) {
    if (error.response.status == '404') {
      message = '存在しないURLです';
    }
    status = error.response.status;
  }

  return <Abort title={status} message={message}/>;
};