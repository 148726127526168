import React from 'react';
import styled from 'styled-components';

const Stack = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

Stack.Layer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({width}) => width ? `width: ${width};` : ''}
  ${({height}) => height ? `height: ${height};` : ''}

  ${({center}) => center ? `
    width: 100%;
    height: 100%;
  ` : ''}

  ${({left}) => left ? `left: ${left};` : ''}
  ${({top}) => top ? `top: ${top};` : ''}
  ${({bottom}) => bottom ? `bottom: ${bottom};` : ''}
  ${({right}) => right ? `right: ${right};` : ''}
`;

export default Stack;