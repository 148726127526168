import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import styled from '@emotion/styled';
import App from '../app';
import Container from '../ui/container';
import {Padding, Margin} from '../ui/spacing';
import {Row, Column} from '../ui/flex';
import filePick from '../ui/filepick';
import PieCutOverlay from '../ui/piecut-overlay';
import { Link } from 'react-router-dom';
import api from '../api';
import {throttle} from 'throttle-debounce';
import theme from '../ui/theme';
import SettingHeader from '../ui/setting-header';
import Confirm from '../ui/confirm';
import { date, still } from '../fmt';
import users from '../users';
import { OutlinePillNavButton } from '../ui/button';
import icon_close from '../img/icon-close.png';

export default () => {
  const app = React.useContext(App);
 
  return <Settings
    user={app.user}
    fetchPeriod={() => api()('/sub')
      .then(rs => rs.data.subscription)
      .then(sub => sub.current_period_end)
    }
    fetchCanceled={() => api()('/sub')
      .then(rs => rs.data.subscription)
      .then(sub => sub.canceled)
    }
    cancel={(reasons) => api().post('/sub/cancel', {reasons})}
    recover={() => api().patch('/sub/recover')}
  />;
}

export const Settings = ({user, fetchPeriod, fetchDetail, fetchCanceled, cancel, recover}) => {
  return (
    <Container css={{paddingTop: 45}}>
      <SettingHeader title="ユーザー設定"/>
      <Profile user={user}/>
      <Padding top="20px">
        <Menu fetchPeriod={fetchPeriod} fetchDetail={fetchDetail} fetchCanceled={fetchCanceled} cancel={cancel} recover={recover} user={user} />
      </Padding>
      <div css={{marginTop: 40}}>
        <OutlinePillNavButton color={theme.colors.accent} to="/mypage" children="マイページへ戻る" caret="left"/>
      </div>
    </Container>
  );
}

const Profile = ({user}) => {
  const app = React.useContext(App);
  const [avatar, setAvatar] = React.useState(user.avatar);
  const [uploading, setUploading] = React.useState(0.0);

  const resetAvatar = async () => {
    const url = await filePick.url();
    try {
      const rs = await api().patch('/user/avatar', {
        "avatar": url.split(',')[1]
      }, {
        onUploadProgress: throttle(100, (progressEvent) => {
          const progress = Math.round( progressEvent.loaded / progressEvent.total );
          setUploading(progress);
        })
      });
      setAvatar(rs.data.user.avatar);
      setUploading(0);
    } catch(e) {
      console.error(e);
      app.error(e);
    }
  };

  return (
    <Row>
      <Margin right="20px">
        <Column onClick={resetAvatar}>
          <PieCutOverlay radius="25" value={uploading*100} color="rgba(0, 0, 0, 0.5)">
            <Avatar src={avatar} alt=""/>
          </PieCutOverlay>
          <span style={{color: theme.colors.accent}}>変更</span>
        </Column>
      </Margin>
      <div style={{flex: 1}}>
        <div>
          <span style={{fontWeight: 'bold'}}>{user.name}</span>
          <span style={{fontSize: 12, marginTop: 20}}>さん</span>
        </div>
        <Margin top="10px">
          <div style={{fontSize: 12}}>入会日：{date(user.registered_at, '/')}</div>
          <div style={{fontSize: 12}}>FC会員継続期間：{users.continued(user)}日目</div>
        </Margin>
      </div>
    </Row>
  );
};


const Menu = ({fetchPeriod, fetchCanceled, cancel, recover, user}) => {
  const app = React.useContext(App)
  const [canceled, setCanceled] = React.useState(null)
  const [locked, lock] = React.useState(false)
  const [period, setPeriod] = React.useState(null)

  const [openCancel, setOpenCancel] = React.useState(false)

  React.useEffect(() => {
    fetchCanceled().then(setCanceled);
    fetchPeriod().then(setPeriod);
  }, []);

  const doCancel = (reasons, callback) => {
    cancel(reasons)
    .then(() => {
      setCanceled(true)
      setOpenCancel(false)
      lock(false)
      callback()
    }).catch(e => {
      console.error(e)
      app.error()
      lock(false)
    })
  }

  const cancelCancel = () => {
    recover()
    .then(() => {
      setCanceled(false)
      lock(false)
    })
    .catch(e => {
      console.error(e)
      app.error()
      lock(false)
    })
  }

  return (
    <ul>
      <MenuTile to="/settings/profile">ユーザー情報変更</MenuTile>
      <MenuTile to="/settings/email">メールアドレス変更</MenuTile>
      <MenuTile to="/settings/password">パスワード変更</MenuTile>
      <MenuTile to="/settings/magazine">メールマガジンの設定</MenuTile>
      <MenuTile to="/artists/">フォロー変更</MenuTile>
      <MenuTile to="/settings/card">お支払い情報変更</MenuTile>
      <MenuTile onClick={!locked ? canceled ? cancelCancel : () => setOpenCancel(true) : null}>
        {canceled ? '退会をキャンセル' : '退会する'}
      </MenuTile>

      <CancelForm openCancel={openCancel} setOpenCancel={setOpenCancel} user={user} period={period} doCancel={doCancel} />
    </ul>
  );
}

const CancelForm = ({openCancel, setOpenCancel, user, period, doCancel}) => {
  const [reasons, setReasons] = React.useState([]);
  const [mode, setMode] = React.useState('check');

  function toggleReasons(value) {
    setReasons(reasons.includes(value) ? reasons.filter(r => r !== value) : [...reasons, value])
  }

  const sendCancel = () => {
    doCancel(reasons, () => {
      setReasons([]);
      setMode('check');
    });
  }
  return <Modal open={openCancel} close={() => setOpenCancel(false)}>
      <div>
        {mode == 'check' && <div css={css`margin-bottom: 20px;`}>
          本当に退会しますか？（FC入会期間：{users.continued(user)}日）<br />
          <span css={css`font-size: 13px; color: #999;`}>※{still(period)}までの会員期間内は会員限定コンテンツの閲覧が可能です。</span>
          <div css={css`display: flex; flex-direction: column; align-items: center; gap: 20px; margin-top: 20px;`}>
            <Btn onClick={() => setMode('reason')} active={true} border={true} text="退会する"/>
            <Btn onClick={() => () => setOpenCancel(false)} active={true} text="退会をやめる"/>
          </div>
        </div>}
        {mode == 'reason' && <div css={css``}>
          <p css={css`font-size: 13px`}>退会理由をご教示いただけますか？（複数選択可）</p>
          <div css={css`
            background: #E8ECEF;
            border-radius: 5px;
            overflow: hidden;
            margin-top: 20px;
          `}>
            <Label name="reasons" value="FCのコンテンツに魅力を感じなかったため。" checked={reasons.includes('FCのコンテンツに魅力を感じなかったため。')} onChange={() => toggleReasons("FCのコンテンツに魅力を感じなかったため。")} />
            <Label name="reasons" value="事務所の問い合わせ対応に不満があったため。" checked={reasons.includes('事務所の問い合わせ対応に不満があったため。')} onChange={() => toggleReasons("事務所の問い合わせ対応に不満があったため。")} />
            <Label name="reasons" value="他に気になる俳優やアーティストができたため。" checked={reasons.includes('他に気になる俳優やアーティストができたため。')} onChange={() => toggleReasons("他に気になる俳優やアーティストができたため。")} />
            <Label name="reasons" value="チケットが手に入らないため。" checked={reasons.includes('チケットが手に入らないため。')} onChange={() => toggleReasons("チケットが手に入らないため。")} />
            <Label name="reasons" value="会費が高いため。" checked={reasons.includes('会費が高いため。')} onChange={() => toggleReasons("会費が高いため。")} />
            <Label name="reasons" value="所属俳優のファンでなくなったため。" checked={reasons.includes('所属俳優のファンでなくなったため。')} onChange={() => toggleReasons("所属俳優のファンでなくなったため。")} />
            <Label name="reasons" value="ほとんど利用していないため。" checked={reasons.includes('ほとんど利用していないため。')} onChange={() => toggleReasons("ほとんど利用していないため。")} />
          </div>
          <div css={css`display: flex; flex-direction: column; gap: 20px; margin-top: 30px;`}>
            <Btn onClick={() => setMode('last')} border={true} active={reasons.length > 0} text="次へ"/>
            <Btn onClick={() => () => setOpenCancel(false)} active={true} text="退会をやめる"/>
          </div>
        </div>}
        {mode == 'last' && 
        <div>
          <div css={css`margin-top: 20px; color: red;`}>
          あなたの所持マイルは{user.mile}です。マイルデータも削除され、二度と復元ができません。退会処理をしてもよろしいでしょうか？
          </div>
          <div css={css`margin-top: 30px;`}>
            <Btn onClick={sendCancel} active={true} text="退会する"/>
          </div>
        </div>
        }
      </div>
  </Modal>
}

const Btn = ({border, active, onClick, text}) => {
  return <div css={css`
  display: flex;
  justify-content: center;
`}>
    <span css={css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 47px;
    ${border ? `
      border: 1px solid ${!active ? `gray` : `#C9171E`};
      color: ${!active ? `gray` : `#C9171E`};
    ` : `
      background: ${!active ? `gray` : `#C9171E`};
      color: white;
    `}
    border-radius: 24px;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
  `} onClick={onClick}>{text}</span>
  </div>
}

const Modal = ({open, close, children}) => <div css={css`
  opacity: 0;
  pointer-events: none;
  transition: .5s opacity;

  ${open && `
    opacity: 1;
    pointer-events: all;
  `}

    position: fixed;
    z-index: 10;
    top: 65px;
    left:  0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: calc( 100vh - 65px );
    overflow: scroll;
    background-color: rgba(0, 0, 0, .5);
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  `}>
    <div css={css`
      width: 100%;
      padding: 30px 20px;
      background: white;
      border-radius: 10px;
      max-width: 390px;
      width: 100%;
      position: relative;
    `}>
      <div css={css`
        position: absolute;
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          corsor: pointer;
        }
      `}>
        <img src={icon_close} onClick={close} />
      </div>
      {children}
    </div>
  </div>;

const Label = ({name, value, checked, onChange}) => {
  return <label css={css`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    gap: 10px;
    font-size: 12px;
  `} id={value}>
    <input type="checkbox" name={name} value={value} checked={checked} onChange={onChange} />
    {value}
  </label>
}

const MenuTile = ({to, onClick, children}) => {
  const style = css`
    display: block;
    padding: 25px 0;  
    cursor: pointer;
  `;

  return <li css={css`
    border-bottom: 1px solid #CCC;

    &:first-of-type {
      border-top: 1px solid #CCC;
    }
  `}>
    {to ? <Link onClick={onClick} to={to} css={style}>{children}</Link> : <div onClick={onClick} css={style}>{children}</div>}
  </li>
};

const Avatar = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 4px 4px 1px #DDD;
`;