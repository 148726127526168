import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import Button from '../ui/button';
import App from '../app';
import Container from '../ui/container';

export default ({past_prizes, year}) => {
  const app = React.useContext(App);

  return (
    <div onClick={() => app.pop()} css={{backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100%'}}>
      <Container css={{height: 65, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'white'}}>
        <span className="material-icons" css={{fontSize: 36, cursor: 'pointer'}}>close</span>
      </Container>
      <div css={{margin: '0 auto', width: '100%', height: 'calc(100% - 120px)', padding: '0 20px 20px', overflowY: 'scroll'}}>
        {past_prizes.map((prize, i) => 
          <div key={i} css={{margin: '0 auto 20px', textAlign: 'center', padding: 20, background: '#E8ECEF', maxWidth: 520, width: '100%'}}>
            <img src={prize.media.image[year]} css={{maxHeight: 480, maxWidth: '100%'}}/>
            {prize.media.caption && <div css={{padding: 12, fontSize: 12, maxWidth: 480, margin: '0 auto'}}>{prize.media.caption}</div>}
          </div>
        )}
      </div>
    </div>
  )
}