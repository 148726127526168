import axios from 'axios';
import iam from './iam';

const withToken = token => axios.create({
  baseURL: process.env.API || 'http://localhost:1345',
  ...(token ? {
    headers: {
      authorization: `Bearer ${token}`
    }
  } : {})
});

export default () => withToken(iam());