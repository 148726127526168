import React from 'react';
import styled from 'styled-components';
import Stack from './stack';
import PieCut from './piecut';

export default ({radius, color, value, children}) => {
  return (
    <Root radius={radius}>
      <Stack>
        <Stack.Layer>
          {children}
        </Stack.Layer>
        <Stack.Layer>
          <PieCut radius={radius} color={color} value={value}/>
        </Stack.Layer>
      </Stack>
    </Root>
  );
};

const Root = styled.div`
  width: ${props => props.radius*2}px;
  height: ${props => props.radius*2}px;
`;