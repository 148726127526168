import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {Link} from 'react-router-dom';
import api from '../api';
import Container from '../ui/container';
import AspectRatio from '../ui/aspect-ratio';
import theme from '../ui/theme';
import App from '../app';
import MediaPopup from './media-popup';
import {ScrollPaginator} from '../ui/paginator';
import { date, mdhm } from '../fmt';
import FavIcon from '../img/fav.svg';
import FavIconRed from '../img/fav_red.svg';
import CommentIcon from '../img/comment.svg';

export default ({match}) => {
  const fetch = next => api()(next || `/talents/${match.params.talent}/media`)
    .then(({data: {media, meta: {links: {next}}}}) => [media, next]);

  return (
    <ScrollPaginator fetch={fetch}>
      {media => <TalentMedia media={media}/>}
    </ScrollPaginator>
  )
}

export const TalentMedia = ({media}) => {
  const app = React.useContext(App);

  return (
    <Container>
      <ul>
        {media.map(media => (
          <li key={media.id} css={{marginTop: 10}}>
            <MediaTile media={media} onClick={media.image ? () => app.push(<MediaPopup media={media}/>) : null}/>
          </li>
        ))}
      </ul>
    </Container>
  )
}

const MediaTile = ({media, onClick}) => (
  <Link to={`/artists/${media.talent.id}/media/${media.id}`}>
  <div css={css`
  background-color: #E8ECEF;
  `}>
    <div css={{ backgroundColor: 'black' }}>
      {media.image && <img src={media.image} alt="" css={{display: 'block', width: '100%', height: 'auto'}}/>}
      {media.video &&    
          <video src={media.video} poster={media.poster} controls preload playsInline css={{display: 'block', width: '100%', height: 'auto'}}/>
      }
    </div>
    {media.caption && <div css={{ padding: '12px 12px 0 12px' }}>{media.caption}</div>}
    <div css={{ padding: 12 }}>{date(media.published_at)}</div>
    <div css={css`padding: 0 10px 10px`}>
      {<MediaFav media={media} />}
    </div>
  </div>
  </Link>
);



const MediaFav = ({media}) => 
<div css={css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7.7px;
`}>
  <div css={css`
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    cursor: pointer;
    img {
      width: 18.52px;
      height: 15px;
    }
  `}><span>{media.fav_count}</span><img src={media.favorited ? FavIconRed : FavIcon} /></div>
  <div css={css`
    cursor: pointer;
    height: 16.51px;
    img {
      width: 15.76px;
      height: 100%;
    }
  `}><img src={CommentIcon} /></div>
</div>
