import React from 'react';
import styled from 'styled-components';
import ArticleTile from '../ui/article-tile';
import Container from '../ui/container';
import {Padding} from '../ui/spacing';
import NewsTabBar from '../ui/news-tab-bar';
import api from '../api';
import {ScrollPaginator} from '../ui/paginator';

export default ({match, history}) => {
  const fetch = next => api()(next || `/talents/${match.params.talent}/news`)
    .then(({data: {news, meta: {links: {next}}}}) => [news, next]);

  return <ScrollPaginator fetch={fetch}>
    {news => <TalentNews news={news} history={history}/>}      
  </ScrollPaginator>;
}

export const TalentNews = ({news, history}) => (
  <Container>
    <ul>{news.map(a => <ArticleTile key={a.id} article={a}/>)}</ul>
  </Container>
);

