import React from 'react';
/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {Link} from 'react-router-dom';
import theme from './theme';
import TalentAvatar from './talent-avatar';
import {date} from '../fmt';

export default ({activity, link}) => {
  const message = <>
    {activity.post && activity.post.title}
    {activity.media && '- メディアを更新しました'}
  </>;

  return (
    <div css={{
      margin: '10px 0',
      display: 'flex'
    }}>
      <span css={{marginRight: 10}}>
        <TalentAvatar talent={activity.talent}/>
      </span>
      <svg width="7.5" height="7.5" css={{marginTop: 7.5}}>
        <polygon points="0 3.75 7.5 0 7.5 7.5" fill={theme.colors.background}/>
      </svg>
      {activity.post ?
        <Link to={`/artists/${activity.talent.id}/blog/${activity.post.id}`} css={{
          backgroundColor: theme.colors.background,
          padding: 10,
          flex: 'auto',
          borderRadius: 4
        }}>
          <div css={{display: 'flex', marginBottom: 10}}>
            <div css={{
              fontWeight: 'bold',
              flex: 'auto'
            }}>{activity.talent.name}</div>
            <div css={{fontSize: 12}}>
              {date(activity.published_at)}
            </div>
          </div>
            <span>
              {message}
            </span>
          </Link>
        :
        activity.media && link ?
          <Link to={`/artists/${activity.talent.id}/media`} css={{
            backgroundColor: theme.colors.background,
            padding: 10,
            flex: 'auto',
            borderRadius: 4
          }}>
            <div css={{display: 'flex', marginBottom: 10}}>
              <div css={{
                fontWeight: 'bold',
                flex: 'auto'
              }}>{activity.talent.name}</div>
              <div css={{fontSize: 12}}>
                {date(activity.published_at)}
              </div>
            </div>
            <div>{message}</div>
          </Link>
            :
        <div css={{
          backgroundColor: theme.colors.background,
          padding: 10,
          flex: 'auto',
          borderRadius: 4
        }}>
          <div css={{display: 'flex', marginBottom: 10}}>
            <div css={{
              fontWeight: 'bold',
              flex: 'auto'
            }}>{activity.talent.name}</div>
            <div css={{fontSize: 12}}>
              {date(activity.published_at)}
            </div>
          </div>
          <div>{message}</div>
        </div>
      }

    </div>
  );
}